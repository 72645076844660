import request from '@/utils/request'

// 获取省市全部数据
export function getMapAll(data) {
  return request({
    url: '/appApi/jzl/mapPage/getMapAll',
    method: 'post',
    data,
  })
}
// 获取仓库列表
export function getMapList(data) {
  return request({
    url: '/appApi/jzl/mapPage/getMapList',
    method: 'post',
    data,
  })
}