<template>
      <div>
        <div class='warehouseItem' v-for="(item, index) in mapListData" :key="index" :class="{ warehouseItemActive:index === selectedIndex }" @click='handleWarehouse(item,index)'>
          <div class='top'>
            <div class='iocn'>{{item.storeTypeName}}</div>
            <div class='info'>
              <div class='address ellipsis'>{{item.provName}}{{item.cityName}}</div>
              <div class='infoItem'>
                <div class='area'>面积：<span class='ellipsis'>{{item.areaVal}}</span>m²</div>
                <div class='distance'>距离：{{item.geoDistance}}</div>
              </div>
            </div>
          </div>
          <div class='bottom'>
            <img src='@/assets/images/home/addressIcon.png' alt=''>
            <div class='addDesc ellipsis2'>{{item.address}}</div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: 'storageListinfo',
  props:{
    selectedIndex: {//当前选中的仓库
      type: Number, // 定义接收的数据类型
      default: null, // 默认值
    },
    mapListData:{
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods:{
    //仓库点击事件
    handleWarehouse(info,index){
      this.$emit('warehouse-click',info,index)
    }
  }
}
</script>

<style scoped lang='scss'>
.warehouseItem{
  width: 300px;
  height: 130px;
  background: #FFFFFF;
  border-radius: 4px;
  margin: 0 12px;
  padding: 20px 20px 11px 20px;
  color:#999999;
  cursor: pointer;
  .top{
    line-height: 16px;
    display: flex;
    .iocn{
      width: 52px;
      height: 52px;
      background: #E1251B;
      border-radius: 10px;
      padding: 10px 11px;
      font-size: 14px;
      color: #FFFFFF;
      margin-right: 11px;
      flex: none;
    }
    .info{
      width: 200px;
      .address{
        font-size: 12px;
        font-weight: bold;
        color: #333333;
        margin: 0 0 5px;
      }
      .infoItem{
        font-size: 12px;
        color: #999999;
        display: flex;
        flex-direction: column;
        div{
          white-space: nowrap;
          display: flex;
          align-items: center;
          span{
            display: inline-block;
            max-width: 80%;
          }
        }
      }
    }
  }
  .bottom{
    display: flex;
    align-items: center;
    margin-top: 10px;
    img{
      width: 11px;
      height: 14px;
      margin-right: 9px;
    }
    .addDesc{
      font-size: 12px;
      color: #999999;
      line-height: 16px;
    }
  }
}
.warehouseItemActive{
  background: rgba(153, 153, 153, 0.1);
}
</style>