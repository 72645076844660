
<template>
  <div class="index-box">
    <!-- <div class="index-box-banner"></div> -->
    <img  class="index-box-banner" src="@/assets/images/realname/accountTopBanner.png" alt="">
    <div style="width: 520px; margin: 0 auto;position: relative; top: -80px;">
      <div class="title">基本信息</div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="options" label-width="136px" class="demo-form">
          <el-form-item label="姓名" class="lengthInput" prop="idCardName">
            <el-input v-model="form.idCardName" placeholder="法人姓名，委托认证填写委托人姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号码" class="lengthInput" prop="idCardNumber">
            <el-input v-model="form.idCardNumber" placeholder="法人身份证号，委托认证填写委托人身份证号"></el-input>
          </el-form-item>
          <el-form-item label="有效期截止日期" prop="serIdCardTime">
            <el-date-picker v-model="form.serIdCardTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
              @change="selectSFRQDate">
            </el-date-picker>
            <el-radio style="margin-left: 10px; font-size: 14px;" v-model="form.serIdCardType" label="1"
              @input="idcardCQDate">长期有效</el-radio>
          </el-form-item>
          <el-form-item label="公司名称" prop="serCompanyName" class="small">
            <el-input v-model="form.serCompanyName" placeholder="请输入公司名称"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="serLicenseCode" class="small">
            <el-input v-model="form.serLicenseCode" placeholder="请输入正确的统一社会信用代码"></el-input>
          </el-form-item>

          <el-form-item label="经营期限" required>
            <el-col class="data" :span="11">
              <el-form-item prop="date1">
                <el-date-picker type="date" placeholder="开始时间" v-model="form.serBusinessBeginDate"
                  value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col class="data" :span="11">
              <el-form-item prop="date1">
                <el-date-picker type="date" placeholder="结束时间" v-model="form.serBusinessEndDate" @change="selectJYDate"
                  value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-radio style="margin-left: 10px; font-size: 14px;" v-model="form.serBusinessDateAll" label="1"
              @input="selectDateInput">无有效期</el-radio>
          </el-form-item>
          <!-- prop="address" -->
          <el-form-item label="经营地址" class="address" prop="address" >
            <!-- -->
            <el-select v-model="form.serProvName" placeholder="请选择省" @change="selectProvice" 
              @visible-change="selectProviceList">
              <el-option 
              v-for="item in proviceList" 
              :key="item.code" 
              :label="item.name" 
              :value="item.name">
              </el-option>
            </el-select>
            <el-select v-model="form.serCityName" placeholder="请选择市" @change="selectCity"
              @visible-change="selectCityList">
              <el-option v-for="item in cityList" :key="item.code" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
            <el-select v-model="form.serAreaName" placeholder="请选择县" @change="selectArea"
              @visible-change="selectAreaList">
              <el-option v-for="item in areaList" :key="item.code" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="serAddress" class="address-detail">
            <el-input v-model="form.serAddress" placeholder="请输入经营地址"></el-input>
          </el-form-item>

        </el-form>
      </div>

      <div class="title">经营信息</div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="options" label-width="136px" class="demo-form">
          <el-form-item label="主营类目" prop="serMainCategoryPc">
            <el-cascader :options="allCategoryList" :props="props" :show-all-levels="false" @change="handleProductChange"
              @visible-change="selectProductTypeClick" v-model="form.serMainCategoryPc"></el-cascader>
          </el-form-item>
          <el-form-item label="主营地区" prop="serMainAreaPc">
            <el-cascader :options="allAddressList" :props="props" :show-all-levels="false" @change="handleChangeAddress"
              @visible-change="selectAddressClick" v-model="form.serMainAreaPc"></el-cascader>
          </el-form-item>
          <el-form-item label="企业类型" prop="serCompanyTypeName">
            <el-cascader :options="allCompanyTypeList" @change="handleChangeCompany"
              @visible-change="selectCompanyTypeClick" v-model="form.serCompanyTypeName"></el-cascader>
          </el-form-item>
        </el-form>
      </div>
      <div class="bottomBtn">
        <el-button class="el-item-submit" @click="backTop">返回</el-button>
        <el-button class="el-item-submit" @click="submitForm">提交审核</el-button>

      </div>
    </div>

  </div>
</template>
  
<script>
import { apiGetSubArea } from '@/api/index'
import { apiGetDict } from '@/api/index'
import { apiListCategoryWithSonList } from '@/api/index'
import { getAreaList } from '@/api/index'
import { apiGetUserAuth } from '@/api/index'
import bus from '@/views/accountManage/bus.js'

export default {
  name: 'FormStep1',
  components: {},
  // props: ['options'],

  data() {
    return {
      // form: this.options,
      form: {
                serAuthType: '',//法人还是委托人 0 法人  1委托人
                serCompanyName: '', //公司名称
                serLicenseCode: '', //社会信用代码
                serBusinessBeginDate: '',//经营期限开始日期
                serBusinessEndDate: '', //经营期限结束日期
                serBusinessDateAll: '',//是否选择无有效期
                serProvId: '', //省id
                serProvName: '',//省name
                serCityId: '', //市id
                serCityName: '', //市name
                serAreaId: '',//区id
                serAreaName: '',//区name
                serAddress: '',//详细地址
                idCardName:'',
                idCardNumber:'',
                serLegalName: '',//法人
                serLegalNo: '',  //法人身份证号
 
                serEntrustName: '',//委托人名称
                serEntrustNo: '', //委托人身份证号

                serIdCardTime: '', //身份证有效期
                serIdCardType: '0',//是否长期   
                // serMainCategory: '',//主营分类
                serMainCategoryPc: [],//主营分类数组 pc端使用
                // serMainArea: '', //主营地区
                serMainAreaPc: [],//主营地区数组 pc端使用
                serCompanyTypeName: '',//企业类型
                serCompanyTypeCode: '',//企业类型编号

                serLicenseImg: '', //营业执照

                serLegalFrontImg: '', //法人正面
                serEntrustFrontImg: '',//委托人正面

                serLegalBackImg: '', //法人反面
                serEntrustBackImg: '',//委托人反面

                serLegalHandImg: '',//法人手持
                serEntrustHandImg:'',
                serEntrustImg: '' //授权书

            },

      proviceList: [],
      cityList: [],
      areaList: [],
      value: [],
      props: { multiple: true },
      //主营类目
      allCategoryList: [],
      //地址
      allAddressList: [],
      //企业类型
      allCompanyTypeList: [],
      rules: {
        idCardName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        idCardNumber: [{ required: true, message: '请输入身份证号码', trigger: 'blur' }],
        serIdCardTime: [{ required: true, message: '请选择截止日期', trigger: 'blur' }],
        serCompanyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        serLicenseCode: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
        serAddress: [{ required: true, message: '请输入经营地址', trigger: 'change' }],
        address: [{ required: true,validator: this.checkMainCagetory }],
        serMainCategoryPc: [{ required: true , validator: this.checkMainCagetory}],
        serMainAreaPc: [{ required: true ,validator: this.checkMainCagetory}],
        serCompanyTypeName: [{ required: true,validator: this.checkMainCagetory }]

      },
    }
  },

  mounted() {
    this.$bus.$on("changeSecondForm", value => {
        // 主营类目接口
    this.selectProductTypeClick(),
      //选择地址接口
      this.selectAddressClick()
    //选择企业类型接口
    this.selectCompanyTypeClick()

      this.form.serAuthType = value.serAuthType
      this.form.serCompanyName = value.serCompanyName
      this.form.serLicenseCode = value.serLicenseCode
      this.form.serBusinessBeginDate = value.serBusinessBeginDate
      this.form.serBusinessEndDate = value.serBusinessEndDate
      this.form.serBusinessDateAll = value.serBusinessDateAll
      this.form.serProvId = value.serProvId
      this.form.serProvName = value.serProvName
      this.form.serCityId = value.serCityId
      this.form.serCityName = value.serCityName
      this.form.serAreaId = value.serAreaId
      this.form.serAreaName = value.serAreaName
      this.form.serAddress = value.serAddress
      this.form.idCardName = value.idCardName
      this.form.idCardNumber = value.idCardNumber
      this.form.serLegalName = value.serLegalName
      this.form.serLegalNo = value.serLegalNo
      this.form.serEntrustName = value.serEntrustName
      this.form.serEntrustNo = value.serEntrustNo
      this.form.serIdCardTime = value.serIdCardTime
      this.form.serIdCardType = value.serIdCardType
      this.form.serMainCategoryPc = value.serMainCategoryPc
      this.form.serMainAreaPc = value.serMainAreaPc
      this.form.serCompanyTypeName = value.serCompanyTypeName
      this.form.serCompanyTypeCode = value.serCompanyTypeCode
      this.form.serLicenseImg = value.serLicenseImg
      this.form.serLegalFrontImg = value.serLegalFrontImg
      this.form.serEntrustFrontImg = value.serEntrustFrontImg
      this.form.serLegalBackImg = value.serLegalBackImg
      this.form.serEntrustBackImg = value.serEntrustBackImg
      this.form.serLegalHandImg = value.serLegalHandImg
      this.form.serEntrustHandImg = value.serEntrustHandImg
      this.form.serEntrustImg = value.serEntrustImg

    
      if(this.form.serMainCategoryPc) {
        const serMainCategoryPc = this.form.serMainCategoryPc
        this.form.serMainCategoryPc = JSON.parse(serMainCategoryPc)
      }
    if (this.form.serMainAreaPc) {
        const serMainAreaPc = this.form.serMainAreaPc
        this.form.serMainAreaPc = JSON.parse(serMainAreaPc)
      }
    // console.log('接收到的第一步数据++++',this.form)
  });
  
    
  },
  methods: {
    checkMainCagetory (rule,value,callBack){
      callBack()
    },
    submitForm() {

      if (!this.form.idCardName) {
        this.$message({ message: "请输入姓名", type: "warning" });
        return
      } else if (!this.form.idCardNumber) {
        this.$message({ message: "请输入身份证号码", type: "warning" });
        return
      }

      if (this.form.serIdCardType == '0') {
        if (!this.form.serIdCardTime) {
          this.$message({ message: "请选择有效截止日期", type: "warning" });
          return
        }
      }
      if (!this.form.serCompanyName) {
        this.$message({ message: "请输入公司名称", type: "warning" });
        return
      } else if (!this.form.serLicenseCode) {
        this.$message({ message: "请输入正确的统一社会信用代码", type: "warning" });
        return
      } else if (!this.form.serBusinessBeginDate) {
        this.$message({ message: "请选择经营期限开始日期", type: "warning" });
        return
      } else if (this.form.serBusinessDateAll == '0') {
        if (!this.form.serBusinessEndDate) {
          this.$message({ message: "请选择经营期限结束日期", type: "warning" });
          return
        }
      }
      if (!this.form.serProvName) {
        this.$message({ message: "请选择省", type: "warning" });
        return
      } else if (!this.form.serCityName) {
        this.$message({ message: "请选择市", type: "warning" });
        return
      } else if (!this.form.serAreaName) {
        this.$message({ message: "请选择县", type: "warning" });
        return
      } else if (!this.form.serAddress) {
        this.$message({ message: "请输入经营地址", type: "warning" });
        return
      }else if (this.form.serMainCategoryPc == undefined || this.form.serMainCategoryPc == null|| this.form.serMainCategoryPc == '' ) {
        this.$message({ message: "请选择主营类目", type: "warning" });
        return
   
      }else if(this.form.serMainCategoryPc.length>15) {
        this.$message({ message: "主营类目不可超过15项，请修改。", type: "warning" });
        return
      }
      else if (this.form.serMainAreaPc == undefined || this.form.serMainAreaPc == null|| this.form.serMainAreaPc == '') {
        this.$message({ message: "请选择主营地区", type: "warning" });
        return
      }else if(this.form.serMainAreaPc.length>15) {
        this.$message({ message: "主营地区不可超过15项，请修改。", type: "warning" });
        return
      }
       else if (!this.form.serCompanyTypeName) {
        this.$message({ message: "请选择企业类型", type: "warning" });
        return
      }
      var submitJson = {}
      const keys = Object.keys(this.form)
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        this.$set(submitJson , element, this.form[element])
      }
      submitJson.serLegalName = submitJson.idCardName
      submitJson.serLegalNo = submitJson.idCardNumber

      submitJson.serEntrustName = submitJson.idCardName
      submitJson.serEntrustNo = submitJson.idCardNumber
    
      submitJson.serMainAreaPc = JSON.stringify(submitJson.serMainAreaPc)
      submitJson.serMainCategoryPc = JSON.stringify(submitJson.serMainCategoryPc)
      submitJson.serMainCategory = '' 
      submitJson.serMainArea = '' 
      // console.log('提交的实名认证数据========',submitJson)

      apiGetUserAuth(submitJson).then((res) => {
        if (res.code == '000000') {
          this.$message({ message: "您的信息提交成功，请耐心等待审核...", type: "success" });

          this.$emit("submitBaseInfoSuccess")
        } else {
          this.$message(res.msg)
        }
      })
    },

    //身份是否长期有效
    idcardCQDate(value) {
      this.form.serIdCardTime = ''
      this.form.serIdCardType = '1'
    },

    //选择身份证截止日期
    selectSFRQDate() {
      this.form.serIdCardType = '0'
    },
    //选择无期限 将开始和截止日期清空
    selectDateInput(value) {
      this.form.serBusinessEndDate = ''
      this.form.serBusinessDateAll = '1'
    },
    //选择经营期限事件回调
    selectJYDate() {
      this.form.serBusinessDateAll = '0'

    },
    handleShowMap() {
      this.dialogVisible = true
    },

    //选择省地址
    selectProviceList() {
      const data = { 'parentCode': '' }
      this.addressData(data, 0)
    },

    //选择市
    selectCityList() {
      if (this.form.serProvId) {
        const data = { 'parentCode': this.form.serProvId }
        this.addressData(data, 1)
      }

    },
    //选择县区
    selectAreaList() {
      if (this.form.serCityId) {
        const data = { 'parentCode': this.form.serCityId }
        this.addressData(data, 2)
      }

    },

    addressData(data, type) {
      apiGetSubArea(data).then((res) => {
        if (res.code == '000000') {
          if (type === 0) {
            this.proviceList = res.data;
          } else if (type === 1) {
            this.cityList = res.data;
          } else {
            this.areaList = res.data;
          }
        } else {
          // MessageBox(res.msg)
          console.log(res.msg)
        }
      })
    },

    //选择省市县回调
    selectProvice(value) {

      this.form.serProvName = value
      
      this.form.serProvId = this.proviceList.find((item) => {
        return item.name === value
      }).code

      console.log('选择省份回调+++++', value,this.form.serProvId)

      // this.form.serProvId = item.code
      // this.form.serProvName = item.name

      // //重新选中省份呢清空市区、县数据 
      this.form.serCityId = ''
      this.form.serCityName = ''
      this.form.serAreaId = ''
      this.form.serAreaName = ''
      
    },
    selectCity(value) {
      // console.log('选择市区回调+++++++++++=', item)
      this.form.serCityName = value
      
      this.form.serCityId = this.cityList.find((item) => {
        return item.name === value
      }).code

      // this.form.serCityId = item.code
      // this.form.serCityName = item.name



      //重新选择市 清空县级数据
      this.form.serAreaId = ''
      this.form.serAreaName = ''
    },

    selectArea(value) {
      this.form.serAreaName = value

      this.form.serAreaId = this.areaList.find((item) => {
        return item.name === value
      }).code

      // this.form.serAreaId = item.code
      // this.form.serAreaName = item.name
    },

    //基本信息接口
    //选择主营类目
    selectProductTypeClick() {
      //全部主营类目接口
      const data = { 'code': 'lease_manage_material_type' }
      apiGetDict(data).then((res) => {
        if (res.code == '000000') {
          this.selectSectionList(res.data)

        } else {
          console.log(res.msg)
        }
      })

    },

    selectSectionList(res) {
      const data = res
      const newData = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const dataDic = { label: element['value'], value: element['key'], children: ['1'] }
        newData.push(dataDic)
      }

      for (let index = 0; index < newData.length; index++) {
        const element = newData[index];
        apiListCategoryWithSonList({ parentCode: 0, materialType: element['value'] }).then((res) => {
          if (res.code == '000000') {
            const childrenList = res.data;
            const newChildrenList = []
            for (let newIndex = 0; newIndex < childrenList.length; newIndex++) {
              const element = childrenList[newIndex];
              const sectionJson = { label: element['name'], value: element['code'] }
              newChildrenList.push(sectionJson)
            }
            newData[index]['children'] = newChildrenList
            this.allCategoryList = newData

          } else {
            console.log(res.msg)
          }
        })
      }

    },
    //主营类目选择回调
    handleProductChange(item) {
      const itemArr = item
      this.form.serMainCategoryPc = itemArr
      console.log('主营分类选择回调++++++++', this.form.serMainCategoryPc)

    },
    //选择主营地区
    selectAddressClick() {
      const data = { 'level': '2' }
      getAreaList(data).then((res) => {
        if (res.code == '000000') {
          this.selectSectionAddressList(res.data)
        } else {
          console.log(res.msg)
        }
      })
    },

    selectSectionAddressList(res) {
      const data = res
      const newData = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const dataDic = { label: element['name'], value: JSON.stringify(element['code']), children: element['subList'] }
        newData.push(dataDic)
      }

      for (let index = 0; index < newData.length; index++) {
        const element = newData[index];
        const childrenList = element['children'];
        const newChildrenList = []
        for (let newIndex = 0; newIndex < childrenList.length; newIndex++) {
          const element = childrenList[newIndex];
          const sectionJson = { label: element['name'], value:JSON.stringify(element['code']) }
          newChildrenList.push(sectionJson)
        }
        newData[index]['children'] = newChildrenList
        this.allAddressList = newData
      }

    },
    //地区选择回调
    handleChangeAddress(item) {
      const itemList = item
      this.form.serMainAreaPc = itemList
    },

    //选择企业类型
    selectCompanyTypeClick() {
      const data = { 'code': 'jzl_company_type' }
      apiGetDict(data).then((res) => {
        if (res.code == '000000') {
          const data = res.data
          const newData = []
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const dataDic = { label: element['value'], value: element['key'] }
            newData.push(dataDic)
          }
          this.allCompanyTypeList = newData
        } else {
          console.log(res.msg)
        }
      })

    },
    //选择企业类型回调
    handleChangeCompany(value) {
      this.form.serCompanyTypeName = value[0]
      this.form.serCompanyTypeCode = value[0]
    },

    //提交数据
    submitClick() {
      this.$emit('submitForm', this.form)

    },
    //返回第一步上传照片
    backTop() {
      this.$emit('backTop')
    }

  },
}
</script>
  
<style lang="scss" scoped>
@import '@/styles/variables';

.index-box {
  background: white;
  flex: 1;
  // height: 864px;
  // margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  .index-box-banner {
    width: 100%;
    height: 154px;
    background: url(@/assets/images/realname/accountTopBanner.png);

  }

}

.title {
  height: 31px;
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
  line-height: 31px;
  display: flex;

}

.form-box {
  padding-top: 20px;
}

::v-deep .el-form-item__label {
  font-size: 14px;
  color: $primaryTextColor;
}

::v-deep .el-radio__inner {
  width: 12px;
  height: 12px;
}


.small ::v-deep .el-input--small .el-input__inner {
  width: 233px;
  height: 36px;
}

.length ::v-deep .el-input--small .el-input__inner {
  width: 305px;
}

.address ::v-deep .el-input--small .el-input__inner {
  width: 101px;
}

.address ::v-deep .el-select {
  width: 101px;
  margin-right: 5px;
}

.el-input--small .el-input__inner .el-select {
  width: 100px;
  margin-right: 5px;
}

::v-deep .el-textarea__inner {
  font-family: Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Arial, sans-serif;
  border-radius: 4px;
  border: 1px solid #ececec;
  height: 36px;
}

.data ::v-deep .el-input--small .el-input__inner {
  width: 130px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #BBBBBB;

}

::v-deep .el-col-11 {
  width: 130px;
  height: 36px;
}

::v-deep .el-col-2 {
  width: 30px;
  text-align: center;

}

::v-deep .el-form-item__label {
  font-size: 14px;
  // $primaryTextSize;
  color: $primaryTextColor;
}

::v-deep .el-cascader .el-input .el-input__inner {
  width: 233px;
}


//隐藏组件中的图标
// .data ::v-deep .el-icon-date:before {
//   content: "";
// }
// .data ::v-deep .el-input--prefix .el-input__inner  {
//   padding: 0px;
//   text-align: center;
// }
.address-detail {
  // margin-top: -13px;

  ::v-deep .el-input--small .el-input__inner {
    width: 313px;
  }
}

.item-button {
  margin-top: 40px;
}

.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.bottomBtn {
  display: flex;
  margin-top: 44px;
  height: 42px;
  justify-content: center;

  .el-item-submit {
    background: #E1251B;
    width: 168px;
    height: 42px;
    color: #FFFFFF;
    border-radius: 4px;

  }
}</style>