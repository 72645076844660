<template>
  <div class="component-container">
    <div class="component-content" @mouseenter="handleAllCategoryMouseEnter" @mouseleave="handleAllCategoryMouseLeave">
      <div class="title">
        <img src="@/assets/images/public/icon-menu.png" class="icon-menu" />
        全部商品分类
      </div>
      <div class="list" v-show="showAllCategory">
        <div class="item" @mouseenter="handleCategoryMouseEnter(index1)" @mouseleave="handleCategoryMouseLeave(index1)" v-for="(item, index1) in allCategoryList" :key="item.key" v-show="item.value!='其他'">
          <div class="title-box">
            <div class="title">{{ item.value }}</div>
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="sub-list" v-if="item.children">
            <template
              v-if="
                item.children
                  .slice(0, 3)
                  .map((item) => {
                    return item.name
                  })
                  .join('/').length > 17
              "
            >
              <div @click="handleClick(index1, index2, -1)" class="secondary-text" v-for="(subitem, index2) in item.children.slice(0, 2)" :key="subitem.id">
                <span v-if="index2 > 0">/</span>
                <span class='sub-item'>{{ subitem.name }}</span>
              </div>
            </template>
            <template v-else>
              <div @click="handleClick(index1, index2, -1)" class="secondary-text" v-for="(subitem, index2) in item.children.slice(0, 3)" :key="subitem.id">
                <span v-if="index2 > 0">/</span>
                <span class='sub-item'>{{ subitem.name }}</span>
              </div>
            </template>
          </div>
          <div v-if="popupList[index1].show && item.children.length > 0" class="popup-box">
            <div class="item" v-for="(more, index2) in item.children" :key="more.name + index2">
              <div class="title">{{ more.name }}</div>
              <div class="sub-list">
                <div @click="handleClick(index1, index2, index3)" class="secondary-text" v-for="(subitem, index3) in more.sonList" :key="subitem.name + index3">
                  <span class='sub-item'>{{ subitem.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'AllCategory',
  components: {},
  computed: {
    ...mapState({
      navActive: (state) => state.home.navActive,
      allCategoryList: (state) => state.home.allCategoryList,
    }),
  },
  data() {
    return {
      showAllCategory: false,
      popupList: [],
    }
  },
  watch: {
    navActive: {
      handler(newVal, oldVal) {
        if ((this.$route.path === '/' || this.$route.path === '/home/home') && newVal === 0) {
          this.showAllCategory = true
        } else {
          this.showAllCategory = false
        }
      },
      immediate: true,
    },
    allCategoryList: {
      handler(newVal, oldVal) {
        if (!newVal || (newVal && newVal.length === 0)) {
          return
        }
        newVal.forEach(() => {
          this.popupList.push({ show: false })
        })
      },
      immediate: true,
    },
  },
  mounted() {
    this.getAllCategoryList()
  },
  methods: {
    ...mapMutations('home', ['setNavActive', 'setAllCategoryListClickIndex']),
    ...mapActions('home', ['getAllCategoryList']),

    // 鼠标移入-全部商品分类
    handleAllCategoryMouseEnter() {
      this.showAllCategory = true
    },
    // 鼠标移出-全部商品分类
    handleAllCategoryMouseLeave() {
      if ((this.$route.path === '/' || this.$route.path === '/home/home') && this.navActive === 0) {
        return
      }
      this.showAllCategory = false
    },
    // 鼠标移入-商品分类
    handleCategoryMouseEnter(index) {
      this.popupList[index].show = true
    },
    // 鼠标移出-商品分类
    handleCategoryMouseLeave(index) {
      this.popupList[index].show = false
    },
    // 点击-分类
    handleClick(index1, index2, index3) {
      this.$router.push('/home/hire')
      this.showAllCategory = false
      this.setNavActive(1)
      this.setAllCategoryListClickIndex([index1, index2, index3])
      this.$bus.$emit('clickAllCategory', [index1, index2, index3])
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.component-container {
  width: 220px;
  background: #ffffff;
  position: relative;
  z-index: 100;
}
.component-content {
  > .title {
    width: 220px;
    height: 40px;
    background: #e1251b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  > .list {
    width: 100%;
    margin-top: 10px;
    height: 455px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > .item {
      position: relative;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      padding: 0 10px;
      cursor: pointer;
      &:hover {
        background: #f5f5f5;
        .title-box {
          color: $importantColor;
          font-weight: bold;
        }
      }
      > .title-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        font-size: $primaryTextSize;
        i {
          color: $secondaryTextColor;
        }
      }
      > .sub-list {
        width: 100%;
        padding-right: 10px;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .sub-item {
          flex-shrink: 0;
          &:hover {
            color: $importantColor;
          }
        }
      }
    }
  }
  .popup-box {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1000;
    background: #f5f5f5;
    padding: 14px 0;
    padding-left: 20px;
    .item {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 5px;

      .title {
        width: 100px;
        font-size: $secondaryTextSize;
        color: $primaryTextColor;
        text-align: right;
        margin-right: 19px;
        //text-wrap: nowrap;
      }
      .sub-list {
        width: 400px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        .secondary-text{
          margin-bottom: 13px;
        }
        .sub-item {
          margin-right: 20px;
          margin-bottom: 13px;
          cursor: pointer;
          &:hover {
            color: $importantColor;
          }
        }
      }
    }
  }
}
.icon-menu {
  width: 17px;
  margin-right: 5px;
  margin-top: 2px;
}
</style>
