<template>
  <div class="component-container">
    <div class="component-content">
      <div class="box box-left"></div>
      <div class="box box-center">
        <el-carousel height="455px" :interval="5000" arrow="always">
          <el-carousel-item v-for="(item, index) in bannerList" :key="item + index">
            <img :src="item.imgSrc" referrer="no-referrer|origin|unsafe-url" referrerPolicy="no-referrer" class="img" @click="handleBannerClick(item)" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="box box-right">
        <div class="user login" v-if="userInfo.userUuid">
          <div class="avatar">
            <img :src="userInfo.headImg || require('@/assets/images/public/avatar.png')" class="img-avatar" />
            <div class="vip-box" v-if="parseInt(userInfo.vipFlag) === 1">
              <img src="@/assets/images/home/icon-vip.png" class="icon-vip" />
            </div>
          </div>

          <div class="list">
            
            <div class="item" v-if="userInfo.entStatus != 0" :class="{ active: userInfo.entStatus === 3 }">
              <i class="img-icon img-icon-2" />
              <p class="text">施工单位</p>
            </div>
            <div class="item"  v-if="userInfo.serStatus != 0" :class="{ active: userInfo.serStatus === 3 }">
              <i class="img-icon img-icon-3" />
              <p class="text">服务商家</p>
            </div>
          </div>
          <p class="name">Hi~ {{ userInfo.userName || userInfo.phoneHide }} 欢迎来到聚循环</p>
          <div class="button" @click="toUserCenter">个人中心</div>
        </div>
        <div class="user" v-else>
          <div class="avatar">
            <img src="@/assets/images/public/avatar.png" class="img-avatar" />
          </div>
          <p class="name">Hi ~ 欢迎来到聚循环</p>
          <div class="button" @click="toLogin">登录/注册</div>
        </div>
        <div class="publish">
          <div class="item" @click="handlePublish(index)" v-for="(item, index) in publishList" :key="item">
            <img :src="require(`@/assets/images/home/icon-1${index + 1}.png`)" class="img-icon" />
            <p class="title">
              {{ item }}
              <span class="add">+</span>
            </p>
          </div>
        </div>
        <div class="fast-box" @click="handleFastPublish">
          <img src="@/assets/images/home/fast-icon.png" class="img-icon" />
          <p class="text-1 bold">30秒</p>
          <p class="text-2">快速发布</p>
          <span class="add">+</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { apiGetBannerList } from '@/api/index.js'
import { mapActions, mapMutations } from 'vuex'
export default {
  name: 'HomeBanner',
  components: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  data() {
    return {
      activeIndex: 0,
      bannerList: [],
      publishList: ['发布出租', '发布出售', '发布求租', '发布求购'],
    }
  },

  mounted() {
    apiGetBannerList({ spaceId: '13' }).then((res) => {
      this.bannerList = res.data.data
    })
  },
  methods: {
    ...mapActions('user', ['updateUserInfo']),
    // 发布
    handlePublish(index) {
      // 1：出租，2：出售，3：求租，4：求购
      if (this.userInfo.userUuid) {
        this.$router.push({ path: `/publish/${index + 1}` })
      } else {
        this.$router.push('/login')
      }
    },
    // 快速发布
    handleFastPublish() {
      this.updateUserInfo().then((res)=>{
        const { userUuid, authenticationStatus } = this.userInfo
        // console.log(userUuid);
        // console.log(authenticationStatus);
        // console.log(this.userInfo);
        // 1：出租，2：出售，3：求租，4：求购
        if (userUuid) {
          // 1 是 0 否 只有认证才能帮发布
          if(!authenticationStatus){
            return this.$confirm('只有认证才能快速发布，是否去认证？', '提示', {
              confirmButtonText: '去认证',
              cancelButtonText: '取消',
              customClass:'el-message-box_my',
            }).then(() => {
              this.$router.push({name:'accountManage', params: { changeSelect: 2}})
            }).catch(() => {});
          }
          this.$router.push('/quickPublish')
        } else {
          this.$router.push('/login')
        }
      })
    },
    // 登录
    toLogin() {
      this.$router.push('/login')
    },
    // 个人中心
    toUserCenter() {
      this.$router.push('/personal/home')
    },
    // banner 点击
    handleBannerClick(item) {
      window.location.href = item.hrefUrl
    },
  },
}
</script>
<style lang="scss">
.el-message-box_my {
  background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
}
</style>
<style lang="scss" scoped>
@import '@/styles/variables';
.component-content {
  flex-direction: row;
}
.box-left {
  width: 220px;
  height: 455px;
  background: #ffffff;
}
.box-center {
  width: 740px;
  height: 455px;
  margin: 0 10px;

  .img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.box-right {
  width: 220px;
  height: 455px;
  .user {
    width: 100%;
    height: 173px;
    background: url('@/assets/images/home/user-bg.png') no-repeat center 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 18px;
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      position: relative;
      .img-avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      .vip-box {
        width: 32px;
        height: 12px;
        background: linear-gradient(136deg, #f4d5b8 0%, #e5aa82 100%);
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-vip {
          width: 12px;
        }
        position: absolute;
        left: 9px;
        bottom: -5px;
      }
    }
    .name {
      margin-top: 19px;
    }
    .button {
      margin-top: 19px;
      width: 94px;
      height: 30px;
      background: linear-gradient(270deg, #f24c3a 0%, #ed3b32 100%);
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $secondaryTextSize;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
    &.login {
      .list {
        margin-top: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #bbbbbb;
        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }

          .img-icon {
            margin-right: 3px;
            &.img-icon-1 {
              width: 10px;
              height: 12px;
              background: url('@/assets/images/home/icon-31.png') no-repeat center 100%;
              background-size: 11px;
            }
            &.img-icon-2 {
              width: 11px;
              height: 11px;
              background: url('@/assets/images/home/icon-32.png') no-repeat center 100%;
              background-size: 11px;
            }
            &.img-icon-3 {
              width: 11px;
              height: 11px;
              background: url('@/assets/images/home/icon-33.png') no-repeat center 100%;
              background-size: 11px;
            }
          }
          &.active {
            color: #e2a278;
            .img-icon {
              &.img-icon-1 {
                background: url('@/assets/images/home/icon-21.png') no-repeat center 100%;
                background-size: 11px;
              }
              &.img-icon-2 {
                background: url('@/assets/images/home/icon-22.png') no-repeat center 100%;
                background-size: 11px;
              }
              &.img-icon-3 {
                background: url('@/assets/images/home/icon-23.png') no-repeat center 100%;
                background-size: 11px;
              }
            }
          }
        }
      }
      .name {
        margin-top: 6px;
        font-size: $secondaryTextSize;
      }
      .button {
        margin-top: 10px;
      }
    }
  }
  .publish {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    .item {
      width: 109px;
      height: 109px;
      background: linear-gradient(360deg, rgb(240, 219, 103, 0.05) 0%, rgb(225, 37, 27, 0.05) 100%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 2px;
      margin-bottom: 2px;
      cursor: pointer;
      //&:hover {
      //  .img-icon {
      //    transform: scale(1.05);
      //  }
      //}
      &:nth-child(2n) {
        margin-right: 0;
      }

      .img-icon {
        width: 42px;
        height: 42px;
      }
      .title {
        margin-top: 8px;
        font-size: $primaryTextSize;
        .add {
          font-family: Arial Black;
          font-weight: bold;
        }
      }
    }
  }
  .fast-box {
    width: 100%;
    height: 50px;
    background: url('@/assets/images/home/fast-bg.png') no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    //&:hover {
    //  transform: scale(1.05);
    //}
    .img-icon {
      width: 16px;
      margin-right: 2px;
    }
    .text-1 {
      font-size: 28px;
      font-family: Arial-Black, Arial;
      line-height: 40px;
      margin-right: 5px;
    }
    .text-2 {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      line-height: 19px;
      margin-right: 10px;
    }
    .add {
      font-family: Arial;
      font-weight: bold;
    }
  }
}
::v-deep .el-carousel__button {
  width: 8px;
  height: 0px;
  border: 2px solid rgba(255, 255, 255, 0.38);
  border-radius: 4px;
}
::v-deep .el-carousel__indicator.is-active button {
  width: 16px;
  height: 1px;
  border: 2px solid #ffffff;
}
</style>
