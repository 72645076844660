<template>
  <div class="component-container">
    <div class="component-content">
      <div class="box top-box">
        <div class="item">
          <div class="icon-box">
            <img src="@/assets/images/public/icon-1.png" class="img-icon" />
          </div>
          <p class="text">海量信息 真实有效</p>
        </div>
        <div class="item">
          <div class="icon-box">
            <img src="@/assets/images/public/icon-2.png" class="img-icon" />
          </div>
          <p class="text">专业可靠 安全舒心</p>
        </div>
        <div class="item">
          <div class="icon-box">
            <img src="@/assets/images/public/icon-3.png" class="img-icon" />
          </div>
          <p class="text">多仓直发 快速高效</p>
        </div>
        <div class="item">
          <div class="icon-box">
            <img src="@/assets/images/public/icon-4.png" class="img-icon" />
          </div>
          <p class="text">阳光租赁 一站服务</p>
        </div>
      </div>
      <div class="box bottom-box">
        违法和不良信息举报邮箱：jianchabu@feiiiu.net | 互联网举报中心<br />
        冀公网安备 13010202001700号 Copyright© 2008 JuPai.Net Inc.聚拍网 版权所有增值电信业务经营许可证 <a href="https://beian.miit.gov.cn/" target="_blank">冀ICP备2024057668号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeFooter',
  components: {},
}
</script>
<style lang="scss" scoped>
.component-content {
  .top-box {
    width: 100%;
    height: 213px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 100px;
      &:last-child {
        margin-right: 0;
      }
      .icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        .img-icon {
          width: 80px;
        }
      }
    }
  }
  .bottom-box {
    // display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 135px;
    background: #333333;
    font-size: 12px;
    color: #ffffff;
    padding-top: 45px;
    box-sizing: border-box;
    a {
      display: inline-block;
    }
  }
}
</style>
