//引入
import { JSEncrypt } from 'jsencrypt'
/**
 * 加密
 * @param {String}  需要加密的参数
 */
export function rsaEncrypt (param) {
  // 后台给的公钥
  let publicKey = decodeURIComponent('MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2zsMJMiqsoFWBubiV%2FKvumCL7h090u5HPBC8awOnwDYYJK%2FC36Yfz9DsDVWi7RBkEPTnZFvigyt%2Bxct%2BBbDlJGXAFJIyAUXlEDoIyQua0Yq5uictCysFQXwOHJIK6erVjAdIgc96%2FvoEH2ZniqTM%2BUtvuR5uIV3We7f9omflsXWe8mzXIbH68UXPyjeRKkTSinYDbIOsB69lAQiBeVg2elsqotvdyJE84rCe2lRFssYujUVSnTsN22Sk3hAg6T%2FzbAXFiiIxmzaWFt80TWLqC%2BSeFGY46WgZnJk%2F%2BBMQfIIDmlkLzPEODkR9Gge2BWXMkclmk3ykPwhpW%2FgbZzpgUQIDAQAB')
  let encryptor = new JSEncrypt() // 新建JSEncrypt对象
  encryptor.setPublicKey(publicKey) // 设置公钥
  let passwordEncryp = encryptor.encrypt(param) // 对密码进行加密
  return passwordEncryp
}

// 解密
export function decrypt (msg) {
  const privateKey = ''
  let decrypt = new JSEncrypt()
  decrypt.setPrivateKey(privateKey)
  var decryptMsg = decrypt.decrypt(msg)
  return decryptMsg
}
