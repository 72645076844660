<template>
    <div class="right-box">
        <!-- 实名认证第一步 -->
        <RealnameCerStep1 :options="form"  v-show="step === 0" @submitForm="stepFirstSubmitForm"/>

        <!-- 实名认证第二步-->
        <!-- <RealnameCerStep2 :options="form" v-if="step === 1" @submitBaseInfoSuccess="stepTwoSubmitForm" @backTop="backTop"/> -->
        <RealnameCerStep2  v-show="step === 1" @submitBaseInfoSuccess="stepTwoSubmitForm" @backTop="backTop"/>

        <!-- 实名认证结果 -->
        <RealnameResult v-if="step === 2" @editInfo="editInfo" />

    </div>
</template>
        
<script>
import RealnameCerStep1 from '@/views/accountManage/realName/company/index.vue'
import RealnameCerStep2 from '@/views/accountManage/realName/company/baseInfoIndex.vue'
import RealnameResult from '@/views/accountManage/realName/realNameResult/index.vue'
import { mapState,mapActions } from 'vuex'
import bus from '@/views/accountManage/bus.js'

export default {
    name: 'accountManage',
     components: { RealnameCerStep1, RealnameCerStep2, RealnameResult },

    props: ['options'],
    computed: {
        ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
    },

    data() {
        return {
            step: 0,
            serStatus: '',

            form: {
                serAuthType: '0',//法人还是委托人 0 法人  1委托人
                serCompanyName: '', //公司名称
                serLicenseCode: '', //社会信用代码
                serBusinessBeginDate: '',//经营期限开始日期
                serBusinessEndDate: '', //经营期限结束日期
                serBusinessDateAll: '',//是否选择无有效期
                serProvId: '', //省id
                serProvName: '',//省name
                serCityId: '', //市id
                serCityName: '', //市name
                serAreaId: '',//区id
                serAreaName: '',//区name
                serAddress: '',//详细地址
                idCardName:'',
                idCardNumber:'',
                serLegalName: '',//法人
                serLegalNo: '',  //法人身份证号
 
                serEntrustName: '',//委托人名称
                serEntrustNo: '', //委托人身份证号

                serIdCardTime: '', //身份证有效期
                serIdCardType: '0',//是否长期   
                // serMainCategory: '',//主营分类
                serMainCategoryPc: '',//主营分类数组 pc端使用
                // serMainArea: '', //主营地区
                serMainAreaPc: '',//主营地区数组 pc端使用
                serCompanyTypeName: '',//企业类型
                serCompanyTypeCode: '',//企业类型编号

                serLicenseImg: '', //营业执照

                serLegalFrontImg: '', //法人正面
                serEntrustFrontImg: '',//委托人正面

                serLegalBackImg: '', //法人反面
                serEntrustBackImg: '',//委托人反面

                serLegalHandImg: '',//法人手持
                serEntrustHandImg:'',
                serEntrustImg: '' //授权书

            },

        }

    },
    created() {
        this.updateUserInfo().then(res=>{
            if (this.userInfo.serStatus === 0 || this.userInfo.serStatus === 1) {
            this.step = 0
        } else {
            this.step = 2
        }
        })
    },
 
    methods: {
        ...mapActions('user', ['updateUserInfo']),

        //第一步（上传图片）提交
        stepFirstSubmitForm(res) {
            this.form = res
            if (this.form.serAuthType === '0') {
                this.form.idCardName = this.form.serLegalName
                this.form.idCardNumber = this.form.serLegalNo
            } else {
                this.form.idCardName = this.form.serEntrustName
                this.form.idCardNumber = this.form.serEntrustNo
            }
            console.log('第一步点击信息确认++++++',this.form)

            this.step = 1
            this.$bus.$emit('changeSecondForm', this.form)


        },
        // 第二步（保存基本信息）提交
        stepTwoSubmitForm() {
            this.step = 2
        },
        // 第三步（驳回编辑状态点击）
        editInfo(res) {
            //请求实名认证数据
            this.step = 0
            this.form = res
            bus.$emit('changeForm', this.form)
        },
        backTop(){
            this.step = 0
        }
    },
}
</script>
        
<style lang="scss" scoped>
@import '@/styles/variables';

.right-box {
    width: 100%;
    background-color: white;
    padding-bottom: 10px;
    margin-left: 10px;
}
</style>
    