<template>
    <div class="page-container">
        <Header @home="goHme" />
        <div class="page-content">
            <div class="main-content">
                <!-- 导航 -->
                <div class="breadcrumb-box">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>{{breadcrunmdTitle}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <!-- 内容 -->
                <div class="tab-box">
                    <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="企业名称" prop="entName">
                            <el-input placeholder="请输入企业名称" v-model="form.entName"></el-input>
                        </el-form-item>
                        <el-form-item label="物资联系人" prop="contactName">
                            <el-input placeholder="请输入物资联系人" v-model="form.contactName"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人电话" prop="contactPhone" type="number" maxlength="11">
                            <el-input placeholder="请输入联系人电话" v-model="form.contactPhone"></el-input>
                        </el-form-item>
                        <el-form-item label="物资所在地" prop="address" class="address">
                            <el-input v-model="form.address" readonly placeholder="请输入物资详情地址"></el-input>
                            <div class="mask" @click="handleShowMap"></div>

                            <!-- <el-select v-model="form.provName" placeholder="请选择省">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                            <el-select v-model="form.cityName" placeholder="请选择市" style="margin-left: 5px;">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                            <el-select v-model="form.areaName" placeholder="请选择县" style="margin-left: 5px;">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                            <el-input class="detailAddressInput" v-model="form.address" placeholder="请输入物资详细地址"></el-input> -->
                        </el-form-item>

                        <!-- <el-form-item label="物资说明" prop="desc">
                            <el-input placeholder="填写详细的说明" type="textarea" v-model="form.desc"></el-input>
                        </el-form-item> -->
                        <el-form-item label="物资附件" prop="annexUrl">
                            <UploadFile ref="uploadImg" @success="handleUploadImageSuccess" :imageList="form.annexUrl"
                                :fileTypes="['.jpg', '.jpeg', '.png', '.rar', '.zip', '.pdf', '.doc', '.docx', '.xls', '.xlsx']"
                                multiple />
                            <p class="desc">支持扩展名：jpg、jpeg、png、rar、zip、pdf、doc、docx、xls、xlsx等格式文件，最多可上传10张</p>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="toHome">返回</el-button>
                            <el-button type="primary" @click="quickPublish('form')">{{submitText}}</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <!-- 高德地图 -->
            <el-dialog title="选择物资所在地" :visible.sync="dialogVisible" width="800px" :close-on-click-modal="false">
                <MapContainer v-if="dialogVisible" @confirm="handleConfirmAddress" @cancel="dialogVisible = false"
                    :lng="form.lon || userPosition.location.lng" :lat="form.lat || userPosition.location.lat" />
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
import { mapState, mapActions } from 'vuex'
import Header from '@/components/public/Header.vue'
import UploadFile from '@/views/publish/components/UploadFile.vue'
import MapContainer from '@/views/publish/components/MapContainer.vue'
import { apiDemandDetail, apiDemandModify, apiDemandRelease, apiGetMyLeaseInfo } from '@/api/index'
import { isMobile } from '@/utils/validate'

export default {
    name: 'HomeIndex',
    components: { Header, UploadFile, MapContainer },
    computed: {
        ...mapState({
            navActive: (state) => state.home.navActive,
            userInfo: (state) => state.user.userInfo,
            userPosition: (state) => state.user.userPosition,

        }),
    },


    data() {
        var validatePhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入联系人电话'))
            } else if (!isMobile(value)) {
                callback(new Error('联系人电话格式不正确'))
            } else {
                callback()
            }
        }
        return {
            dialogVisible: false,
            form: {
                //用户id
                userUuid: '',
                //企业名称
                entName: '',
                //经度
                lon: '',
                //纬度
                lat: '',
                //联系电话
                contactPhone: '',
                //联系人姓名
                contactName: '',
                //行政区编码
                adcode: '',
                //详细地址
                address: '',
                //附件
                annexUrl: [],

            },
            rules: {
                entName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
                contactName: [{ required: true, message: '请输入物资联系人', trigger: 'blur' }],
                contactPhone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
                // leaseDesc: [{ required: true, message: '请输入物资说明', trigger: 'blur' }],
                address: [{ required: true, message: '请选择物资所在地', trigger: 'blur' }],
                annexUrl: [{ required: true, validator: this.checkFile }]

            },
            quickPublishEdit:false,
            breadcrunmdTitle:'快速发布信息',
            submitText:'发布物资',

        }
    },

    created(){
      let { id } = this.$route.params
      if (id) {
        this.quickPublishEdit = true
        this.breadcrunmdTitle = '编辑委托发布信息'
        this.submitText = '编辑物资'
        // 获取信息
        this.getDemandDetail(id);
      }
    },
    methods: {
        ...mapActions('user', ['getUserInfo']),
        checkFile(rule, value, callBack) {
            callBack()
        },
        getDemandDetail(id){
          apiDemandDetail({
            id,
            userUuid: this.userInfo.userUuid,
          }).then((res) => {
            if(res.data){
              let itemNameArr = res.data.annexName.split(',');
              let formObj = {
                ...res.data,
                annexUrl: res.data.annexUrl ?
                  res.data.annexUrl.split(',').map((item,index) => {
                    return { name: itemNameArr[index] ? itemNameArr[index] : item.substring(item.lastIndexOf("/")+1), url: item }
                  }) : [],
                adcode:res.data.areaId
              }
              this.form = formObj
              this.handleUploadImageSuccess(formObj.annexUrl)
            }
          })
        },
        quickPublish(formName) {

            if (!this.form.entName) {
                this.$message({ message: "请输入企业名称", type: "warning" });
            } else if (!this.form.contactName) {
                this.$message({ message: "请输入物资联系人", type: "warning" });

            } else if (!this.form.contactPhone) {
                this.$message({ message: "请输入联系人电话", type: "warning" });
            } else if (!this.form.address) {
                this.$message({ message: "请输入物资所在地", type: "warning" });

            } else if (!this.form.annexUrl || this.form.annexUrl.length == 0) {
                this.$message({ message: "请选择物资附件", type: "warning" });

            } else {
                const json = JSON.parse(JSON.stringify(this.form))
                var annexList = json.annexUrl
                var URLList = []
                var annexNameArr = [];
                if (annexList.length) {
                    for (let index = 0; index < annexList.length; index++) {
                        const element = annexList[index];
                        URLList.push(element.url)
                        annexNameArr.push(element.name)
                    }
                }
                json.annexUrl = URLList.join(",")
                json.annexName = annexNameArr.join(",")
                if(this.quickPublishEdit == true){
                  apiDemandModify(json).then((res) => {
                    if (res.code == '000000') {
                      this.$message({ message: "提交成功", type: "success" })
                      setTimeout(() => {
                        this.$router.push({
                          name:'phome',
                          params:{
                            changeSelect:7
                          }
                        })
                      }, 500)
                    } else {
                      this.$confirm(res.msg, '提示', {
                        confirmButtonText: '确定',
                        showCancelButton:false,
                        customClass:'el-message-box_my'
                      }).then(() => {
                        setTimeout(() => {
                          this.$router.push({
                            name:'phome',
                            params:{
                              changeSelect:7
                            }
                          })
                        }, 500)
                      }).catch(() => {});
                      // this.$message({ message: res.msg, type: "warning" });
                    }
                  })
                }else{
                  apiDemandRelease(json).then((res) => {
                    console.log('返回数据' + JSON.stringify(res))
                    if (res.code == '000000') {
                      console.log('')
                      this.$message({ message: "提交成功", type: "success" })
                      setTimeout(() => {
                        this.$router.push({
                          name:'phome',
                          params:{
                            changeSelect:7
                          }
                        })
                      }, 500)
                    } else {
                      this.$message({ message: res.msg, type: "warning" });
                    }
                  })
                }
            }
        },



        // 格式化数据
        // formatData(json) {

        //     const item = {}
        //     for (const key in json) {
        //         if (key !== 'id') {
        //             item[key] = json[key]
        //         }
        //     }
        //     item.annexUrl = json.annexUrl
        //         .map((item) => {
        //             return item.url
        //         })
        //         .join(',')
        //     return item
        // },


        back(formName) {
            this.$refs[formName].resetFields()
            this.$router.go(-1)
        },
        // 上传文件成功
        handleUploadImageSuccess(list) {
            this.form.annexUrl = list
            console.log('上传文件成功', list)
        },
        // 返回首页
        toHome() {
            this.$router.push('/')
        },
        //展示高德地图
        handleShowMap() {
            this.dialogVisible = true
        },
        // 确认地址
        handleConfirmAddress(result) {
            const { province, city, district, township, street, neighborhood, building, adcode } = result.regeocode.addressComponent
            this.dialogVisible = false
            this.form.address = result.regeocode.formattedAddress
            this.form.lat = result.lat
            this.form.lon = result.lng
            this.form.adcode = adcode
        },
        // 返回首页
        goHme() {
            this.$router.push('/')
            this.setNavActive(0)
        },
    },
}
</script>
<style lang="scss">
.el-message-box_my {
  background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
}
</style>
<style lang="scss" scoped>
@import '@/styles/variables';

.page-container {
    .page-content {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        background: #ffffff;
    }
}

.main-content {
    width: $contentWidth;
    padding: 11px 0 60px;
}

.breadcrumb-box {
    width: 100%;
}

.tab-box {
    margin-top: 3px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .el-input--small .el-input__inner {
    width: 313px;
    font-size: 12px;
    height: 36px;
}

::v-deep .el-input--suffix .el-input__inner {
    width: 101px;
}

::v-deep .el-textarea__inner {
    width: 313px;
    height: 60px;
}

.detailAddressInput {
    margin-top: 5px;
}

.index-content {
    padding: 10px 0;
}

.desc {
    font-size: 12px;
    color: #BBBBBB;

}

.mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}
</style>
  