<template>
  <div class='tips'>
    <img src="@/assets/images/public/tips.png"/>
    <span>交易过程中可能会产生服务费，发布后将有业务人员与您联系。</span>
  </div>
</template>

<script>
export default {
  name: 'FormTips'
}
</script>

<style scoped lang='scss'>
  .tips{
    display: flex;
    margin-top: 24px;
    img{
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }
    span{
      font-size: 12px;
      color: #BBBBBB;
      line-height: 16px;
    }
  }
</style>