import Cookies from 'js-cookie'

const TokenKey = 'userUuid'

export function getToken() {
  return Cookies.get(TokenKey)
}

//默认有效期，会话级别
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}