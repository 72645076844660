<template>
  <div class="item-module-goods-list">
    <div class="item-module-goods-list-top">
      <p class="item-module-goods-list-top-text">委托时间：{{ demandInfoItem.addTime }}</p>
    </div>
    <div class="item-module-goods-list-bottom">
      <div class="item-module-goods-list-bottom-left">
        <div class="item-module-goods-list-bl-content detailBaseInfo">
          <p class="item-module-goods-list-bl-content-p ellipsis2" style="font-weight: bold;font-size: 16px;">{{ demandInfoItem.entName }}</p>
          <p class="item-module-goods-list-bl-content-p ellipsis" style="margin-top: 10px;">物资联系人：{{ demandInfoItem.contactName }}</p>
          <p class="item-module-goods-list-bl-content-p" style="margin-top: 3px">联系人电话：{{ demandInfoItem.contactPhone }}</p>
          <p class="item-module-goods-list-bl-content-p address" style="margin-top: 3px">
            <span>物资所在地：</span>
            <span class='ellipsis2'>{{ demandInfoItem.provName }}{{ demandInfoItem.cityName }}{{ demandInfoItem.areaName }}{{ demandInfoItem.address }}</span>
          </p>
        </div>
        <div class="item-module-goods-list-bl-content fileContent">
          <div class='fileTitle'>
            <span>附件：</span>
            <div v-if='demandInfoItem.annexUrlList.length > 5'>
              <img :src="imageSrc" class="icon-menu" @click="toggleImage"/>
            </div>
          </div>
          <div class='fileList' :style="{height:fileBlockHeight}">
            <div v-for="(item,index) in demandInfoItem.annexUrlList" :key='index'>
              <a :href="item.file" target='_blank' class='ellipsis'>{{index+1}}、{{item.name}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="item-module-goods-list-bottom-right">
        <div class="item-module-goods-list-right-item1">
          <div v-if="demandInfoItem.acceptStatus == 0 || (demandInfoItem.acceptStatus == 1 && demandInfoItem.flowStatus == 0)" class="item-module-goods-list-left-item">
            <p class="item-module-goods-list-br-p1">待受理</p>
          </div>
          <div v-else-if="demandInfoItem.flowStatus == 1" class="item-module-goods-list-left-item">
            <p class="item-module-goods-list-br-p1">待发布</p>
          </div>
          <div v-else-if="demandInfoItem.flowStatus == 2" class="item-module-goods-list-left-item">
            <p class="item-module-goods-list-br-p1">已发布</p>
          </div>
          <div v-else-if="demandInfoItem.acceptStatus == 3" class="item-module-goods-list-left-item">
            <p class="item-module-goods-list-br-p1">无效</p>
          </div>
        </div>
        <div class="item-module-goods-list-right-item2" v-if="demandInfoItem.acceptStatus == 0 || (demandInfoItem.acceptStatus == 1 && demandInfoItem.flowStatus == 0) || demandInfoItem.acceptStatus == 3">
          <p class="item-module-goods-list-br-p1" style="padding-right: 10px; border-right: 1px dashed #EAEAEA; cursor: pointer" @click.stop="handleEdit(demandInfoItem.id)">修改</p>
          <p class="item-module-goods-list-br-p1" style="padding-left: 10px; cursor: pointer" @click.stop="handleDel(demandInfoItem.id)">删除</p>
        </div>
        <div class="item-module-goods-list-right-item2" v-else-if="demandInfoItem.flowStatus == 1">
          <p class="item-module-goods-list-br-p1" style="padding-right: 10px; border-right: 1px dashed #EAEAEA; pointer-events: none;color: #BBBBBB;">修改</p>
          <p class="item-module-goods-list-br-p1" style="padding-left: 10px;pointer-events: none;color: #BBBBBB;">删除</p>
        </div>
        <div class="item-module-goods-list-right-item2" v-else-if="demandInfoItem.flowStatus == 2">
          <el-popover
            placement="bottom-end"
            width="400"
            trigger="click">
            <div class='goodsItemList'>
              <div class='ellipsis goodsItemName' v-for='(item,index) in demandInfoItem.leaseInfoList' :key='index' @click='handleDetail(item.infoNo,item.specId)'>{{item.materialName}}</div>
            </div>
            <el-button class="goodsInfoBtn" slot="reference">查看物资信息({{demandInfoItem.leaseInfoList.length}})</el-button>
          </el-popover>
        </div>
        <div class="item-module-goods-list-right-item2" v-else></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { demandRemove } from '@/api/personal'
import { apiDemandDetail } from '@/api'
export default {
  name: 'ReleaseItem',
  props:{
    demandInfoItem:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  data(){
    return{
      imageSrc: require('@/assets/images/public/showUp.png'),
      currentIndex: 0, // 当前图片的索引
      fileBlockHeight:'90px',
    }
  },
  methods:{
    toggleImage(){
      if(this.currentIndex == 0){
        this.imageSrc = require('@/assets/images/public/showDown.png');
        this.fileBlockHeight = 'auto';
        this.currentIndex = 1;
      }else{
        this.imageSrc = require('@/assets/images/public/showUp.png')
        this.currentIndex = 0;
        this.fileBlockHeight = '90px';
      }
    },
    // 删除
    handleDel(id){
      let params = {
        userUuid:this.userInfo.userUuid,
        id:id
      }
      this.$confirm('确定删除此委托信息码？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'el-message-box_my',
      }).then(() => {
        demandRemove(params).then((res) => {
          if (res.success) {
            this.$parent.getDemandListInfo();
          } else {
            this.$message({ message: res.msg, type: 'warning' });
            this.$parent.getDemandListInfo();
          }
        })
      }).catch(() => {});
    },
    // 修改
    handleEdit(id){
      apiDemandDetail({
        id,
        userUuid: this.userInfo.userUuid,
      }).then((res) => {
        if(res.data){
          let detailInfo = res.data;
          if(detailInfo.acceptStatus == 0 || (detailInfo.acceptStatus == 1 && detailInfo.flowStatus == 0) || detailInfo.acceptStatus == 3){
            this.$router.push({ path: `/quickPublish/${id}` })
          }else{
            // 已受理
            if(detailInfo.flowStatus == 1 || detailInfo.flowStatus == 2){
              let msg = '该委托信息不支持修改';
              if(detailInfo.flowStatus == 1){
                msg = '已受理的委托信息不支持修改';
              }else if(detailInfo.flowStatus == 2){
                msg = '已发布的委托信息不支持修改';
              }
              this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                showCancelButton:false,
                customClass:'el-message-box_my'
              }).then(() => {
                this.$parent.getDemandListInfo();
              }).catch(() => {});
            }
          }
        }
      })
    },
    //查看物资详情
    handleDetail(infoNo,specId){
      // 跳转详情
      var handleCode = 'edc66a6a8601378d'
      // 登录成功后未开通会员，弹窗领取会员后可查看详情
      this.$router.push({
        path: '/product/detail',
        query: {
          infoNo: infoNo,
          specId:specId,
          subheads: '发布管理',
          handleCode: handleCode,
        },
      })
    }
  }
}
</script>
<style lang="scss">
.el-message-box_my {
  background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%) !important;
}
</style>
<style scoped lang='scss'>
  .item-module-goods-list {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 15px 0px;
    margin: 0px 15px;
    border-bottom-color: #eaeaea;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    .item-module-goods-list-top {
      display: flex;
      flex-direction: row;
      justify-content: start;
      font-size: 14px;
      color: #bbbbbb;
      .item-module-goods-list-top-text {
        margin-left: 10px;
      }
    }
    .item-module-goods-list-bottom {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: flex-start;
      padding: 0 10px;
      .item-module-goods-list-bottom-left {
        display: flex;
        flex-direction: row;
        flex: 1;
        padding-top: 15px;
        cursor: pointer;
        .detailBaseInfo{
          width: 395px;
          padding-right: 50px;
          border-right: 1px solid #EAEAEA;
          .address{
            display: flex;
            span{
              &:first-child{
                flex: 1;
                min-width: 84px;
              }
            }
          }
        }
        .fileContent{
          width: 334px;
          padding: 0 40px;
          .fileTitle{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 4px;
            img{
              width: 18px;
              height: 18px;
            }
          }
          .fileList{
            font-size: 12px;
            color: #1569E7;
            line-height: 18px;
            height: 90px;
            overflow: hidden;
            div{
              a{
                word-break: break-all;
              }
            }
          }
        }
      }
      .item-module-goods-list-bottom-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        padding-top: 10px;
        .item-module-goods-list-right-item1{
          width: 124px;
          height: 116px;
          text-align: center;
          border-left: 1px solid #EAEAEA;
          border-right: 1px solid #EAEAEA;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .item-module-goods-list-right-item2{
          width: 163px;
          height: 116px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          .goodsInfoBtn{
            border: none;
            color: #e1251b;
            &:hover, &:focus{
              color: #e1251b;
              background: #FFFFFF;
            }
          }
        }
        .item-module-goods-list-br-p1 {
          font-size: 14px;
          color: #e1251b;
        }
      }
    }
}
  .goodsItemList{
    .goodsItemName{
      cursor: pointer;
      color: #333333;
      line-height: 27px;
      &:hover{
        color: #E1251B;
      }
    }
  }
</style>