<template>
    <div class="index-box">
        <!-- <div class="banner-box"></div> -->
        <img src="@/assets/images/realname/accountTopBanner.png" style="width: 100%;height: 154px;" alt="">
        <div class="index-info-box">
            <div class="index-title">头像</div>
            <img v-if="!picturl" src="@/assets/images/realname/head.png" style="width: 60px;height: 60px;margin-left: 10px;" alt="">
            <img v-if="picturl" :src="picturl" style="width: 60px;height: 60px;margin-left: 10px; border-radius: 50%;" alt="">
            <el-upload style="margin-left: 10px;" class="upload-demo" ref="upload" action="https://v0.api.upyun.com/jupaiwang" :show-file-list="false"
                :on-success="onSuccess" :before-upload="beforeUpload" list-type="picture" :data="fileExtendData">
                <el-button slot="trigger" size="small" type="text">上传头像</el-button>
            </el-upload>
        </div>
        <div class="index-info-box">
            <div class="index-title">用户名称</div>
            <el-input placeholder="名称" class="el-item-text" v-model="userName"> </el-input>
        </div>
        <div class="index-info-box">
            <div class="index-title">用户账号</div>
            <el-input placeholder="账号" class="el-item-text"  readonly v-model="userInfo.phone"> </el-input>
        </div>
        <div class="index-info-box">
            <div class="index-title">用户身份</div>
            <div class="item" v-if="entStatus != 0" :class="{ active: userInfo.entStatus === 3 }">
                <i class="img-icon img-icon-2"  />
                <p class="text">施工单位</p>
            </div>
            <div class="item" v-if="serStatus != 0" :class="{ active: userInfo.serStatus === 3 }">
                <i class="img-icon img-icon-3" />
                <p class="text">服务商家</p>
            </div>

        </div>
        <div class="button primary-button" @click="submitForm">确认修改</div>
    </div>
</template>
        
<script>
import { mapState } from 'vuex'
import { getUpyunToken } from '@/api/common'
import {modifyUserInfo} from '@/api/user'
import { mapActions, mapMutations } from 'vuex'

export default {
    name: 'changePassword',
    components: {},

    computed: {
        ...mapState({
            navActive: (state) => state.home.navActive,
            userInfo: (state) => state.user.userInfo,

        }),
    },
    created() {
        this.userName  = this.userInfo.userName
        this.serStatus  = this.userInfo.serStatus
        this.entStatus  = this.userInfo.entStatus
        this.picturl = this.userInfo.headImg

    },
    data() {
        return {
            userName:'',
            serStatus:'',
            entStatus:'',

            fileExtendData: {},
            fileList: [],
            picturl:'',
            normarlPicrrul:'',
        }
    },
    methods: {
        ...mapActions('user', ['updateUserInfo']),

        submitForm() {
            var parm = {userName:this.userName,headImg:this.picturl}
            modifyUserInfo(parm).then((res) => {
                    if (res.code == '000000') {
                        this.$message({message: '提交成功',type: "success"});
                        //刷新用户信息
                        this.updateUserInfo()
                    } else {
                        this.$message({message: res.msg,type: "error"});
                    }
                })

        },
        // 获取 token
        beforeUpload() {
            return new Promise((resolve, reject) => {
                var params = { propKey: 'jupai' }
                getUpyunToken(params).then((res) => {
                    if (res.code == '000000') {
                        this.fileExtendData = {
                            policy: res.data.policy,
                            authorization: 'UPYUN jupai:' + res.data.sign,
                        }
                        resolve()
                    } else {
                        reject()
                    }
                })
            })
        },
        // 上传成功
        onSuccess(response, file, fileList) {
            console.log('上传图片url==========', JSON.stringify(response))
            this.picturl = process.env.VUE_APP_UPYUN_API + response.url
            // this.dialogImageUrl=process.env.VUE_APP_UPYUN_API + response.url
        },
    },
}
</script>
        
<style lang="scss" scoped>
@import '@/styles/variables';




.index-box {
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    height: 875px;
    flex-direction: column;
    margin-left: 10px;


    .banner-box {
        width: 100%;
        height: 154px;
        background-image: url(@/assets/images/realname/accountTopBanner.png);
    }

    .index-info-box {
        display: flex;
        width: 400px;
        background: white;
        align-items: center;
        margin-bottom: 15px;

        .index-title {
            height: 19px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #333333;
            width: 100px;
            text-align: right;
            flex-shrink: 0;
        }

        .item {

            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;

            &:last-child {
                margin-right: 0;
            }

            .img-icon {
            margin-right: 3px;
            &.img-icon-2 {
              width: 11px;
              height: 11px;
              background: url('@/assets/images/home/icon-32.png') no-repeat center 100%;
              background-size: 11px;
            }
            &.img-icon-3 {
              width: 11px;
              height: 11px;
              background: url('@/assets/images/home/icon-33.png') no-repeat center 100%;
              background-size: 11px;
            }
          }
          &.active {
            color: #e2a278;
            .img-icon {
              &.img-icon-2 {
                background: url('@/assets/images/home/icon-22.png') no-repeat center 100%;
                background-size: 11px;
              }
              &.img-icon-3 {
                background: url('@/assets/images/home/icon-23.png') no-repeat center 100%;
                background-size: 11px;
              }
            }
          }

            .text {
                font-size: 12px;
                font-family: MicrosoftYaHei;
                // color: #E2A278;
            }

        }

    }


    .button {
        margin-top: 40px;
        width: 168px;
        height: 42px;
    }
}

::v-deep .el-input--small .el-input__inner {
    width: 277.28px;
    margin-left: 10px;
}

::v-deep .el-button {
    background: white;
    color: #E1251B;
    border-color: white;
}
</style>
    