import { apiGetDict, apiListCategoryWithSonList } from '@/api/index'

const state = () => {
  return {
    navActive: 0, // 导航索引
    allCategoryList: null, // 全部商品分类
    allCategoryListClickIndex: [-1, -1, -1], // 点击商品分类索引
    searchKeywords: '', // 搜索关键词
  }
}

const mutations = {
  setNavActive(state, index) {
    state.navActive = index
  },
  setAllCategoryList(state, allCategoryList) {
    state.allCategoryList = allCategoryList.filter(item => item.value !== '其他')
  },
  setSearchKeywords(state, keywords) {
    state.searchKeywords = keywords
  },
  setAllCategoryListClickIndex(state, index) {
    state.allCategoryListClickIndex = index
  },
}

const actions = {
  getAllCategoryList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apiGetDict({ code: 'lease_manage_material_type' })
        .then((res) => {
          const { data } = res
          if (data && data.length > 0) {
            data.forEach((item) => {
              item.children = []
              apiListCategoryWithSonList({ parentCode: 0, materialType: item.key }).then((res) => {
                const { data } = res
                if (data) {
                  item.children = res.data
                }
              })
            })
            commit('setAllCategoryList', res.data)
          }
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
