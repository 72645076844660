<template>
  <div class="component-container">
    <div class="component-content">
      <div class="goods-list">
        <div class="item" :class="{ active: index === activeIndex }" v-for="(item, index) in materialList"
          :key="item.materialName">
          <div class="label">{{ item.materialTypeName }}/ {{ item.materialName }}</div>
          <div class="oprate">
            <i class="el-icon-edit-outline" @click="handleEditMaterial(index)"></i>
            <div class="line"></div>
            <i class="el-icon-delete" @click="handleDeleteMaterial(index)"></i>
          </div>
        </div>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="formStep2" label-width="200px" class="demo-form" :key="form.id">
          <el-form-item label="物资分类" prop="materialType">
            <el-select v-model="form.materialType" placeholder="请选择物资分类" @change="materialTypeChange">
              <el-option v-for="item in allCategoryList" :key="item.key" :label="item.value"
                :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="materName" label="物资名称" prop="materialCategory">
            <!-- @blur="hiddenQuickCagetory" -->
            <el-input v-if="form.materialTypeName === '其他'" v-model="form.materialName" placeholder="请输入物资名称"></el-input>
            <el-input v-else v-model="form.materialName" placeholder="请选择物资名称" filterable readonly
              @focus="materialNameChange" @blur="hiddenQuickCagetory">
            </el-input>
            <QuickAllCagetory class="all-catrgory" v-show="showQuickCagetopry" />
          </el-form-item>
          <el-form-item label="物资照片" prop="pictureUrl">
            <UploadImage ref="uploadImg" @success="handleUploadImageSuccess" :imageList="form.pictureUrl" multiple />
            <p class="desc">支持扩展名：jpg、jpeg、png等格式照片，最多可上传10张</p>
          </el-form-item>
          <!-- 规格型号 -->
          <div v-for="(item, index) in form.specList" :key="item.id + index">
            <div class="line"></div>
            <el-form-item label="规格型号" :prop="'specList.' + index + '.specName'" :rules="{
              required: true,
              message: '请输入规格型号',
              trigger: 'blur',
            }">
              <el-input v-model="item.specName" placeholder="请输入规格型号" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="数量" :prop="'specList.' + index + '.qty'" :rules="{
              required: true,
              validator: validateQty,
            }">

              <el-input class="editNum" type="text" v-model="item.qty" @keydown.native="keyInput" placeholder="请输入数量"
                onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"></el-input>
              <el-select v-model="item.qtyUnit" placeholder="请选择" style="margin-left: 10px; width: 90px">
                <el-option v-for="item in unitList" :key="item.key" :label="item.value" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- 出租出售置换 -->
            <div v-for="(typeFormItem, indexTypeForm) in typeItem && typeItem.formList" :key="typeFormItem.prop">
              <el-form-item v-if="indexTypeForm > 0" :label="typeFormItem.label"
                :prop="'specList.' + index + '.' + typeFormItem.prop" class="form-item-2">
                <el-switch v-model="item[typeFormItem.prop]" active-color="#E1251B" inactive-color="#BBBBBB"> </el-switch>
                <span class="desc">{{ typeFormItem.desc }}</span>
              </el-form-item>
              <el-form-item :label="typeFormItem.label2" :prop="'specList.' + index + '.' + typeFormItem.minProp"
                class="form-item-1" :rules="{
                  required: true,
                  validator: typeFormItem.validator,
                  trigger: 'blur',
                }" v-if="item[typeFormItem.prop] || indexTypeForm === 0">
                <div class="item-line">
                  <el-form-item :prop="'specList.' + index + '.' + typeFormItem.minProp" :rules="{
                    required: true,
                    validator: PriceV,
                    trigger: 'blur',
                  }">
                    <!-- @input="priceChange($event, item, typeFormItem.discussProp, typeFormItem.minProp)" -->
                    <!--  -->
                    <el-input type="Number" min="1" v-model="item[typeFormItem.minProp]" placeholder="请输入最低价"
                      class="input" @keyup.native="clearNoNum($event, 9, index, 'leaseMinPrice')">

                    </el-input>
                  </el-form-item>
                  <i class="el-icon el-icon-minus"></i>
                  <el-form-item :prop="'specList.' + index + '.' + typeFormItem.maxProp" :rules="{
                    required: true,
                    validator: PriceV,
                    trigger: 'blur',
                  }"><!--onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"-->
                    <!-- @input="priceChange($event, item, typeFormItem.discussProp, typeFormItem.maxProp)" -->
                    <el-input type="Number" min="1" v-model="item[typeFormItem.maxProp]" placeholder="请输入最高价"
                      class="input" @keyup.native="clearNoNum($event, 9, index, 'leaseMaxPrice')"></el-input>
                  </el-form-item>
                  <span class="util">元<template v-if="item.qtyUnit">/</template>{{ item.qtyUnit }}{{ typeFormItem.prop ===
                    'isLease' ? '/天' : '' }}</span>
                  <div class="radio-box"
                    @click="radioChange(item, typeFormItem.discussProp, typeFormItem.minProp, typeFormItem.maxProp)">
                    <i class="radio" :class="{ active: item[typeFormItem.discussProp] === 1 }"></i>
                    电话/面议
                  </div>
                </div>
              </el-form-item>
            </div>
            <el-form-item label="规格型号照片" :prop="'specList.' + index + '.specPictureUrl'">
              <UploadImage ref="uploadImg" @success="handleSpecpictUploadImageSuccess(index, $event)" :imageList="item.specPictureUrl" multiple />
              <p class="desc">支持扩展名：jpg、jpeg、png等格式照片，最多可上传10张</p>
            </el-form-item>
            <div class="item-button-1">
              <div class="button" @click="handleAddSpec" v-if="index === form.specList.length - 1">
                <div class="icon-box">
                  <i class="el-icon-plus"></i>
                </div>
                <span>继续添加</span>
              </div>
              <div class="button delete" @click="handleDeleteSpec(index)" v-if="form.specList.length > 1">
                <div class="icon-box">
                  <i class="el-icon-delete"></i>
                </div>
                <span>删除规格</span>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="item-button-2">
            <div class="button-box">
              <div class="button primary-button disable" @click="$emit('back')">上一步</div>
              <div v-if="!publishEdit" class="button primary-button" @click="handleAddMaterial('formStep2')">继续添加物资</div>
              <div class="button primary-button" @click="submitForm('formStep2')">发布物资</div>
            </div>
          </div>
        </el-form>
      </div>
      <FormTips/>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { apiGetDict } from '@/api/index'
import UploadImage from './UploadImage'
import QuickAllCagetory from './QuickAllCagetory.vue'
import FormTips  from './FormTips.vue'
export default {
  name: 'FormStep2',
  components: { UploadImage, QuickAllCagetory, FormTips },
  props: {
    publishEdit: Boolean,
    defaultData: Object,
    type: Number,
  },
  computed: {
    ...mapState({
      allCategoryList: (state) => state.home.allCategoryList,
    }),
    materialNameList() {
      if (!this.allCategoryList) {
        return
      }
      const allList = this.allCategoryList.find((item) => {
        return item.key === this.form.materialType
      })
      if (!(allList && allList.children)) {
        return
      }
      const list = []
      allList.children.forEach((item) => {
        if (item.sonList) {
          item.sonList.forEach((item2) => {
            list.push({
              value: item2.name,
              key: item2.code,
            })
          })
        }
      })
      return list
    },
    typeItem() {
      // 出租出售置换的表单信息 -当前类型的
      const item = this.typeList.find((item) => {
        return item.key === this.type
      })

      return item
    },
  },
  data() {
    return {
      showQuickCagetopry: false,
      materialList: [],
      activeIndex: 0,
      form: {},
      rules: {
        materialType: [{ required: true, message: '请选择物资类型', trigger: 'change' }],
        materialCategory: [{ required: true, validator: this.validateMaterialCategory, trigger: 'change' }],
        pictureUrl: [{ required: true, message: '请至少上传一张物资图片', trigger: 'blur' }],
        // minPrice: [{ required: true, pattern: '^[1-9]\\d{0,9}(\\.\\d{1,4})?$|^0(\\.\\d{1,4})?$', message: '整数长度最多9位，小数点最多四位', trigger: ['blur', 'change'] }],
        maxPrice: [{ required: true, pattern: '', message: '整数长度最多9位，小数点最多俩位', trigger: ['blur', 'change'] }],
      },
      unitList: [],
      typeList: [
        // 出租出售置换的表单信息列表
        {
          key: 1,
          value: '出租',
          formList: [
            {
              label: '可出租',
              label2: '出租单价',
              prop: 'isLease',
              minProp: 'leaseMinPrice',
              maxProp: 'leaseMaxPrice',
              discussProp: 'leaseDiscussPersonally',
              desc: '开启可出租，会同步发布出租信息',
              validator: this.validateLeasePrice,
            },
            {
              label: '可出售',
              label2: '出售单价',
              prop: 'isSale',
              minProp: 'saleMinPrice',
              maxProp: 'saleMaxPrice',
              discussProp: 'saleDiscussPersonally',
              desc: '开启可出售，会同步发布出售信息',
              validator: this.validateSalePrice,
            },
            {
              label: '可置换',
              label2: '置换单价',
              prop: 'isChg',
              minProp: 'chgMinPrice',
              maxProp: 'chgMaxPrice',
              discussProp: 'chgDiscussPersonally',
              desc: '开启可置换，会同步发布置换信息',
              validator: this.validateChgPrice,
            },
          ],
        },
        {
          key: 2,
          value: '出售',
          formList: [
            {
              label: '可出售',
              label2: '出售单价',
              prop: 'isSale',
              minProp: 'saleMinPrice',
              maxProp: 'saleMaxPrice',
              discussProp: 'saleDiscussPersonally',
              desc: '开启可出售，会同步发布出售信息',
              validator: this.validateSalePrice,
            },
            {
              label: '可出租',
              label2: '出租单价',
              prop: 'isLease',
              minProp: 'leaseMinPrice',
              maxProp: 'leaseMaxPrice',
              discussProp: 'leaseDiscussPersonally',
              desc: '开启可出租，会同步发布出租信息',
              validator: this.validateLeasePrice,
            },
            {
              label: '可置换',
              label2: '置换单价',
              prop: 'isChg',
              minProp: 'chgMinPrice',
              maxProp: 'chgMaxPrice',
              discussProp: 'chgDiscussPersonally',
              desc: '开启可置换，会同步发布置换信息',
              validator: this.validateChgPrice,
            },
          ],
        },
        {
          key: 3,
          value: '求租',
          formList: [
            {
              label: '可求租',
              label2: '求租单价',
              prop: 'isLease',
              minProp: 'leaseMinPrice',
              maxProp: 'leaseMaxPrice',
              discussProp: 'leaseDiscussPersonally',
              desc: '开启可求租，会同步发布出租信息',
              validator: this.validateLeasePrice,
            },
            {
              label: '可求购',
              label2: '求购单价',
              prop: 'isSale',
              minProp: 'saleMinPrice',
              maxProp: 'saleMaxPrice',
              discussProp: 'saleDiscussPersonally',
              desc: '开启可求购，会同步发布求购信息',
              validator: this.validateSalePrice,
            },
            {
              label: '可置换',
              label2: '置换单价',
              prop: 'isChg',
              minProp: 'chgMinPrice',
              maxProp: 'chgMaxPrice',
              discussProp: 'chgDiscussPersonally',
              desc: '开启可置换，会同步发布置换信息',
              validator: this.validateChgPrice,
            },
          ],
        },
        {
          key: 4,
          value: '求购',
          formList: [
            {
              label: '可求购',
              label2: '求购单价',
              prop: 'isSale',
              minProp: 'saleMinPrice',
              maxProp: 'saleMaxPrice',
              discussProp: 'saleDiscussPersonally',
              desc: '开启可求购，会同步发布求购信息',
              validator: this.validateSalePrice,
            },
            {
              label: '可求租',
              label2: '求租单价',
              prop: 'isLease',
              minProp: 'leaseMinPrice',
              maxProp: 'leaseMaxPrice',
              discussProp: 'leaseDiscussPersonally',
              desc: '开启可求租，会同步发布求租信息',
              validator: this.validateLeasePrice,
            },
            {
              label: '可置换',
              label2: '置换单价',
              prop: 'isChg',
              minProp: 'chgMinPrice',
              maxProp: 'chgMaxPrice',
              discussProp: 'chgDiscussPersonally',
              desc: '开启可置换，会同步发布出售信息',
              validator: this.validateChgPrice,
            },
          ],
        },
      ],
    }
  },
  watch: {
    defaultData: {
      handler: function (newVal, oldVal) {
        console.log(newVal, '--')
        if (newVal && newVal.materialList && newVal.materialList[0]) {
          const spec = newVal.materialList[0]
          this.form = this.newFormData(spec)
        }
      },
      immediate: true,
    },
    allCategoryList: {
      handler: function (newVal, oldVal) {
        if (newVal && this.defaultData && this.defaultData.materialList && this.defaultData.materialList[0]) {
          const spec = this.defaultData.materialList[0]
          this.form = this.newFormData(spec)
        }
      },
      immediate: true,
    },
    type: {
      handler: function (newVal, oldVal) {
        this.form = this.newFormData()
      },
      immediate: true,
    },
  },
  mounted() {
    apiGetDict({ code: 'corpore_unit ' }).then((res) => {
      const { data } = res
      this.unitList = data
    })
    this.$bus.$on("selectCagetoryItem", value => {
      this.form.materialCategory = value.code
      this.form.materialName = value.name
      this.showQuickCagetopry = false
    })

  },
  methods: {

    // 电联和价格联动
    priceChange(e, item, prop, props) {
      if (e) {
        item[prop] = 0
        item[props] = ('' + e) // 第一步：转成字符串
          .replace(/[^\d^.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || '' // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      }

    },


    clearNoNum(obj, digits, index, key) {
      var temp = obj.target.value;
      if (temp < 0) {
        obj.target.value = "";
        return;
      }
      var posDot = temp.indexOf("."); //返回指定字符在此字符串中第一次出现处的索引
      if (posDot < 0) {
        //不包含小数点
        if (temp.length <= digits) {
          return; //小于五位数直接返回
        } else {
          // obj.value.delete(5, 6);//大于五位数就删掉第六位（只会保留五位）
          if (temp.length > digits) {
            obj.target.value = temp.slice(0, digits)
          }
          return;
        }
      }
      var lastDot = temp.lastIndexOf(".");
      if (posDot != lastDot) {
        obj.target.value = "";
        return;
      }
      //走到这有小数点
      if (posDot > digits) {
        // edt.delete(5, 6);//大于五位数就删掉第六位（只会保留五位）
        if (temp.length > digits) {
          obj.target.value = temp.substring(1, digits + 1);

        }
        return;
      }
      if (temp.length - posDot - 1 > 2) {
        //如果包含小数点
        // edt.delete(posDot + 3, posDot + 4);//删除小数点后的第三位
        obj.target.value = temp.substring(0, posDot + 3);
        this.form.specList[index][key] = obj.target.value
      }
    },


    radioChange(item, baseProp, goalProp1, goalProp2) {
      item[baseProp] = (item[baseProp] + 1) % 2
      item[goalProp1] = ''
      item[goalProp2] = ''
    },
    // 校验价格格式
    PriceV(rule, value, callback) {
      // const reg = /^[1-9]\d{0,8}(\.\d{1,4})?$|^0(\.\d{1,4})?$/
      const reg = /^[1-9]\d{0,}(\.\d{1,3})?$|^0(\.\d{1,3})?$/

      if (reg.test(+value)) {
        callback()
      } else {
        callback(new Error('正确价格为整数位9位，小数2位'))
      }
    },
    // 去除number输入框内e
    keyInput(e) {
      let key = e.key
      if (key === 'e') {
        e.returnValue = false
        return false
      }
      return true
    },
    // 提交表单
    submitForm(formName) {
      if (this.publishEdit) {
        // 编辑
        const refsForm = this.$refs[formName]
        refsForm.validate((valid) => {
          if (valid) {
            const data = this.formatData([this.form])
            console.log(data)
            this.$emit('submit', data)
          }
        })
      } else {
        this.handleAddMaterial(formName, 'submit')
          .then(() => {
            const data = this.formatData(this.materialList)
            this.$emit('submit', data)
          })
          .catch(() => { })
      }
    },
    // 格式化数据
    formatData(materialList) {
      const list = []
      materialList.forEach((material) => {
        const item = {}
        for (const key in material) {
          if (key !== 'id') {
            item[key] = material[key]
          }
        }
        item.pictureUrl = item.pictureUrl
          .map((item) => {
            return item.url
          })
          .join(',')
        item.specList = []

        material.specList.forEach((spec) => {
          const subList = {}
          for (const key in spec) {
            if (key !== 'id') {
              subList[key] = spec[key]
            }
          }
          subList.leaseDurUnit = '天'
          subList.leasePricingUnit = subList.salePricingUnit = subList.cngPricingUnit = '元'
          subList.isSale = subList.isSale ? 1 : 0
          subList.isLease = subList.isLease ? 1 : 0
          subList.isChg = subList.isChg ? 1 : 0
          subList.specPictureUrl = subList.specPictureUrl
            .map((item) => {
              return item.url
            })
            .join(',')
          item.specList.push(subList)
        })

        list.push(item)
      })
      return list
    },
    // 继续添加物资
    handleAddMaterial(formName, type) {
      return new Promise((resolve, reject) => {
        const refsForm = this.$refs[formName]
        // console.log('继续添加物资保存的数据======', JSON.stringify(this.form))

        refsForm.validate((valid) => {
          if (valid) {
            this.materialList[this.activeIndex] = this.form
            if (this.materialList.length >= 10 && type !== 'submit') {
              this.$message({
                type: 'error',
                message: '一次发布最多可添加十条物资数据',
              })
              resolve()
            } else {
              this.form = this.newFormData()
              this.activeIndex = this.materialList.length
              this.$nextTick(() => {
                refsForm.clearValidate()
                this.$refs.uploadImg.clear()
              })
              resolve()
            }
          } else {
            // 如果添加过数据，填写一半继续发布
            if (this.materialList.length && type === 'submit') {
              this.$confirm('继续提交将不会保存未填写完的数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              })
                .then(() => {
                  resolve()
                })
                .catch(() => { });
            } else {
              return
            }
          }
        })
      })
    },
    // 编辑物资
    handleEditMaterial(index) {
      this.activeIndex = index
      this.form = this.materialList[this.activeIndex]
      console.log(this.materialList)
    },
    // 删除物资
    handleDeleteMaterial(index) {
      this.$confirm('确定删除此物资信息吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          if (this.activeIndex === this.materialList) {
            // 正在添加
            this.activeIndex = this.materialList.length - 1
          } else {
            // 正在编辑
            if (this.activeIndex === index) {
              this.activeIndex = this.materialList.length - 1
              this.form = this.newFormData()
            } else {
              if (this.activeIndex > index) {
                this.activeIndex--
              }
            }
          }
          if (this.activeIndex < 0) {
            this.activeIndex = 0
          }
          this.materialList.splice(index, 1)
        })
        .catch(() => { })
    },

    newFormData(data) {
      if (data) {
        let specListArr = [];
        if(data.specList.length > 0){
          data.specList.map((item)=>{
            specListArr.push(this.newSpecData(item))
          })
        }
        const newData = {
          id: new Date().toGMTString() + Math.random(),
          materialType: String(data.materialType), // 物资类型
          materialTypeName: data.materialTypeName, // 物资类型名称
          materialCategory: String(data.materialCategory), // 物资名称Id
          materialName: data.materialName, // 物资名称
          pictureUrl: data.pictureUrl.split(',').map((item) => {
            return { name: item, url: item }
          }), // 图片地址
          // specList: [this.newSpecData(data.specList[0])],
          specList: specListArr,
        }
        if (this.allCategoryList) {
          const cate = this.allCategoryList.find((item) => {
            return item.key === newData.materialType
          })
          if (cate) {
            newData.materialTypeName = cate.value
          }
        }
        return newData
      } else {
        return {
          id: new Date().toGMTString() + Math.random(),
          materialType: '', // 物资类型
          materialTypeName: '', // 物资类型名称
          materialCategory: '', // 物资名称Id
          materialName: '', // 物资名称
          pictureUrl: [], // 图片地址
          specList: [this.newSpecData()],
        }
      }
    },
    newSpecData(data) {
      if (data) {
        return {
          id: new Date().toGMTString() + Math.random(),
          specName: data.specName, // 规格名称
          qty: data.qty, // 数量
          qtyUnit: data.qtyUnit, // 数量单位

          isLease: parseInt(data.isLease) === 1 ? true : false, // 是否可租（0-否，1-是）
          leaseMinPrice: data.leaseMinPrice, // 租赁最小价格
          leaseMaxPrice: data.leaseMaxPrice, // 租赁最大价格
          leasePricingUnit: data.leasePricingUnit, // 租赁计价金额单位（默认‘元’）
          leaseDurUnit: data.leaseDurUnit, // 租赁计价时长单位（默认‘天’）
          leaseDiscussPersonally: data.leaseDiscussPersonally, // 租赁面议（0-否，1-是）

          isSale: parseInt(data.isSale) === 1 ? true : false, // 是否可售（0-否，1-是）
          saleMinPrice: data.saleMinPrice, // 出售最小价格
          saleMaxPrice: data.saleMaxPrice, // 出售最大价格
          salePricingUnit: data.salePricingUnit, // 出售计价单位（默认‘元’）
          saleDiscussPersonally: data.saleDiscussPersonally, // 出售面议（0-否，1-是）

          isChg: parseInt(data.isChg) === 1 ? true : false, // 是否可置换（0-否，1-是）
          chgMinPrice: data.chgMinPrice, // 置换最小价格
          chgMaxPrice: data.chgMaxPrice, // 置换最大价格
          cngPricingUnit: data.cngPricingUnit, // 置换计价单位
          chgDiscussPersonally: data.chgDiscussPersonally, // 置换面议（0-否，1-是）
          specPictureUrl: data.specPictureUrl ?
            data.specPictureUrl.split(',').map((item) => {
               return { name: item, url: item }
            }) : []
        }
      } else {
        return {
          id: new Date().toGMTString() + Math.random(),
          specName: '', // 规格名称
          qty: '', // 数量
          qtyUnit: '', // 数量单位

          isLease: this.type === 1 || this.type === 3 ? true : false, // 是否可租（0-否，1-是）
          leaseMinPrice: '', // 租赁最小价格
          leaseMaxPrice: '', // 租赁最大价格
          leasePricingUnit: '', // 租赁计价金额单位（默认‘元’）
          leaseDurUnit: '', // 租赁计价时长单位（默认‘天’）
          leaseDiscussPersonally: 0, // 租赁面议（0-否，1-是）

          isSale: this.type === 2 || this.type === 4 ? true : false, // 是否可售（0-否，1-是）
          saleMinPrice: '', // 出售最小价格
          saleMaxPrice: '', // 出售最大价格
          salePricingUnit: '', // 出售计价单位（默认‘元’）
          saleDiscussPersonally: 0, // 出售面议（0-否，1-是）

          isChg: false, // 是否可置换（0-否，1-是）
          chgMinPrice: '', // 置换最小价格
          chgMaxPrice: '', // 置换最大价格
          cngPricingUnit: '', // 置换计价单位
          chgDiscussPersonally: 0, // 置换面议（0-否，1-是）
          specPictureUrl: [], // 图片地址
        }
      }
    },
    // 添加规格
    handleAddSpec() {
      this.form.specList.push(this.newSpecData())
    },
    // 删除规格
    handleDeleteSpec(index) {
      this.form.specList.splice(index, 1)
    },
    // 上传图片成功
    handleUploadImageSuccess(list) {
      this.form.pictureUrl = list
    },
    handleSpecpictUploadImageSuccess(index, list){
      this.form.specList[index].specPictureUrl = list;
    },
    // 选择物资类型
    materialTypeChange(value) {
      this.form.materialTypeName = this.allCategoryList.find((item) => {
        return item.key === value
      }).value
      if (this.form.materialTypeName === '其他') {
        this.form.materialName = ''
        this.form.materialCategory = 0
      } else {
        this.form.materialName = ''
        this.form.materialCategory = ''
      }

    },
    // 选择物资名称
    materialNameChange() {
      if (this.form.materialType == '') {
        this.$message({ message: "请选择物资分类", type: "warning" });
        return
      }
      this.showQuickCagetopry = true
      this.$bus.$emit('selectQuickCagetroy', { type: this.form.materialType, allList: this.allCategoryList })

      // this.form.materialName = this.materialNameList.find((item) => {
      //   return item.key === value
      // }).value
    },
    hiddenQuickCagetory() {
      this.showQuickCagetopry = false

    },

    validateMaterialCategory(rule, value, callback) {
      const index = rule.field.split('.')[1]
      const findIndex = this.materialList.findIndex((item) => {
        return item.materialCategory === value
      })
      if (value === '') {
        callback(new Error('请选择物资名称'))
      } else if (findIndex !== -1 && this.activeIndex !== findIndex) {
        callback(new Error('已添加此物资，不可重复添加'))
      } else {
        callback()
      }
    },
    validateQty(rule, value, callback) {
      const index = rule.field.split('.')[1]
      const spec = this.form.specList[index]
      if (value === '') {
        callback(new Error('请输入物资数量'))
      } else if (spec.qtyUnit === '') {
        callback(new Error('请选择计量单位'))
      } else {
        callback()
      }
    },
    validateLeasePrice(rule, value, callback) {
      const index = rule.field.split('.')[1]
      const spec = this.form.specList[index]
      const nValue = Number(value)
      if (!spec.leaseDiscussPersonally && (value === '' || spec.leaseMaxPrice === '')) {
        callback(new Error('请输入价格'))
      } else if (!spec.leaseDiscussPersonally && (nValue >= spec.leaseMaxPrice || nValue < 0 || spec.leaseMaxPrice < 0)) {
        callback(new Error('请输入正确价格'))
      } else {
        callback()
      }
    },
    validateSalePrice(rule, value, callback) {
      const index = rule.field.split('.')[1]
      const spec = this.form.specList[index]
      const nValue = Number(value)
      if (!spec.saleDiscussPersonally && (value === '' || spec.saleMaxPrice === '')) {
        callback(new Error('请输入价格'))
      } else if (!spec.saleDiscussPersonally && (nValue >= spec.saleMaxPrice || nValue < 0 || spec.saleMaxPrice < 0)) {
        callback(new Error('请输入正确价格'))
      } else {
        callback()
      }
    },
    validateChgPrice(rule, value, callback) {
      const index = rule.field.split('.')[1]
      const spec = this.form.specList[index]
      const nValue = Number(value)
      if (!spec.chgDiscussPersonally && (value === '' || spec.chgMaxPrice === '')) {
        callback(new Error('请输入价格'))
      } else if (!spec.chgDiscussPersonally && (nValue >= spec.chgMaxPrice || nValue < 0 || spec.chgMaxPrice < 0)) {
        callback(new Error('请输入正确价格'))
      } else {
        callback()
      }
    },
  },
}
</script>


<style lang="scss" scoped>
@import '@/styles/variables';

.form-box {
  width: 740px;
  border-top: 1px dashed #d6d6d6;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

::v-deep .el-form-item__label {
  font-size: $primaryTextSize;
  color: $primaryTextColor;
}

.small .el-input {
  width: 102px;
}

.el-select {
  width: 212px;
  margin-right: 5px;
}

.el-input {
  width: 212px;
  height: 36px;
}

.el-textarea {
  width: 212px;
}

::v-deep .el-input__inner,
::v-deep .el-textarea__inner {
  font-family: Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Arial, sans-serif;
  border-radius: 4px;
  border: 1px solid #ececec;
}

.item-button-2 {
  margin-top: 40px;

  .button-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 30px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.item-button-1 {
  margin-bottom: 30px;
  padding-left: 220px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .button {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 22px;

    .icon-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      background: $importantColor;
      border-radius: 50%;
      color: #ffffff;
      margin-bottom: 8px;
      font-size: 16px;
    }

    &.delete {
      margin: 0;

      .icon-box {
        background: #ffffff;
        border: 1px solid #ececec;
        color: #bbbbbb;
      }
    }
  }
}

.goods-list {
  width: 740px;

  .item {
    width: 100%;
    height: 40px;
    background: #f5f5f5;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    margin-bottom: 10px;
    color: $importantColor;

    &.active {
      background: $importantColor;
      color: #ffffff;

      .oprate {
        color: #ffffff;
      }
    }

    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .oprate {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #bbbbbb;

      i {
        font-size: 18px;
      }

      .line {
        width: 0;
        height: 26px;
        border-right: 1px solid #d7d7d7;
        margin: 0 15px;
      }
    }
  }
}

.desc {
  font-size: $secondaryTextSize;
  color: $secondaryTextColor;
  margin-top: 15px;
}

.line {
  width: 740px;
  height: 0;
  border-bottom: 1px dashed #d6d6d6;
  margin: 20px 0;
}

::v-deep input[type='number']::-webkit-inner-spin-button,
::v-deep input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-item-1 {
  .input {
    width: 110px;
    ::v-deep .el-input__inner[type='number'] {
      -moz-appearance: textfield
    }
  }


  .el-icon {
    color: #bdbdbd;
    margin: 0 9px;
  }

  .util {
    margin-left: 10px;
  }

  .radio {
    margin-left: 24px;
  }
}

.form-item-2 {
  .desc {
    margin-left: 10px;
  }
}
.radio-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  .radio {
    width: 15px;
    height: 15px;
    border: 1px solid #cacaca;
    border-radius: 50%;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:after {
      content: '';
      transition: width 0.1s, height 0.1s;
      width: 0px;
      height: 0px;
      background: #ffffff;
      border-radius: 50%;
    }

    &.active {
      border: 1px solid $importantColor;
      background: $importantColor;

      &:after {
        width: 5px;
        height: 5px;
      }
    }
  }
}

.item-line {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .el-form-item {
    margin-bottom: 0 !important;
  }
}

.materName ::v-deep .el-input--small .el-input__inner {
  cursor: pointer;
}



</style>
