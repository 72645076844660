<template>
  <div id='container'></div>
</template>

<script>
import { apiGetSysConfig } from '@/api'
import AMapLoader from '@amap/amap-jsapi-loader'
import { getMapAll } from '@/api/storage'
export default {
  name: 'storageMap',
  data() {
    return {
      map: null,
      infoWindow: null,
      markers:[],
      markersPrev:[],
      getJzlMapAllVosPrev:[],//全部的省级仓库信息
      getJzlMapAllVosCity:[],//全部的市级仓库信息
      getJzlStoreVoListAll:[],//全部的仓库信息
      mapListData:[],//仓库列表数据
      clickFlag:false,//是否是点击放大地图
      geocoder:null,
      layer:null,
      imagePath: require('@/assets/images/public/locationTip.png'), // 图片路径存储在数据中
      prevZoomLevel: 4.4,
      zoomLevel:4.4,
    }
  },
  watch: {
    zoomLevel(newZoomLevel) {
      let that = this;
      if(that.clickFlag == false) {
        if (newZoomLevel < 6.6 && this.prevZoomLevel >= 6.6) {
            // 在缩放级别小于6.6时执行方法
            that.removeAllMarkers();
            that.setMarkers(that.getJzlMapAllVosPrev,'prev')
            that.prevZoomLevel = newZoomLevel;
        } else if ((newZoomLevel >= 6.6 && newZoomLevel < 10) && (that.prevZoomLevel < 6.6 || that.prevZoomLevel >= 10)) {
             // 在缩放级别在6.6到10之间时执行方法
             that.removeAllMarkers();
             that.setMarkers(that.getJzlMapAllVosCity,'prev')
             that.prevZoomLevel = newZoomLevel;
        } else if (newZoomLevel >= 10 && (that.prevZoomLevel < 10 || that.prevZoomLevel < newZoomLevel)) {
            // 在缩放级别大于10时执行方法
            that.removeAllMarkers();
            that.setMarkers(that.getJzlStoreVoListAll,'store')
            that.prevZoomLevel = newZoomLevel;
        }
      }
      that.clickFlag = false;
    }
  },
  mounted() {
    apiGetSysConfig({ code: 'jzl_map_config' }).then((res) => {
      const { key, secret } = res.data
      this.initAMap(key, secret)
    })
  },
  methods:{
    initAMap(key, secret) {
      window._AMapSecurityConfig = {
        securityJsCode: secret,
      }
      let that = this;
      AMapLoader.load({
        key: key, // 首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.Geocoder',
          'AMap.LabelsLayer',
        ] // 需要使用的的插件列表
      })
        .then((AMap) => {
          that.map = new AMap.Map('container', {
            zoom: 4.4,
            zooms: [4.4, 15],
            center: [101.77782, 36.61729]
          });
          that.geocoder = new AMap.Geocoder({
            city: '全国',
            radius: 1000,
            extensions:'all'
          });
          that.layer = new AMap.LabelsLayer({
            zooms: [4.4, 15],
            zIndex: 1000,
            collision: false
          });
          that.geocoder.getAddress('全国', (status, result) => {
            if (status === 'complete' && result.info === 'OK') {
              console.log(result)
            }
          });
          // 将图层添加到地图
          that.map.add(that.layer);
          // 监听地图缩放级别变化
          that.map.on('zoomend', this.handleZoomChange);
          getMapAll({
          }).then((res) => {
            if(res.data){
              that.getMapAllInfo(res.data);
            }
          })
        })
        .catch((e) => {
        })
    },
    handleZoomChange() {
      this.zoomLevel = this.map.getZoom();
    },
    // 获取仓库数据
    async getLocationStroe(item,type) {
      return new Promise((resolve, reject) => {
        if(type == 'prev' || type == 'city'){
          resolve({
            ...item,
            position: [item.longitude, item.latitude]
          });
        }else{
          resolve({
            ...item,
            position: [item.lon, item.lat]
          });
        }
      });
    },
    async getMapAllInfo(mapAllData) {
      try {
        // 省数据
        if(mapAllData.provList.length > 0){
          this.getJzlMapAllVosPrev = await Promise.all(mapAllData.provList.map(item => this.getLocationStroe(item,'prev')));
          this.setMarkers(this.getJzlMapAllVosPrev,'prev')
        }
        // 市数据
        if(mapAllData.cityList.length > 0){
          this.getJzlMapAllVosCity = await Promise.all(mapAllData.cityList.map(item => this.getLocationStroe(item,'city')));
        }
        // 获取全部仓库
        if(mapAllData.srortVoList.length > 0){
          this.getJzlStoreVoListAll = await Promise.all(mapAllData.srortVoList.map(item => this.getLocationStroe(item,'store')));
        }
      } catch (error) {
        console.error(error);
      }
    },
    setMarkers(listAll,type){
      let that = this;
      var icon = {
        type: 'image',
        image: that.imagePath,
        size: [32, 45],
        anchor: 'bottom-center',
      };
      listAll.forEach((item,index)=>{
        var curPosition = item.position;
        var curData = {};
        if(type == 'prev'){
          let num = item.num;
          const text = {
            content: num.toString(), //要展示的文字内容
            direction: "center", //文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
            offset: [1, -5], //在 direction 基础上的偏移量
            //文字样式
            style: {
              fontSize: 14, //字体大小
              fillColor: "#fff", //字体颜色
            },
          };
          curData = {
            position: curPosition,
            icon,
            text: text,
          };
        }else if(type == 'store'){
          curData = {
            position: curPosition,
            icon,
          };
        }
        var labelMarker = new window.AMap.LabelMarker(curData);
        if(type == 'prev'){
          that.markersPrev.push(labelMarker);
          // 给marker绑定事件
          labelMarker.on('mouseover', function(e){
            var position = e.data.data && e.data.data.position;
            if(position){
              normalMarker.setContent(
                '<div style="width:300px;display: flex; justify-content: space-between;align-items: center;padding: 5px 12px;line-height:16px;background: #333333;border-radius: 4px;color: #FFFFFF;">'+
                '<span style="margin-right: 5px;">'+item.dataName+'</span>'+
                '<span style="margin-right: 5px;">仓库:<strong style="margin-left: 5px;">'+item.num+'</strong>个</span>'+
                '<span>面积:<strong style="margin-left: 5px;">'+item.area+'</strong>m²</span>'+
                ' </div>');
              normalMarker.setPosition(position);
              that.map.add(normalMarker);
            }
          });

          labelMarker.on('mouseout', function(){
            that.map.remove(normalMarker);
          });
          labelMarker.on('click', function(e) {
            that.map.setCenter(curPosition);
            that.$parent.getMapListData(item,null,null,1,true);
            that.$parent.$refs.warehouseListRef.scrollTop = 0;
            if (item.parentId == 0) {
              that.setZoomFun(6.6);
            } else{
              that.setZoomFun(11);
            }
          });
        }else if(type == 'store'){
          that.markersPrev.push(labelMarker);
          // 给marker绑定事件
          labelMarker.on('mouseover', function(e){
            var position = e.data.data && e.data.data.position;
            if(position){
              normalMarker.setContent(
                '<div style="width: 300px;display: flex; flex-direction: column;padding: 8px 12px;background: #333333;border-radius: 4px;line-height:16px;color: #FFFFFF;">'+
                '<span style="margin-bottom: 5px;">仓库类型:<strong style="margin-left: 5px;">'+item.storeTypeName+'</strong></span>'+
                '<span style="margin-bottom: 5px;">面积:<strong style="margin-left: 5px;">'+item.areaVal+'</strong>m²</span>'+
                '<span>详细地址:<strong style="margin-left: 5px;">'+item.address+'</strong></span>'+
                ' </div>');
              normalMarker.setPosition(position);
              that.map.add(normalMarker);
            }
          });

          labelMarker.on('mouseout', function(){
            that.map.remove(normalMarker);
          });
          labelMarker.on('click', function(e) {
            let uniqueId = item.id;
            let selectindex = that.mapListData.findIndex(ite => ite.id == uniqueId);
            if(selectindex != '-1'){
              that.handleWarehouse(e,selectindex)
            }else{
              let selectedObj = {
                parentId:item.provId,
                dataId:item.cityId
              }
              that.$parent.$refs.warehouseListRef.scrollTop = 0;
              that.$parent.getMapListData(selectedObj,null,uniqueId,1,true);
            }
          });
        }

      })
      // 一次性将海量点添加到图层
      that.layer.add(that.markersPrev);
      // 普通点
      var normalMarker = new window.AMap.Marker({
        anchor: 'bottom-center',
        offset: new window.AMap.Pixel(-15, -50),
      });

    },
    // 移除所有标记
    removeAllMarkers() {
      this.map.clearMap();
      this.layer.clear(); // 清除图层上的所有标记
      this.markersPrev = []; // 清空标记数组
    },
    //设置地图等级
    setZoomFun(num){
      this.map.setZoom(num,false,800);
    },
    //设置窗体
    frameFun(selectedItem){
      this.setZoomFun(15);
      const markersContent = []
      markersContent.push(`<div style="background: url(${require('@/assets/images/public/locationTip.png')});width: 32px;height: 45px;background-size: 100%">`)
      markersContent.push(`<span style='font-size: 14px;font-weight: bold;color: #FFFFFF;text-align: center;display: inline-block;margin-top: 7px;width: 100%;'></span>`)
      markersContent.push(`</div>`)
      // 创建 Marker
      let marker = new window.AMap.Marker({
        offset: new window.AMap.Pixel(-15, -50),
        position: [selectedItem.lon,selectedItem.lat], // 标注点位置
        content: markersContent.join(''),
        map: this.map // 将标注点添加到地图上
      });
      const info = [];
      info.push(
        `<div style="display: flex; flex-direction: column;padding: 8px 12px;line-height:16px;color: #FFFFFF;">
          <span style='margin-bottom: 5px;'>仓库类型:<strong style='margin-left: 5px;'>${selectedItem.storeTypeName}</strong></span>
          <span style='margin-bottom: 5px;'>面积:<strong style='margin-left: 5px;'>${selectedItem.areaVal}</strong>m²</span>
          <span>详细地址:<strong style='margin-left: 5px;'>${selectedItem.address}</strong></span>
        </div>`
      );
      let infoWindow = new window.AMap.InfoWindow({
        content: info.join(''),// 你需要展示的内容
        offset: new window.AMap.Pixel(0, -30) // 信息窗体相对于标注位置的偏移量
      });
      infoWindow.open(this.map, [selectedItem.lon, selectedItem.lat]); // 将信息窗体添加到地图上并指定位置
      this.map.setCenter([selectedItem.lon, selectedItem.lat]);
      this.markers.push(marker);
    }

  }
}
</script>

<style scoped>

</style>