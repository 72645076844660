<template>
  <div class="component-container">
    <div class="component-content">
      <img src="@/assets/images/public/logo1.png" @click="handleClickHome" class="img-logo" />
      <div class='component-text'>个人中心</div>
      <div class="component-right">
        <div class="item" v-for="(item, index) in title" :class="{ active: itemSelect === index }" :key="item" @click="handleNavClick(index)">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'PersonalInfoTop',
  components: {},
  computed: {},
  data() {
    return {
      title: ['首页', '账号管理', '消息中心'],
      itemSelect: this.iSelect,
    }
  },
  props:['iSelect'],
  methods: {
    handleClickHome() {
      this.$router.push('/')
    },
    handleNavClick(index) {
      this.itemSelect = index
      switch(this.title[index]) {
        case "首页":
          this.$router.push('/personal/home', ()=>{}, ()=>{})
          break;
        case "账号管理":
          this.$router.push('/accountManage', ()=>{}, ()=>{})
          break;
        case "消息中心":
          this.$router.push('/personal/message', ()=>{}, ()=>{})
          break;
        default:
          this.$router.push('/personal/home', ()=>{}, ()=>{})
            break;
      }
    }
  },
}
</script>
  
<style lang="scss" scoped>
@import '@/styles/variables';

.component-container {
  width: 100%;;
  background: white;
}
.component-content {
  width: $contentWidth;
  height: 101px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  .component-text{
    margin: 0 40px 0 21px;
    font-size: 18px;
    color: #333333;
    line-height: 24px;
  }
}

.img-logo {
  cursor: pointer;
  width: 143px;
  &:hover {
    opacity: 0.9;
  }
}
.component-right {
  display: flex;
  font-size: 14px;
  color: #333333;
  .item {
    margin-left: 40px;
    cursor: pointer;
    &:hover {
      color: $importantColor;
    }
    &.active {
      color: $importantColor;
    }
  }
}
</style>
  