<template>
    <div class="step3-box">
        <img src="@/assets/images/realname/password_success.png" class="icon-menu" style="margin-top: 50px; width: 142px;" />
        <div class="title">密码修改成功，请重新登录<p style="color:#E1251B;">{{countdown}}s</p></div>
        <el-button class="el-item-submit" @click="goLogin">重新登录</el-button>
    </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import {removeToken } from '@/utils/cookie'

  export default {
    name: 'changePassword',
    components: { },
    computed: {
   
    },
    created() {
      this.countdownClick()
    },
    data() {
      return {
        interval:'',
        countdown:0
      }
    },
    methods: {
      ...mapActions('user', ['logout']),
      goLogin(){
        this.countdown = 0
        // //清除userUuid
        // removeToken();
        // //去登录页
        // this.$router.push('/login');

        this.logout()
      },
      countdownClick() {
        this.countdown = 10
        const interval = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--
            } else {
              clearInterval(interval)
              removeToken();
              this.$router.push('/login');
              this.logout()
            }
          }, 1000)
      }

    },
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/variables';
  
  .step3-box {
    width: 100%;
    height: 721px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;

 .title {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    letter-spacing: 2px;
    height: 19px;
    margin-top: 10px;
    display: flex;
 }
  .el-item-submit {
    background: #E1251B;
    width: 168px;
    height: 42px;
    margin-top: 23px;
    color: #FFFFFF;
    border-radius: 4px;
  
  }

  }
  
  </style>
    