<template>
  <div class="component">
    <div class="leftBox">
      <div :class=" selectIndex==index?'active left-item':'left-item'" v-for="(item, index) in leftList" :key="index" @mouseenter="handleCategoryMouseEnter(index)">
        {{ item.name}}
      </div>
    </div>
    <div class="rightBox">
      <div class="right-item" @mousedown="selectItem(item)" v-for="(item, index) in secondList" :key="index">{{ item.name }}
      </div>
    </div>

  </div>
</template>
  
<script>

export default {
  name: 'AllCategory',
  components: {},
  computed: {
 
  },

  data() {
    return {
      showAllCategory: false,
      popupList: [],
      leftList: [],
      secondList: [],
      selectIndex:0,
    }
  },
  watch: {
  
  },
  mounted() {

      this.$bus.$on("selectQuickCagetroy", value => {
        var allCategoryList = value.allList
        for (let index = 0; index < allCategoryList.length; index++) {
          const element = allCategoryList[index];
          if (element.key == value.type) {
            this.leftList = element.children
            const sonList = this.leftList[0]
            this.secondList = sonList['sonList']
            return
          }
        }
      })

  },

  methods: {
    handleCategoryMouseEnter(index) {
      const sonList = this.leftList[index]
      this.secondList = sonList['sonList']
      this.selectIndex = index
    },
    selectItem(item) {
         console.log('选中的下标========',item.code,item.name)
         this.$bus.$emit('selectCagetoryItem', {code:item.code,name:item.name})
    }
  },
}
</script>
  
<style lang="scss" scoped>
@import '@/styles/variables';

.component {
  background: #ffffff;
  position: absolute;
  z-index: 100;
  display: flex;
  height: 316px;
  box-shadow: 1px 1px 5px 1px #ECECEC;

}

.leftBox {
  height: 100%;
  overflow-y: auto;
}
.left-item {
  max-width: 130px;
  line-height: 16px;
  margin-top:15px;
  margin-left: 10px;
  color: #999999;
  font-size: 12px;
  display: flex;
  word-wrap:break-word;word-break:break-all;
  cursor:pointer;
  
}
.active{
  color:#333333;
  font-weight: 900;
}
.rightBox {
  width: 272px;
  overflow-y: auto;

  .right-item {
    font-size: 12px;
    height: 16px;
    line-height: 16px;
    display: inline-block;
    margin-top:15px;
    margin-left:10px ;
    color: #BBBBBB;
    cursor:pointer;

    &:hover {
      color: red;
    }
  }
  


}
</style>
  