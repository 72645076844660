import axios from 'axios'
import { Message } from 'element-ui'
import { getToken } from '@/utils/cookie'
import md5 from 'js-md5'
import { Base64 } from 'js-base64'
import { Encrypt } from '@/utils/aes'
import { rsaEncrypt } from '@/utils/rsa'
import qs from 'qs'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Accept-Language'] = 'zh-CN'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  timeout: 10000, // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (config.method === 'post') {
      // console.log(config.data)
      //是post提交
      if (config.headers['Content-Type'] == 'multipart/form-data;charset=utf-8') {
        //图片或文件格式，不做处理
      } else {
        //json数据，加验签
        var oldData = {}
        if (config.data) {
          oldData = qs.parse(config.data)
        }
        if (getToken()) {
          //如果有userUuid,那么加到业务参数中
          oldData['userUuid'] = getToken()
        }
        // console.log(oldData)
        //获取当前时间戳
        var timestamp = new Date().getTime()
        //生成AES Key
        var aesKey = Base64.encode('jupai' + timestamp)
        //业务参数aes加密
        var reqContent = encodeURIComponent(Encrypt(JSON.stringify(oldData), aesKey))
        //md5签名
        var md5Sign = md5(reqContent).toUpperCase()
        //UrlDecode 解密公钥
        var rsaSign = encodeURIComponent(rsaEncrypt(aesKey))
        //参数
        var params = {
          version: process.env.VUE_APP_VERSION,
          osType: process.env.VUE_APP_OSTYPE,
          reqContent: reqContent,
          md5Sign: md5Sign,
          rsaSign: rsaSign,
          timeStamp: timestamp,
          gps: 'gps',
        }
        config.data = params
      }
    }
    // console.log(config)
    return config
  },
  (error) => {
    // Do something with request error
    // console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  (response) => {
    // loadingInstance.close()
    // console.log(response)
    if (response.status == 200) {
      return response.data
    } else {
      // Message({
      //         message: response.data.msg,
      //         type: 'error',
      //         duration: 5 * 1000
      //       })
      return Promise.reject('error')
    }
  },
  (error) => {
    // console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
