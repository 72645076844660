<template>
  <div class="component-container recommend">
    <div class="component-content recommend">
      <img src="@/assets/images/home/title-1.png" class="img-title" />
      <div class="category">
        <el-tabs v-model="active" :stretch="true" class='custom-tabs'>
          <el-tab-pane v-for="item in allCategoryList" :key="item.key" :label="item.value" :name="item.key" v-show="item.value!='其他'"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="goods" v-if="list && active && list[active] && list[active].length > 0">
        <GoodsItem v-for="item in list[active]" :key="item.infoNo" @set-vip-show = "setVipShow" class="item" :data="item" />
      </div>
      <el-empty v-else :image-size="200"></el-empty>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import GoodsItem from './GoodsItem'

export default {
  name: 'HomeRecommend',
  components: { GoodsItem },
  props: {
    list: Object,
  },
  data() {
    return {
      active: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      allCategoryList: (state) => {
        if(state.home && state.home.allCategoryList){
          let allCategoryList=[];
          for(let i=0; i<state.home.allCategoryList.length; i++) {
            if(state.home.allCategoryList[i]["value"] != "其他"){
              allCategoryList.push(state.home.allCategoryList[i]);
            }
          }
          return allCategoryList;
        }
      },
    }),
  },
  watch: {
    allCategoryList: {
      handler(newVal, oldVal) {
        if (!newVal || (newVal && newVal.length === 0)) {
          return
        }
        this.active = newVal[0].key
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    setVipShow(data){
      console.log('点击了2')
      this.$emit('set-vip-show-top',data);
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.component-container.recommend {
  margin-top: 20px;
  .component-content.recommend {
    width: $contentWidth;
  }
}
.img-title {
  width: 163px;
}
.category {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  background: #ffffff;
  padding: 0 20px;
}

.goods {
  margin-top: 10px;
  width: 100%;
  height: 384px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .item {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__nav-wrap::after {
  content: none;
}
::v-deep .el-tabs__item {
  padding: 0;
  height: 50px;
  font-size: $primaryTextSize;
  color: $primaryTextColor;
  line-height: 50px;
}

::v-deep .el-tabs__item:hover {
  color: $importantColor;
}
::v-deep .el-tabs__item.is-active {
  color: $importantColor;
  font-weight: bold;
}
.custom-tabs ::v-deep .el-tabs__active-bar {
  width: 21px!important;
  margin-left: calc((100% / 7 - 21px) / 2);
  background-color: $importantColor;
  height: 4px;
  border-radius: 4px;
}

</style>
