<template>
  <div class="item-module-goods">
    <div class="item-content-list">
      <div v-for="(item, index) in selTitleData" :key="index + 'w'" class="item-content-list-text" :class="{ active: selTitleIndex === index }" @click="handleNavClick(item.flowStatus, index)">
        {{ item.title }}
      </div>
    </div>
    <el-divider></el-divider>
    <div v-show="demandInfoList.length > 0">
      <div v-for='(item,index) in demandInfoList' :key='index'>
        <ReleaseItem :demandInfoItem='item'/>
      </div>
      <div class="pagination-bottom">
        <el-pagination @size-change="currentPage" @current-change="currentPage" :current-page.sync="pageNo" :page-size="pageSize" layout="total, prev, pager, next" :total="total"> </el-pagination>
      </div>
    </div>
    <div v-show="demandInfoList.length == 0">
      <EmptyEntrust/>
    </div>
  </div>
</template>

<script>
import { getMyDemandList } from '@/api/personal'
import ReleaseItem from '@/views/personalcenter/components/ReleaseItem.vue'
import EmptyEntrust from '@/views/personalcenter/components/EmptyEntrust.vue'
export default {
  name: 'EntrustCom',
  components:{
    ReleaseItem,
    EmptyEntrust
  },
  data(){
    return{
      selTitleData: [
        { title: ' 全部 ', flowStatus: null },
        { title: ' 待受理 ', flowStatus: 0 },
        { title: ' 待发布 ', flowStatus: 1 },
        { title: ' 已发布 ', flowStatus: 2 },
        { title: ' 无效 ', flowStatus: 3 },
      ],
      selTitleIndex: 0,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      flowStatus: null,
      acceptStatus:null,
      demandInfoList:[]
    }
  },
  mounted() {
    this.getDemandListInfo();
  },
  methods:{
    currentPage(page) {
      this.pageNo = page
      this.getDemandListInfo()
    },
    handleNavClick(flowStatus, index) {
      this.selTitleIndex = index;
      // 待受理
      if(flowStatus == 0){
        this.flowStatus = null;
        this.acceptStatus = '-1';
      }else if(flowStatus == 1){
        // 待发布
        this.flowStatus = 1;
        this.acceptStatus = null;
      }else if(flowStatus == 2){
        // 已发布
        this.flowStatus = 2;
        this.acceptStatus = null;
      }else if(flowStatus == 3){
        // 无效
        this.flowStatus = null;
        this.acceptStatus = 3;
      }else{
        this.flowStatus = null;
        this.acceptStatus = null;
      }
      this.pageNo = 1;
      this.getDemandListInfo();
    },
    getDemandListInfo(){
      var params = {
        page: this.pageNo,
        limit: this.pageSize,
        flowStatus: this.flowStatus,
        acceptStatus: this.acceptStatus,
      }
      getMyDemandList(params).then((res) => {
        if (res.success) {
          var newList = res.data
          newList.forEach((item,index) => {
            let itemArr = item.annexUrl.split(',');
            let annexUrlList = [];
            item.annexUrlList = annexUrlList;
            let itemNameArr = item.annexName.split(',');
            itemArr.map((items,indexs) => {
              annexUrlList.push({
                name:itemNameArr[indexs] ? itemNameArr[indexs] : items.substring(items.lastIndexOf("/")+1),
                file:items
              })
            })
          })
          this.demandInfoList = newList
          this.total = res.count
        } else {
          this.$message({ message: res.msg, type: 'warning' })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.item-content-list {
  height: 59px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-left: 57px;
  .item-content-list-text {
    color: #333333;
    margin-right: 73px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: $importantColor;
    }
    &.active {
      color: $importantColor;
    }
  }
}
.item-module-goods {
  display: flex;
  flex-direction: column;
  justify-content: start;
  .pagination-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
}
.item-module-goods ::v-deep .el-divider--horizontal {
  margin: 0px 20px 0px 20px;
  background-color: #eaeaea;
  width: auto;
}
</style>