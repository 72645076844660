import request from '@/utils/request'

// 获取用户认证信息
export function getUserAuth(data) {
    return request({
      url: '/appApi/jzl/user/getUserAuth',
      method: 'post',
      data: data
    })
}

