<template>
  <div>
    <div class='images'>
      <img src='@/assets/images/public/entrustEmpty.png' />
      <div class='noData'>暂无数据!</div>
      <div class="button primary-button" @click="handleFastPublish">快速发布</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'EmptyEntrust',
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods:{
    ...mapActions('user', ['updateUserInfo']),
    // 快速发布
    handleFastPublish() {
      this.updateUserInfo().then((res)=>{
        const { userUuid, authenticationStatus } = this.userInfo
        // 1：出租，2：出售，3：求租，4：求购
        if (userUuid) {
          // 1 是 0 否 只有认证才能帮发布
          if(!authenticationStatus){
            return this.$confirm('只有认证才能快速发布，是否去认证？', '提示', {
              confirmButtonText: '去认证',
              cancelButtonText: '取消',
            }).then(() => {
              this.$router.push({name:'accountManage', params: { changeSelect: 2}})
            }).catch(() => {});
          }
          this.$router.push('/quickPublish')
        } else {
          this.$router.push('/login')
        }
      })
    },
  }
}
</script>

<style scoped lang='scss'>
  .images{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
    img{
      width: 239px;
      height: 194px;
    }
    .noData{
      margin: 6px 0 30px;
      font-size: 14px;
      color: #BBBBBB;
      line-height: 19px;
    }
    .button{
      width: 110px;
      height: 40px;
      background: #E1251B;
      border-radius: 4px;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
</style>