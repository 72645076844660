<template>
  <div v-loading="loading">
    <input type="text" class="address" id="inputAddress" @input="handleInput" />
    <div id="container"></div>
    <div class="button-box">
      <div class="button primary-button disable" @click="$emit('cancel')">取消</div>
      <div class="button primary-button" :class="{ disable: !canSubmit }" @click="handleConfirmAddress">确认</div>
    </div>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { apiGetSysConfig, apiGetDict, apiListCategoryWithSonList } from '@/api/index'

export default {
  name: 'MapContainer',
  props: {
    lng: Number,
    lat: Number,
  },
  data() {
    return {
      loading: true,
      address: '',
      map: null,
      mouseTool: null,
      overlays: [],
      auto: null,
      placeSearch: null,
      geocoder: null,
      canSubmit: false,
    }
  },
  mounted() {
    apiGetSysConfig({ code: 'jzl_map_config' }).then((res) => {
      const { key, secret } = res.data
      this.initAMap(key, secret)
    })
  },
  unmounted() {
    this.map?.destroy()
  },
  methods: {
    initAMap(key, secret) {
      window._AMapSecurityConfig = {
        securityJsCode: secret,
      }
      let _this = this
      let position = [116.397428, 39.90923]
      if (this.lat && this.lng) {
        position = [this.lng, this.lat]
      }
      AMapLoader.load({
        key: key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Geolocation', 'AMap.Scale', 'AMap.ToolBar', 'AMap.ControlBar', 'AMap.MouseTool', 'AMap.MapType', 'AMap.HawkEye', 'AMap.AutoComplete', 'AMap.PlaceSearch', 'AMap.Geocoder'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          _this.loading = false
          _this.map = new AMap.Map('container', {
            // 设置地图容器id
            viewMode: '2D', // 是否为3D地图模式
            zoom: 11, // 初始化地图级别
            center: position, // 初始化地图中心点位置
          })

          //   添加地图控件
          _this.marker = new AMap.Marker()
          _this.map.add(_this.marker)
          _this.marker.setPosition(position)
          _this.map.addControl(new AMap.Scale()) // 添加左下角的比例尺
          let toolBar = new AMap.ToolBar({
            position: {
              bottom: '210px',
              right: '35px',
            },
          })
          _this.map.addControl(toolBar) // 添加右下角的放大缩小

          //   添加地图搜索
          _this.auto = new AMap.AutoComplete({
            input: 'inputAddress', // 搜索框的id
          })
          _this.auto.on('select', _this.select) //注册监听，当选中某条记录时会触发

          // 添加鼠标点击获取经纬度
          _this.geocoder = new AMap.Geocoder({
            city: '010', //城市设为北京，默认：“全国”
            radius: 1000, //范围，默认：500
          })
          _this.positionToAddress(position, true)

          _this.map.on('click', function (e) {
            position = [e.lnglat.getLng(), e.lnglat.getLat()]
            _this.clickPos(position, true)
          })
        })
        .catch((e) => {
          console.log(e)
        })
    },
    positionToAddress(position, show) {
      this.geocoder.getAddress(position, (status, result) => {
        if (status === 'complete' && result.info === 'OK') {
          result.lng = position[0]
          result.lat = position[1]
          this.result = result
          this.address = result.regeocode.formattedAddress
          this.canSubmit = true
          if (show) {
            document.getElementById('inputAddress').value = this.address
          }
        }
      })
    },
    // 选择提示的地址
    select(e) {
      const pos = e.poi.location
      this.clickPos([pos.lng, pos.lat], false)
    },
    // 点击位置
    clickPos(position, show) {
      this.marker.setPosition(position)
      this.map.setCenter(position)
      this.map.setZoom(13)
      this.positionToAddress(position, show)
    },
    handleInput() {
      this.canSubmit = false
    },
    // 确认地址
    handleConfirmAddress() {
      if (!this.canSubmit) {
        return
      }
      this.$emit('confirm', this.result)
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

#container {
  width: 100%;
  height: 400px;
}

.address {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  border: 1px solid #ececec;
  border-radius: 4px;
  line-height: 30px;
  padding: 0 12px;
}

.button-box {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .button {
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
<style lang="scss">
.amap-sug-result {
  z-index: 10000;
}
</style>
