<template>
  <div class="component-container">
    <div class="component-content">
      <img v-show="showImg == '1'" src="@/assets/images/home/vip.png" @click="handleClickVIP" class="img-vip" />
      <el-dialog title="" :visible.sync="showDialog" :show-close="false" width="520px" :close-on-click-modal="false">
        <div class="trans-box">
          <div class="flip-item dialog-content-back" :class="{ 'flip-back': playFlip }">
            <img src="@/assets/images/home/dialog-close.png" class="img-close" @click="handleClose" />
            <img src="@/assets/images/home/icon_member_tipimg.png" class="img-1" />
            <p class="text-1">开通VIP查看更多租售商机</p>
            <div class="dialog_content-xiaoju">
              <img src="@/assets/images/home/icon_member_tip_left.png" class="dialog_content-xiaoju-img" />
              <p class="text-2">小聚免费赠送你</p>
              <img src="@/assets/images/home/icon_member_tip_right.png" class="dialog_content-xiaoju-img" />
            </div>

            <img src="@/assets/images/home/icon_member_oneyear.png" style="margin-top: 5px" />

            <div class="button" @click="handleFlipVIP">立即领取</div>
          </div>

          <div class="flip-item dialog-content" :class="playFlip ? 'flip-front': 'flip-front-normal'">
            <img src="@/assets/images/home/dialog-close.png" class="img-close" @click="handleClose" />
            <img src="@/assets/images/home/dialog-1.png" class="img-1" />
            <p class="text-1">VIP</p>
            <p class="text-2">聚循环VIP会员限时免费领价值</p>
            <p class="text-3">￥ <span class="big">2888</span></p>
            <div class="list">
              <div class="item">
                <img src="@/assets/images/home/dialog-icon-1.png" class="img-icon-1" width="59" />
                <p class="text">无限商机免费查看</p>
              </div>
              <div class="item">
                <img src="@/assets/images/home/dialog-icon-2.png" class="img-icon-2" width="59" />
                <p class="text">物资信息免费咨询</p>
              </div>
              <div class="item">
                <img src="@/assets/images/home/dialog-icon-3.png" class="img-icon-3" width="59" />
                <p class="text">租赁信息免费发布</p>
              </div>
            </div>
            <div class="button" @click="handleGetVIP">领取会员</div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { gainVip } from '@/api/user'

export default {
  name: 'HomeVIP',
  components: {},
  props: {
    isShowFlipDialog: {
      type: [Boolean],
      default: false,
    },
    isShowDialog: {
      type: [Boolean],
      default: false,
    },
    showImg: {
      type: [Number],
      default: 1,
    },
  },
  data() {
    return {
      playFlip: false,
      showDialog: this.isShowDialog,
      showOverflow: false,
    }
  },

  mounted() {
    // console.log("还" + this.isShowFlipDialog );
    // if(this.isShowFlipDialog) {
    //   this.showOverflow = false
    // }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  watch: {
    isShowDialog: {
      handler(newVal, oldVal) {
        if (newVal) {
          console.log(newVal)
          this.showDialog = true
        } else {
          console.log(newVal + '1111')
          this.showDialog = false
        }
      },
    },
  },
  // watch: {
  //   userInfo: {
  //     handler: function (newval, oldVal) {
  //       if (newval && newval.userUuid && parseInt(newval.vipFlag) === 0) {
  //         this.showDialog = true
  //       }
  //     },
  //     immediate: true,
  //     deep: true,
  //   },
  // },
  methods: {
    ...mapMutations('user', ['updateUserVip']),

    handleFlipVIP() {
      this.playFlip = !this.playFlip
      this.showOverflow = true
    },

    // 点击VIP
    handleClickVIP() {
      // this.showDialog = true
      this.$emit('set-show-dialog', true)
    },
    // 领取会员
    handleGetVIP() {
      if (!this.userInfo.userUuid) {
        this.$confirm('登录后领取VIP', '提示', {
          confirmButtonText: '去登录',
          cancelButtonText: '取消',
        })
          .then(() => {
            this.$router.push('/login')
          })
          .catch(() => {})
        return
      }
      gainVip({ userUuid: this.userInfo.userUuid }).then((res) => {
        const { code, msg, data } = res
        if (code === '000000') {
          this.updateUserVip()
          this.$message({
            message: data,
            type: 'success',
            duration: 2000,
            onClose: () => {
              // this.showDialog = false
              this.$emit('set-show-dialog', false)
            },
          })
        } else {
          this.$message({
            message: msg,
            type: 'error',
            duration: 2000,
          })
        }
      })
    },
    handleClose() {
      this.playFlip = false
      this.showOverflow = false
      // this.showDialog = false;
      this.$emit('set-show-dialog', false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.flip-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 1s ease-in-out 0s;
  backface-visibility: hidden;
  box-sizing: border-box;
}

.img-vip {
  width: 1200px;
  margin-top: 10px;
  cursor: pointer;
  //&:hover {
  //  opacity: 0.9;
  //}
}

.flip-front {
  transform: rotateY(0deg);
  // transition-duration: 2s;
}

.flip-front-normal {
  transform: rotateY(180deg);
}

.flip-back {
  transform: rotateY(180deg);
  transition-duration: 1s;
}

.trans-box {
  width: 520px;
  height: 520px;
  position: relative;
  // overflow: hidden;
}

.dialog-content {
  z-index: 50;
  width: 520px;
  height: 520px;
  background: url('@/assets/images/home/dialog-bg.png') no-repeat center 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .img-close {
    position: absolute;
    width: 22px;
    top: 22px;
    right: 24px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .img-1 {
    width: 136px;
    height: 170px;
    margin-top: -70px;
  }

  .text-1 {
    // margin-top: -40px;
    font-size: 55px;
    font-family: Arial-Black, Arial;
    font-weight: 900;
    color: #ffdbbf;
    line-height: 78px;
  }

  .text-2 {
    margin-top: -8px;
    font-size: 28px;
    color: #ffdbbf;
    line-height: 37px;
    letter-spacing: 3px;
  }

  .text-3 {
    margin-top: 10px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #ffdbbf;
    line-height: 33px;

    .big {
      font-size: 43px;
      font-family: Arial-Black, Arial;
      font-weight: 900;
    }
  }

  .list {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      .img-icon {
        width: 59px;
      }

      .text {
        margin-top: 8px;
        font-size: 14px;
        color: #ffdbbf;
        line-height: 19px;
      }
    }
  }

  .button {
    margin-top: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 50px;
    background: #ffdbbf;
    border-radius: 34px;
    font-size: 14px;
    color: #000000;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
.dialog-content-back {
  z-index: 100;
  width: 520px;
  height: 520px;
  background: url('@/assets/images/home/icon_member_background.png') no-repeat center 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  .dialog_content-xiaoju {
    display: flex;
    flex-direction: row;
    margin-top: 48px;
    align-items: center;
    .dialog_content-xiaoju-img {
      height: 3px;
    }
  }
  .img-close {
    position: absolute;
    width: 22px;
    top: 22px;
    right: 24px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .img-1 {
    width: 162px;
    height: 148px;
    margin-top: -80px;
  }
  .text-1 {
    margin-top: 32px;
    font-size: 28px;
    color: #e5725f;
    line-height: 37px;

    letter-spacing: 3px;
  }
  .text-2 {
    font-size: 24px;
    color: #e5725f;
    line-height: 31px;
    letter-spacing: 2px;
  }
  .text-3 {
    margin-top: 10px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #ffdbbf;
    line-height: 33px;
    .big {
      font-size: 43px;
      font-family: Arial-Black, Arial;
      font-weight: 900;
    }
  }
  .list {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      .img-icon {
        width: 59px;
      }
      .text {
        margin-top: 8px;
        font-size: 14px;
        color: #ffdbbf;
        line-height: 19px;
      }
    }
  }
  .button {
    margin-top: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 50px;

    background: linear-gradient(270deg, #ec6472 0%, #eb6f54 100%);
    border-radius: 34px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
::v-deep .el-dialog {
  background: none;
  box-shadow: none;
}

::v-deep .el-dialog__header {
  display: none;
}

::v-deep .el-dialog__body {
  padding: 0;
}
</style>

