<template>
  <div class="step2-box">
    <el-form label-width="100px" class="el-form-content">
      <el-form-item label="登录密码" prop="name">
        <el-input show-password placeholder="密码长度至少8个字符包括大小写字母、数字和特殊字符" class="el-item-text" v-model="newPassword1">
        </el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="name">
        <el-input show-password placeholder="请再次输入登录密码" class="el-item-text" v-model="newPassword2">
        </el-input>
      </el-form-item>

    </el-form>
    <el-button class="el-item-submit" @click="changeClick">修改密码</el-button>
  </div>
</template>

<script>
import { setPasswordByCode } from '@/api/user'
export default {
  name: 'changePassword',
  components: {},
  computed: {

  },
  props: {
    lastData: {
      "phone": '',
      "code": ''
    }
  },
  data() {
    return {
      // 第一次输入的密码
      newPassword1: '',
      //第二次输入的密码
      newPassword2: '',
    }
  },
  methods: {
    changeClick() {
      // 1.请求接口
      if (this.newPassword1 == '') {
        this.$message({
          type: 'error',
          message: '请输入新密码',
        })
        return
      }
      if (JSON.stringify(this.newPassword1).length < 8) {
        this.$message({
          type: 'error',
          message: '密码长度最少为8位',
        })
        return
      }

      if (this.newPassword2 == '') {
        this.$message({
          type: 'error',
          message: '请输入确认密码',
        })
        return

      }
      if (JSON.stringify(this.newPassword2).length < 8) {
        this.$message({
          type: 'error',
          message: '密码长度最少为8位',
        })
        return
      }
      if (this.newPassword1 != this.newPassword2) {
        this.$message({
          type: 'error',
          message: '两次输入密码不一致',
        })
        return

      }
      var params = {
        "phone": this.lastData.phone,
        "code": this.lastData.code,
        "password": this.newPassword1
      }
      setPasswordByCode(params).then(res => {
        if (res.code == "000000") {
          //切换到第三步
          this.$emit('step2Next')
        } else {
          
          this.$message({
            type: 'error',
            message:res.msg,
          })
        }
      })
    }
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.step2-box {
  width: 100%;
  height: 721px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

  .tip {
    margin-left: 248px;
    color: #BBBBBB;
    font-size: 12px;
    font-family: MicrosoftYaHei;

  }

  ::v-deep .el-input--small .el-input__inner {
    width: 343px;
    font-size: 12px;
    // height: 36px;
  }

  .el-item-submit {
    background: #E1251B;
    width: 168px;
    height: 42px;
    margin-top: 40px;
    color: #FFFFFF;
    border-radius: 4px;

  }

}
</style>
    