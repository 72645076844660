<template>
  <div class="index-box" >
    <img :src="require(`@/assets/images/realname/reamCer_${status}.png`)" alt=""  class="backgroundimg">
    <div  class="index-content">
    <div class="img-box" v-if="status == 3">
      <img src="@/assets/images/realname/realname_status.png" class="icon-menu"  />
      <div class="title">已完成企业实名认证</div>
    </div>
    <div class="img-box"  v-else-if="status == 2" >
      <img src="@/assets/images/realname/realname_status_2.png" class="icon-menu"  />
      <div class="title">企业实名认证已提交，请耐心等待审核~</div>
    </div>
    <div class="img-box"  v-else-if="status == -1">
      <img src="@/assets/images/realname/realname_status_-1.png" class="icon-menu"  />
      <div class="title">企业实名认证已驳回，请重新修改~</div>
      <div class="reason" v-if="failreason">{{ failreason }}</div>
    </div>
    <div class="img-box" v-else >
      <img src="@/assets/images/realname/realname_status.png" class="icon-menu"  />
      <div class="title">企业实名认证未提交</div>
    </div>
    <div class="tab-box" v-for="item in tablist" :key="item">
      <div class="tab-box-title">{{ infoList[item].name }}</div>
      <div class="tab-box-subtitle">{{ infoList[item].value }}</div>
    </div>
    <el-button class="el-item-submit"  type="primary"  v-if="status == -1" @click="editInfoClick">修改信息</el-button>
    
  </div>
  </div>
</template>
      
<script>

import { apiGetUserInfo } from '@/api/user'
import { getUserAuth } from '@/api/realname'
import { hideStr } from '@/utils/format'

export default {
  name: 'realNameReuslt',
  components: {},
  computed: {

  },
  data() {
    return {
      //测试数据 0 未认证 1审核中 2失败
      status: 2,
      failreason: '驳回原因',
      imgURL:'https://img.iplaysoft.com/wp-content/uploads/2019/free-images/free_stock_photo_2x.jpg!0x0.webp',
      form: {},
      tablist: [
        'serCompanyName', 'serLicenseCode', 'serLegalName', 'serLegalNo', 'LegalDate', 'serBusinessDate', 'serAddress', 'serMainCategory', 'serMainArea', 'serCompanyTypeName'
      ],
 
      infoList: {
        "serCompanyName": {
          "name": "企业名称",
          "value": ""
        },
        "serLicenseCode": {
          "name": "统一社会信用代码",
          "value": ""
        },
        "serLegalName": {
          "name": "姓名",
          "value": ""
        },
        "serLegalNo": {
          "name": "身份证号",
          "value": ""
        },
        "LegalDate": {
          "name": "证件有效期",
          "value": ""
        },
        "serBusinessDate": {
          "name": "经营期限",
          "value": ""
        },
        "serAddress": {
          "name": "经营地址",
          "value": ""
        },
        "serMainCategory": {
          "name": "主营类目",
          "value": ""
        },
        "serMainArea": {
          "name": "主营地区",
          "value": ""
        },
        "serCompanyTypeName": {
          "name": "企业类型",
          "value": ""
        },
      }
    }
  },
  methods: {
    getAuthInfo() {
      getUserAuth().then(res => {
        if (res.code == '000000') {
          var authInfo = res.data;
          this.form = res.data
          this.form.serAuthType = JSON.stringify(res.data['serAuthType'])
          // console.log('实名认证数据+++++', res.data)
          this.infoList.serCompanyName.value = authInfo.serCompanyName;
          this.infoList.serLicenseCode.value = hideStr(authInfo.serLicenseCode);
          if (authInfo.serAuthType == 1) {
            //委托人
            this.infoList.serLegalName.value = authInfo.serEntrustName;
            this.infoList.serLegalNo.value = hideStr(authInfo.serEntrustNo);
            this.infoList.LegalDate.value = authInfo.serIdCardTime;
          } else {
            //法人
            this.infoList.serLegalName.value = authInfo.serLegalName;
            this.infoList.serLegalNo.value = hideStr(authInfo.serLegalNo);
            this.infoList.LegalDate.value = authInfo.serIdCardTime;
          }
          if(authInfo.serIdCardType == 1) {
            this.infoList.LegalDate.value = '长期';
          } 
          // var serBusinessDate = "";
          // if(!authInfo.serBusinessBeginDate && authInfo.serBusinessBeginDate!=''){
          //   serBusinessDate += authInfo.serBusinessBeginDate;
          // }
          // if(!authInfo.serBusinessEndDate && authInfo.serBusinessEndDate!=''){
          //   if(serBusinessDate != ''){
          //      serBusinessDate += "——";
          //   }
          //   serBusinessDate += authInfo.serBusinessEndDate;
          // }
          var serBusinessDate = authInfo.serBusinessBeginDate + '至';
          if(authInfo.serBusinessDateAll == 1){
            serBusinessDate += '无固定期限'
          }else{
            serBusinessDate += authInfo.serBusinessEndDate
          }
          this.infoList.serBusinessDate.value = serBusinessDate;

          this.infoList.serAddress.value = authInfo.serProvName + authInfo.serCityName + authInfo.serAreaName + authInfo.serAddress;
          this.appendCagetory(JSON.parse(authInfo.serMainCategory), 1)
          this.appendCagetory(JSON.parse(authInfo.serMainArea), 2)
          this.infoList.serCompanyTypeName.value = authInfo.serCompanyTypeName;
          this.failreason = '驳回原因：' + authInfo.serFailMsg;
        }
      })
    },
    appendCagetory(cagetroyList, index) {
      var itemArr = []
      if (cagetroyList) {
        if (cagetroyList.length > 0) {
          for (let index = 0; index < cagetroyList.length; index++) {
            const element = cagetroyList[index]
            var name = element['name']
            itemArr.push(name)
          }

          if (index == 1) {
            this.infoList.serMainCategory.value = itemArr.join("、");

          } else if (index == 2) {
            this.infoList.serMainArea.value = itemArr.join("、");
          }
        }

      }

    },
    editInfoClick() {
      var json = this.form
      json.serIdCardType = JSON.stringify(json.serIdCardType)
    
      json.serBusinessDateAll = JSON.stringify(json.serBusinessDateAll)

      this.$emit('editInfo', json)
    }

  },
  mounted() {
    apiGetUserInfo().then(res => {
      if (res.code == '000000') {
        this.status = res.data.serStatus;
        this.getAuthInfo();
  
      }
    })
  }
}
</script>
      
<style lang="scss" scoped>
@import '@/styles/variables';


.index-box {
  width: 1060px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;  
  padding-bottom:90px ;
 
  .backgroundimg{
    position: absolute;
    left: 0px;
    top: 0px;
    width:1060px;
    height:100%;  /**宽高100%是为了图片铺满屏幕 **/
    z-index:10;

}

  .index-content {
    z-index:11;
    // position: absolute;
    display: flex;
    width: 100%;
    left: 0px;
    top: 0px;
    align-items: center; 
    flex-direction: column;


  }

.img-box {
  margin-top: 50px; 
  display: flex;
  align-items: center; 
  flex-direction: column;

}
  .icon-menu {
    display: flex;
    align-items: center;
    width: 118px;
    height: 110px;
    display: flex;
    align-items: center;
  }

  .title {
    height: 26px;
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
    line-height: 26px;
    margin-top: 11px;
  }

  .reason {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #E1251B;
    margin-top: 5px;
  }

  .tab-box {
    display: flex;
    width: 400px;
    // padding: 15px;
    margin-top: 30px;
  }

  .tab-box .tab-box-title {
    width: 136px;
    height: 19px;
    text-align: right;
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
  }

  .tab-box .tab-box-subtitle {
    // height: 19px;
    width: 75%;
    margin-left: 15px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #999999;
  }

}

.el-item-submit {
  background: #E1251B;
  width: 168px;
  height: 42px;
  margin-top: 32px;
  color: #FFFFFF;
  border-radius: 4px;
  // margin-bottom: 67px;

}
</style>
  