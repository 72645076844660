<template>
    <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :on-change="handleChange"
        :file-list="fileList">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
    </el-upload>
</template>

<script>
export default {
    name: 'loginByPhone',
    computed: {},
    data() {
        return {
            fileList: []
        }
    },
    mounted() {
        //初始化
    },
    methods: {
        handleChange(file, fileList) {
            this.fileList = fileList.slice(-3);
            console.log('上传的文件' + this.fileList)
        }

    }
}
</script>
