import { apiGetUserInfo } from '@/api/user'
import { getToken, removeToken, setToken } from '@/utils/cookie'
import router from '@/router'

const state = () => {
  return {
    userInit: false,
    userInfo: {},
    userPosition: {
      location: {
        lng: '',
        lat: ''
      }
    },
  }
}

const mutations = {
  // 是否获取了用户信息
  setUserInit(state, init) {
    state.userInit = init
  },
  // 用户信息
  setUserUuid(state, id) {
    state.userInfo.userUuid = id
  },
  // 用户信息
  setUserInfo(state, info) {
    state.userInfo = info
  },
  // 用户位置
  setUserPosition(state, pos) {
    state.userPosition = pos
  },
  updateUserVip(state) {
    state.userInfo.vipFlag = 1
  },
}

const actions = {
  // user login
  // login({ commit }, userInfo) {
  //   const { account, password } = userInfo
  //   return new Promise((resolve, reject) => {
  //     login({ account: account.trim(), password: password })
  //       .then((response) => {
  //         const { data } = response
  //         localStorage.setItem('logindata', JSON.stringify(response.data.menus))
  //         // commit('SET_TOKEN', data.token)
  //         setToken(data.token)
  //         resolve()
  //       })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },
  // get user info
  getUserInfo({ commit, state }) {
    if (getToken()) {
      commit('setUserUuid', getToken())
      return new Promise((resolve, reject) => {
        apiGetUserInfo({ userUuid: getToken() })
          .then((res) => {
            // console.log('用户信息数据======',res)
            var userInfo = res.data;
            if(userInfo.serStatus == 3 || userInfo.entStatus == 3){
              userInfo.authenticationStatus = 1
            }else{
              userInfo.authenticationStatus = 0
            }
            commit('setUserInfo', userInfo);
            commit('setUserInit', true)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      removeToken()
      localStorage.removeItem('publish-base-info')
      commit('setUserInfo', {})
      commit('setUserInit', false)
      router.replace('/login')
      resolve()
    })
  },
  updateUserInfo({ commit, state }) {
    if (getToken()) {
      commit('setUserUuid', getToken())
      return new Promise((resolve, reject) => {
        apiGetUserInfo({ userUuid: getToken() })
          .then((res) => {
            var userInfo = res.data;
            if(userInfo.serStatus == 3 || userInfo.entStatus == 3){
              userInfo.authenticationStatus = 1
            }else{
              userInfo.authenticationStatus = 0
            }
            commit('setUserInfo', userInfo)
            commit('setUserInit', true)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  // // remove token
  // resetToken({ commit }) {
  //   return new Promise((resolve) => {
  //     // commit('SET_TOKEN', '')
  //     // commit('SET_ROLES', [])
  //     removeToken() // must remove  token  first
  //     resolve()
  //   })
  // },
  // // dynamically modify permissions
  // changeRoles({ commit, dispatch }, role) {
  //   return new Promise(async (resolve) => {
  //     const token = role + '-token'
  //     // commit('SET_TOKEN', token)
  //     setToken(token)
  //     const { roles } = await dispatch('getInfo')
  //     resetRouter()
  //     // generate accessible routes map based on roles
  //     const accessRoutes = await dispatch('permission/generateRoutes', roles, {
  //       root: true,
  //     })
  //     // dynamically add accessible routes
  //     router.addRoutes(accessRoutes)
  //     // reset visited views and cached views
  //     dispatch('tagsView/delAllViews', null, { root: true })
  //     resolve()
  //   })
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
