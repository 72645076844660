<template>
  <div class="app-container">
    <el-upload ref="elUpload" :limit="limit" class="upload-container" :on-exceed="handleExceed" :multiple='multiple' action="https://v0.api.upyun.com/jupaiwang"
      list-type="picture-card" :before-upload="beforeUpload" :on-preview="handlePreview" :on-remove="handleRemove"
      :file-list="fileList" :data="fileExtendData" :on-success="onSuccess">
      <i class="el-icon"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getUpyunToken } from '@/api/common'

export default {
  name: 'UploadImage',
  computed: {},
  props: {
    imageList: Array,
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 允许上传类型
    fileTypes: {
      type:Array,
      // default: () => ['.jpg','.jpeg','.png']
      default: () => ['.jpg','.jpeg','.png','.rar','.zip','.pdf','.doc','.docx','.xls','.xlsx']

      
    }
  },
  data() {
    return {
      limit: 10,
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      fileExtendData: {},
    }
  },
  mounted() {
    //初始化
    if (this.imageList && this.imageList.length > 0) {
      this.fileList = this.imageList
      this.addImageAttr()
    }
  },
  methods: {
    // 图片添加属性
    addImageAttr() {
      this.$nextTick(() => {
        const eleList = document.getElementsByClassName('el-upload-list__item-thumbnail')
        if (eleList && eleList.length > 0) {
          Array.prototype.forEach.call(eleList, (item) => {
            item.setAttribute('referrer', 'no-referrer|origin|unsafe-url')
            item.setAttribute('referrerPolicy', 'no-referrer')
          })
        }
      })
    },
    // 删除图片
    handleRemove(file, fileList) {
      this.onSuccess('', file, fileList)
    },
    // 预览图片
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 获取 token
    beforeUpload(file) {
      const { name } = file;
      const fileSuffix = name.split('.');
      const len = fileSuffix.length - 1;
      // 判断上传文件的后缀名, 将后缀名都转换成小写做比较
      if (
        this.fileTypes.map((item) => item.toLowerCase()).indexOf('.' + fileSuffix[len].toLowerCase()) < 0
      ) {
        // 取消上传请求
        this.$alert('选择的文件中包含不支持的格式', '提示');
        return false;
      }

      // 获取 token
      return new Promise((resolve, reject) => {
        var params = { propKey: 'jupai' }
        getUpyunToken(params).then((res) => {
          if (res.code == '000000') {
            this.fileExtendData = {
              policy: res.data.policy,
              authorization: 'UPYUN jupai:' + res.data.sign,
            }
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    //文件超出个数限制
    handleExceed() {
      this.$message.warning(`当前限制选择 ${this.limit} 个文件`);
    },
    // 上传成功
    onSuccess(response, file, fileList) {
      this.addImageAttr()
      fileList.forEach((item) => {
        if (item.response) {
          item.url = process.env.VUE_APP_UPYUN_API + item.response.url
        }
      })
      console.log(fileList)
      this.$emit('success', fileList)
    },
    // 清空图片
    clear() {
      this.$refs.elUpload.clearFiles()
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables';

::v-deep .el-upload.el-upload--picture-card {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-upload--picture-card i {
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    width: 54px;
    height: 51px;
    background: url('@/assets/images/home/upload.png') no-repeat center / 100%;
  }
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}

::v-deep .el-upload.el-upload--picture-card {
  border: 1px dashed #ececec;
}
</style>
