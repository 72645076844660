import request from '@/utils/request'

// 登录测试
export function loginByCode(data) {
  return request({
    url: '/appApi/jzl/loginPage/login',
    method: 'post',
    data: data,
  })
}

// 领取vip
export function gainVip(data) {
  return request({
    url: '/appApi/jzl/userVip/gainVip',
    method: 'post',
    data: data,
  })
}

// 获取用户信息
export function apiGetUserInfo(data) {
  return request({
    url: '/appApi/jzl/user/getUserBaseInfo',
    method: 'post',
    data: data,
  })
}

// 获取用户认证信息
export function apiGetUserAuth(data) {
  return request({
    url: '/appApi/jzl/user/getUserAuth',
    method: 'post',
    data: data,
  })
}

// 验证短信验证码
export function checkPhoneCode(data) {
  return request({
    url: '/appApi/jzl/loginPage/checkPhoneCode',
    method: 'post',
    data: data,
  })
}

// 设置密码通过短信验证码
export function setPasswordByCode(data) {
  return request({
    url: '/appApi/jzl/loginPage/setPasswordByCode',
    method: 'post',
    data: data,
  })
}
// 注册新用户
export function registerNewUser(data) {
  return request({
    url: '/appApi/jzl/loginPage/register',
    method: 'post',
    data: data
  })
}
//修改用户信息
export function modifyUserInfo(data) {
  return request({
    url: '/appApi/jzl/user/modifyUserInfo',
    method: 'post',
    data: data
  })
}
// 绑定服务商
export function bindingAccountIdentity(data) {
  return request({
    url: '/appApi/jzl/loginPage/bindingAccountIdentity',
    method: 'post',
    data: data
  })
}