<template>
  <div class="page-container">
    <Header @home="toHome" />
    <div class="page-content">
      <div class="main-content">
        <div class="breadcrumb-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-if="publishEdit">编辑{{ type.value }}信息</el-breadcrumb-item>
            <el-breadcrumb-item v-else>发布{{ type.value }}信息</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="step-box">
          <div class="step" :class="{ active: step >= 1 }">
            <div class="num">1</div>
            <div class="title">填写基本信息</div>
          </div>
          <div class="step" :class="{ active: step >= 2 }">
            <div class="num">2</div>
            <div class="title">填写物资信息</div>
          </div>
          <div class="step" :class="{ active: step >= 3 }">
            <div class="num">3</div>
            <div class="title">提交物资信息</div>
          </div>
        </div>
        <div class="step-box" v-if="step > 0">
          <FormStep1 :publishEdit="publishEdit" v-show="step === 1" @home="toHome" @next="next" />
          <FormStep2 :publishEdit="publishEdit" :type="type.key" :defaultData="defaultData" v-show="step === 2" @back="step = 1" @submit="submit" />
          <FormStep3 :publishEdit="publishEdit" :type="type.value" v-show="step === 3" @home="toHome" @goon="goonPublish" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Header from '@/components/public/Header.vue'
import FormStep1 from './components/FormStep1.vue'
import FormStep2 from './components/FormStep2.vue'
import FormStep3 from './components/FormStep3.vue'
import { apiPublishInfo, apiGetMyLeaseInfo, apiEditMyLeaseInfo, apiGetUserAuthInfo } from '@/api/index'

export default {
  name: 'PublishIndex',
  components: { Header, FormStep1, FormStep2, FormStep3 },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  data() {
    return {
      publishEdit: false, // 是否编辑信息
      typeList: [
        { key: 1, value: '出租' },
        { key: 2, value: '出售' },
        { key: 3, value: '求租' },
        { key: 4, value: '求购' },
      ],
      type: {},
      step: 1,
      dataStep1: {},
      dataStep2: {},
      defaultData: {},
    }
  },
  created() {
    // 非最优方法，后续有时间优化，只是做特殊处理，不影响使用
    let { infoNo, type } = this.$route.params
    if (infoNo) {
      this.publishEdit = true
      // 获取信息
      apiGetMyLeaseInfo({
        infoNo,
        userUuid: this.userInfo.userUuid,
      }).then((res) => {
        this.defaultData = res.data
      })
    }
    if (type) {
      localStorage.setItem('publishType', type)
    } else if (localStorage.getItem('publishType')) {
      type = parseInt(localStorage.getItem('publishType'))
    } else {
      type = 1
    }
    this.type = this.typeList.find((item) => {
      return item.key === parseInt(type)
    })
  },
  mounted() {},
  // 离开提示
  beforeRouteLeave(to, from, next) {
    if (this.step !== 3) {
      this.$confirm('离开本页面会丢失已填写的表单信息！', '提示', {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
      })
        .then(() => {
          console.log(this.userInfo.userUuid)
          if(this.userInfo.userUuid) {
            next()
          } else {
            next({replace: true,redirect: '/login'})
          }
        })
        .catch(() => {
          next(false)
          // if(!this.userInfo.userUuid) {
          //   next({replace: true,redirect: '/login'})
          // }
        })
    } else {
      next()
    }
  },
  methods: {
    // ...mapActions('user', ['getUserInfo']),

    // 继续发布
    goonPublish() {
      // this.$router.replace(`/publish`)
      // location.reload()
      this.$router.replace({ path: '/publish/'+this.type.key });
      window.location.reload()
    },
    // 返回首页
    toHome() {
      this.$router.go(-1)
    },
    // 下一步
    next(data) {
      this.dataStep1 = data
      this.step = 2
    },
    // 发布物资
    submit(dataStep2) {
      const sendData = {...this.dataStep1,
        userUuid: this.userInfo.userUuid,
        releaseType: this.type.key, // 发布类型 1：出租，2：出售，3：求租，4：求购
        materialList: dataStep2,
      }
      if (this.publishEdit) {
        // 编辑
        sendData.id = this.defaultData.id
        sendData.infoNo = this.defaultData.infoNo
        console.log('sendData', sendData)
        apiEditMyLeaseInfo(sendData).then((res) => {
          const { code, data, msg } = res
          if (code === '000000') {
            this.step = 3
            // 本地存储基本信息
            const a = { ...this.dataStep1, leaseDesc: '' }
            localStorage.setItem('publish-base-info', JSON.stringify(a))
          } else {
            this.$message({
              type: 'error',
              message: msg,
            })
          }
        })
      } else {
        console.log('sendData', sendData)
        apiPublishInfo(sendData).then((res) => {
          const { code, data, msg } = res
          if (code === '000000') {
            this.step = 3
            // 本地存储基本信息
            const a = { ...this.dataStep1, leaseDesc: '' }
            localStorage.setItem('publish-base-info', JSON.stringify(a))
          } else {
            this.$message({
              type: 'error',
              message: msg,
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.page-container {
  .page-content {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    background: #ffffff;
  }
}
.main-content {
  width: $contentWidth;
  padding: 11px 0 60px;
}
.breadcrumb-box {
  width: 100%;
}
.step-box {
  margin-top: 3px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    .num {
      width: 36px;
      height: 36px;
      margin-right: 5px;
      background: #d8d8d8;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
    }
    .title {
      font-size: 12px;
      color: #bbbbbb;
    }
    &.active {
      .num {
        background: $importantColor;
      }
      .title {
        color: $primaryTextColor;
      }
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
    &:after {
      content: '';
      width: 100px;
      height: 1px;
      border-top: 1px dashed #999999;
      margin: 0 5px;
    }
  }
}
</style>
