<template>
  <div class="component-container list">
    <div class="component-content">
      <div class="top-box">
        <div class="breadcrumb-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ navList[navActive].name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="selected" :style="{ marginBottom: selectedList.length > 0 && '5px' }">
          <div class="item" v-for="(item, index) in selectedList" :key="index" @click="handleDelete(item, index)">
            <p class="label">{{ item.label }}：</p>
            <p class="value secondary-text">{{ item.value }}</p>
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <div class="search-box" v-loading="loadingSearch">
        <SearchItem ref="refmaterialType" v-if="allCategoryList" class="search-item" label="物资类型" @change="handleChange($event, 'materialType')" :data-list="allCategoryList" :oprate="{ open: false, check: false }" />
        <SearchItem
          ref="refmaterialCategory"
          v-if="allCategoryList && allCategoryList[activeIndex.materialType] && allCategoryList[activeIndex.materialType].children"
          class="search-item"
          label="物资分类"
          @change="handleChange($event, 'materialCategory')"
          :data-list="allCategoryList[activeIndex.materialType].children"
          :oprate="{ check: false, open: true }"
        />
        <SearchItem
          ref="refmaterialCategoryList"
          v-if="allCategoryList && allCategoryList[activeIndex.materialType] && allCategoryList[activeIndex.materialType].children && allCategoryList[activeIndex.materialType].children[activeIndex.materialCategory]"
          class="search-item"
          label="物资名称"
          @change="handleChange($event, 'materialCategoryList')"
          :data-list="allCategoryList[activeIndex.materialType].children[activeIndex.materialCategory].sonList"
        />
        <SearchItem ref="refProvinceCodeList" v-if="provinceList" class="search-item" label="所在省份" @change="handleChange($event, 'provinceCodeList')" :data-list="provinceList" />
        <SearchItem ref="refCityCodeList" v-if="cityList.length" class="search-item" label="所在城市" @change="handleChange($event, 'cityCodeList')" :data-list="cityList" />
        <SearchItem ref="refLabelKilometerNumber" class="search-item" label="距离范围" @change="handleChange($event, 'labelKilometerNumber')" :data-list="distanceList" :oprate="{ open: false, check: false }">
          <div class="slot-box">
            <input v-model="startKilometerNumber" type="Number" min="1" class="input" placeholder="请输入公里数" />
            <i class="el-icon-minus icon"></i>
            <input v-model="endKilometerNumber" type="Number" min="1" class="input" placeholder="请输入公里数" />
            <div class="button" @click="handleKilometerInput">确定</div>
          </div>
        </SearchItem>
      </div>
      <div class="line">
        <div class="left">
          <div class="button" :class="{ active: sortType === 0 }" @click="handleSort(0)">综合排序</div>
          <div class="button" :class="{ active: sortType === 1 }" @click="handleSort(1)">上架时间</div>
        </div>
        <div class="right">
          共有{{ total }}物资
          <p class="page">
            <span class="important-text"> {{ page }} </span>
            /{{ Math.ceil(total / size) }}
          </p>
          <div class="button" :class="{ disable: page === 1 }" @click="pagePrev">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="button" :class="{ disable: page === Math.ceil(total / size) }" @click="pageNext">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="goods-list" v-loading="loadingList">
        <div class="list-content" v-if="goodsList && goodsList.length > 0">
          <GoodsItem class="goods-item" v-for="item in goodsList" :data="item" :key="item.infoNo" @set-vip-show="setVipShow" :subhead="navList[navActive].name" />
        </div>
        <el-empty v-else :image-size="200"></el-empty>
      </div>
      <div class="pagination">
        <el-pagination @current-change="handlePageChange" :current-page="page" layout="total, prev, pager, next, jumper" :total="total"> </el-pagination>
        <div class="button" @click="handleSearch">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodsItem from './GoodsItem'
import SearchItem from './SearchItem'
import { mapState, mapMutations } from 'vuex'
import { apiGetSubArea, apiSearchList } from '@/api/index'
import { collectGoods } from '@/api/detail'
import { NavList } from '@/constant/index'

export default {
  name: 'GoodsList',
  components: { GoodsItem, SearchItem },
  props: {
    title: String,
  },
  data() {
    return {
      navList: NavList,
      loadingSearch: true,
      loadingList: true,
      total: 0,
      selectedList: [],
      page: 1,
      size: 10,
      searchParams: {
        materialType: { key: '' }, // 物资类型
        materialCategory: { code: '' }, // 物资分类
        materialCategoryList: [], // 物资名称
        provinceCodeList: [], // 省
        cityCodeList: [], // 市
        labelKilometerNumber: { key: '' }, // 公里
      },
      startKilometerNumber: '',
      endKilometerNumber: '',
      activeIndex: {
        materialType: 0, // 物资类型
        materialCategory: 0, // 物资分类
      },
      distanceList: [
        { key: 100, name: '100公里' },
        { key: 200, name: '200公里' },
        { key: 300, name: '300公里' },
        { key: 400, name: '400公里' },
        { key: 500, name: '500公里' },
        { key: 800, name: '800公里' },
      ],
      provinceList: [],
      cityList: [],
      goodsList: [],
      sortType: 0, //0表示距离升序排序、1表示发布时间倒序；没有经纬度按照1排序
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      userPosition: (state) => state.user.userPosition,
      allCategoryList: (state) => state.home.allCategoryList,
      navActive: (state) => state.home.navActive,
      searchKeywords: (state) => state.home.searchKeywords,
      allCategoryListClickIndex: (state) => state.home.allCategoryListClickIndex,
    }),
  },
  watch: {
    searchParams: {
      handler: function (newval, oldVal) {
        this.selectedListChange()
      },
      deep: true,
    },
    searchKeywords: {
      handler: function (newval, oldVal) {
        this.selectedListChange()
      },
      immediate: true,
    },
    userPosition: {
      handler: function () {
        this.handleSearch()
      },
      deep: true,
    },
    // 因为保留筛选条件，所以使用一个组件，监听nav变化
    navActive: {
      handler: function (newVal, oldVal) {
        this.page = 1
        this.handleSearch()
      },
    },
  },
  mounted() {
    this.$bus.$on('search', this.handleSearch)
    this.$bus.$on('clickAllCategory', this.handleClickAllCategory)
    if (this.allCategoryListClickIndex[0] > -1) {
      this.handleClickAllCategory()
    }

    apiGetSubArea({ parentCode: 100000 }).then((res) => {
      this.provinceList = res.data
      this.loadingSearch = false
      this.handleSearch()
      // apiGetSubArea({ parentCode: this.provinceList[0].code }).then((res) => {
      //   this.cityList = res.data
      //
      //
      // })
    })
  },
  destroyed() {
    this.$bus.$off('search', this.handleSearch)
    this.$bus.$off('clickAllCategory', this.handleClickAllCategory)
  },
  methods: {
    ...mapMutations('home', ['setSearchKeywords', 'setAllCategoryListClickIndex']),
    // 上一页
    pagePrev() {
      if (this.page === 1) {
        return
      }
      this.page--
      this.handleSearch()
    },
    // 下一页
    pageNext() {
      if (this.page === Math.ceil(this.total / this.size)) {
        return
      }
      this.page++
      this.handleSearch()
    },
    isPositiveInteger(value) {
      var pattern = /^[1-9]\d*$/
      return pattern.test(value)
    },
    // 输入公里数
    handleKilometerInput() {
      this.startKilometerNumber <= 0 && (this.startKilometerNumber = '')
      this.endKilometerNumber <= 0 && (this.endKilometerNumber = '')

      if (!this.startKilometerNumber && !this.endKilometerNumber) {
        this.$message({
          type: 'error',
          message: '请输入公里数',
        })
      } else if (this.startKilometerNumber && !this.isPositiveInteger(+this.startKilometerNumber)) {
        this.$message({
          type: 'error',
          message: '请输入正整数',
        })
      } else if (this.endKilometerNumber && !this.isPositiveInteger(+this.endKilometerNumber)) {
        this.$message({
          type: 'error',
          message: '请输入正整数',
        })
      } else if (this.startKilometerNumber && this.endKilometerNumber && +this.startKilometerNumber >= +this.endKilometerNumber) {
        this.$message({
          type: 'error',
          message: '最小公里数不能大于最大公里数',
        })
      } else {
        this.searchParams.startKilometerNumber = this.startKilometerNumber
        this.searchParams.endKilometerNumber = this.endKilometerNumber
        this.$refs.refLabelKilometerNumber.clearChecked()
      }
    },
    // 页码变化
    handlePageChange(page) {
      this.page = page
      this.handleSearch()
    },
    // 筛选条件变化
    handleChange(value, type) {
      let change = false
      this.searchParams[type] = value
      if (type === 'materialType') {
        if (value === '') {
          this.activeIndex.materialType = 0
        } else {
          this.activeIndex.materialType = this.allCategoryList.findIndex((item) => {
            return item.key === value.key
          })
          if (this.activeIndex.materialType === -1) {
            this.activeIndex.materialType = 0
          }
        }
        this.activeIndex.materialCategory = 0
        change = true
        this.$refs.refmaterialCategory.clearChecked()
      }
      if (type === 'materialCategory') {
        if (value === '') {
          this.activeIndex.materialCategory = 0
        } else {
          this.activeIndex.materialCategory = this.allCategoryList[this.activeIndex.materialType].children.findIndex((item) => {
            return item.code === value.code
          })
          if (this.activeIndex.materialCategory === -1) {
            this.activeIndex.materialCategory = 0
          }
        }
        change = true
        this.$refs.refmaterialCategoryList && this.$refs.refmaterialCategoryList.clearChecked()
      }
      if (type === 'provinceCodeList') {
        this.cityList = []

        if (value.length === 0) {
          // if (this.provinceList[0]) {
          //   apiGetSubArea({ parentCode: this.provinceList[0].code }).then((res) => {
          //     this.cityList = res.data
          //   })
          // }
        } else {
          value.forEach((item) => {
            apiGetSubArea({ parentCode: item.code }).then((res) => {
              this.cityList = this.cityList.concat(res.data)
            })
          })
          change = true

          if (this.$refs.refCityCodeList) {
            this.$refs.refCityCodeList.clearChecked()
          }
        }
        this.handleSearch()

      }
      if (!change) {
        // 联动变化完成再搜索
        this.page = 1
        this.handleSearch()
      }
    },
    // 搜索
    handleSearch() {
      this.$nextTick(() => {
        // 1：出租，2：出售，3：求租，4：求购，5：置换，
        const data = {
          userUuid: this.userInfo.userUuid,
          releaseType: this.navActive,
          materialType: this.searchParams.materialType.key || '', // 物资类型
          materialCategory: this.searchParams.materialCategory.code || '', // 物资分类
          materialsName: this.searchKeywords,
          materialCategoryList: this.searchParams.materialCategoryList.map((item) => {
            return item.code
          }),
          provinceCodeList: this.searchParams.provinceCodeList.map((item) => {
            return item.code
          }), // 省
          cityCodeList: this.searchParams.cityCodeList.map((item) => {
            return item.code
          }), // 市
          labelKilometerNumber: this.searchParams.labelKilometerNumber.key || '', // 公里
          startKilometerNumber: this.startKilometerNumber || '',
          endKilometerNumber: this.endKilometerNumber || this.searchParams.labelKilometerNumber.key || '',
          page: this.page,
          size: this.size,
          lon: this.userPosition.location.lng,
          lat: this.userPosition.location.lat,
          sortType: this.sortType, // 0表示距离升序排序、1表示发布时间倒序；没有经纬度按照1排序
        }

        // console.log('apiSearchList data', data)
        apiSearchList(data).then((res) => {
          const { data, count } = res
          this.loadingList = false
          this.goodsList = data
          this.total = count
        })
      })
    },
    // 删除
    handleDelete(item, index) {
      this.page = 1
      this.selectedList.splice(index, 1)
      const a = this.$store.state.home.allCategoryListClickIndex
      a.splice(index)
      this.setAllCategoryListClickIndex([...a])
      if (item.ref) {
        this.$refs[item.ref].deleteItem(item)
      } else if (item.type === 'kilometerNumber') {
        this.startKilometerNumber = ''
        this.endKilometerNumber = ''
        this.handleSearch()
      } else if (item.type === 'keyWords') {
        this.setSearchKeywords('')
        this.handleSearch()
      }
    },
    // 排序
    handleSort(type) {
      this.sortType = type
      this.page = 1
      this.handleSearch()
    },
    // 选中的标签变化
    selectedListChange() {
      this.selectedList = []
      if (this.searchKeywords) {
        this.selectedList.push({
          type: 'keyWords',
          label: '关键词',
          value: this.searchKeywords,
        })
      }
      const {
        materialType, // 物资类型
        materialCategory, // 物资分类
        materialCategoryList, // 物资名称
        provinceCodeList, // 省
        cityCodeList, // 市
        labelKilometerNumber, // 公里
      } = this.searchParams
      if (materialType.key) {
        this.selectedList.push({
          ref: 'refmaterialType',
          key: materialType.key,
          label: '物资类型',
          value: materialType.value,
        })
      }
      if (materialCategory.code) {
        this.selectedList.push({
          ref: 'refmaterialCategory',
          code: materialCategory.code,
          label: '物资分类',
          value: materialCategory.name,
        })
      }
      if (materialCategoryList.length > 0) {
        materialCategoryList.forEach((item) => {
          this.selectedList.push({
            ref: 'refmaterialCategoryList',
            code: item.code,
            label: '物资名称',
            value: item.name,
          })
        })
      }
      if (provinceCodeList.length > 0) {
        provinceCodeList.forEach((item) => {
          this.selectedList.push({
            ref: 'refProvinceCodeList',
            code: item.code,
            label: '所在省份',
            value: item.name,
          })
        })
      }
      if (cityCodeList.length > 0) {
        cityCodeList.forEach((item) => {
          this.selectedList.push({
            ref: 'refCityCodeList',
            code: item.code,
            label: '所在城市',
            value: item.name,
          })
        })
      }
      if (labelKilometerNumber.key) {
        this.selectedList.push({
          ref: 'refLabelKilometerNumber',
          code: labelKilometerNumber.key,
          label: '距离范围',
          value: labelKilometerNumber.name,
        })
        this.startKilometerNumber = this.endKilometerNumber = ''
      }
      if (this.startKilometerNumber || this.endKilometerNumber) {
        this.selectedList.push({
          type: 'kilometerNumber',
          label: '距离范围',
          value: `${this.startKilometerNumber}-${this.endKilometerNumber}公里`,
        })
      }
    },
    // 点击全部商品分类
    handleClickAllCategory() {
      // console.log(this.allCategoryListClickIndex)
      this.activeIndex = {
        materialType: this.allCategoryListClickIndex[0], // 物资类型
        materialCategory: this.allCategoryListClickIndex[1], // 物资分类
      }
      this.$nextTick(() => {
        this.$refs.refmaterialType && this.$refs.refmaterialType.setActive(this.allCategoryListClickIndex[0])
        this.$refs.refmaterialCategory && this.allCategoryListClickIndex.length >= 2 && this.$refs.refmaterialCategory.setActive(this.allCategoryListClickIndex[1])
        if (this.allCategoryListClickIndex[2] > -1) {
          this.$refs.refmaterialCategoryList && this.$refs.refmaterialCategoryList.setActive(this.allCategoryListClickIndex[2])
        }

        // 因为保留筛选条件，所以点击全部商品分类时需要清除其他标签
        this.$refs.refProvinceCodeList && this.$refs.refProvinceCodeList.clearChecked()
        this.$refs.refCityCodeList && this.$refs.refCityCodeList.clearChecked()
        this.$refs.refLabelKilometerNumber && this.$refs.refLabelKilometerNumber.clearChecked()
        this.startKilometerNumber = this.endKilometerNumber = ''
      })
    },
    setVipShow(data) {
      // console.log('点击了2')
      this.$emit('set-vip-show-top', data)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.component-container.list {
  width: $contentWidth;
}
.top-box {
  width: 100%;
  .breadcrumb-box {
    width: 100%;
    color: #656567;
    margin-bottom: 10px;
    .el-breadcrumb{
      font-size: 12px;
    }
  }
  .selected {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .item {
      height: 20px;
      background: #ffffff;
      margin-right: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      color: #999999;
      padding: 0 10px;
      margin-bottom: 5px;
      .label {
      }
      .value {
        color: $importantColor;
        margin-right: 5px;
      }
      i {
        cursor: pointer;
        margin-top: 2px;
        &:hover {
          color: $importantColor;
        }
      }
    }
  }
}
.search-box {
  width: 100%;
  background: #ffffff;
  padding: 0 20px;
  line-height: 35px;
  .search-item:not(:nth-last-child(2)) {
    border-bottom: 1px dashed #e7e7e7;
  }
}
.line {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 73px;
      height: 20px;
      border-radius: 2px;
      cursor: pointer;
      &:hover {
        color: $importantColor;
      }
      &.active {
        background: $importantColor;
        color: #ffffff;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
  .right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .page {
      margin: 0 10px;
      font-size: 14px;
    }
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
      background: $importantColor;
      color: #ffffff;
      border-radius: 2px;
      &.disable {
        background: #f4f4f4;
        color: #bbbbbb;
        cursor: default;
      }
      &:hover {
        opacity: 0.8;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}
.goods-list {
  margin-top: 10px;
  width: 100%;
  .list-content {
    width: 100%;
    min-height: 394px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .goods-item {
      margin-right: 10px;
      margin-bottom: 10px;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .button {
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 24px;
    background: #e1251b;
    border-radius: 2px;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}

::v-deep .el-pager li.active {
  color: $importantColor;
}
::v-deep .el-pager li:hover {
  color: $importantColor;
}
::v-deep .el-pagination button:hover {
  color: $importantColor;
}

::v-deep .el-breadcrumb__inner {
  color: #656567;
}
.slot-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .input {
    width: 90px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #bbbbbb;
    font-size: 12px;
    padding-left: 8px;
    &::placeholder {
      color: #bbbbbb;
    }
  }
  .input[type=number]::-webkit-outer-spin-button,
  .input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .input[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }

  .icon {
    margin: 0 4px;
  }
  .button {
    margin-left: 10px;
    width: 68px;
    height: 30px;
    background: #e1251b;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep .el-pagination__total {
  color: $primaryTextColor;
}

::v-deep .el-pager {
  color: $primaryTextColor;
  font-weight: normal;
}
::v-deep .el-pager li {
  background: none;
}
::v-deep .el-pagination .btn-prev,
::v-deep .el-pagination .btn-next {
  background: none;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  height: 24px;
}
.el-empty {
  margin: 0 auto;
}
</style>
