import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
import axios from "axios";
import dragscroll from '@/utils/directives.js'; // 引入鼠标拖拽滚动图片指令文件


Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.use(ElementUI, { size: 'small', zIndex: 3000 })

// 注册鼠标拖拽滚动图片指令
Vue.directive('dragscroll', dragscroll);

const bus = new Vue()
Vue.prototype.$bus = bus

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
