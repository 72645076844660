/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  return /^1[2,3,4,5,6,7,8,9]\d{9}$/.test(s)
  // return /^1[0-9]{10}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * 身份证
 * @param {*} s
 */
export function isIDBumber(s) {
  return /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
  return /^http[s]?:\/\/.*/.test(s)
}

/**
 * 整数
 * @param {*} s
 */
export function isInteger(s) {
  return Number.isInteger(Number(s))
}

/**
 * 整数 和小数
 * @param {*} s
 */
export function isIntOrDouble(s) {
  return /^[0-9]+\.?[0-9]*$/.test(s)
}

/**
 * 密码  正则验证密码 必须包含大小写字母数字,长度大于六
 * @param {*} s
 */
export function isPassword(s) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,20}$/.test(s)
}

/**
 * 不包含 数字和特殊符号
 * @param {*} s
 */
export function notHavNumAndSymbol(s) {
  console.log(s, '----')
  // console.log(/^(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/])$/.test(s))
  return /^[\u0391-\uFFE5A-Za-z]+$/.test(s)
}
/**
 * 只能 数字和特殊符号
 * @param {*} s
 */
export function isNumAndLetter(s) {
  console.log(s, '----')
  // console.log(/^(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/])$/.test(s))
  return /^[0-9a-zA-Z]+$/.test(s)
}
/**
 * 整数 和 字母
 * @param {*} s
 */
export function isIntOrLetter(s) {
  return /^[A-Za-z0-9]+$/.test(s)
}
