import request from '@/utils/request'

// 获取我的信息列表
export function getMyLeaseList(data) {
    return request({
      url: '/appApi/jzl/leaseInfo/getMyList',
      method: 'post',
      data: data
    })
}

// 删除我的信息
export function delMyLease(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/delMyLease',
    method: 'post',
    data: data
  })
}

// 上下架我的信息
export function setMyLeaseUpOrDown(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/setMyLeaseUpOrDown',
    method: 'post',
    data: data
  })
}

// 我的-拨打记录
export function getCallList(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/getCallList',
    method: 'post',
    data: data
  })
}

// 我的-收藏记录
export function getCollectList(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/getCollectList',
    method: 'post',
    data: data
  })
}

// 我的-浏览记录
export function getLookList(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/getLookList',
    method: 'post',
    data: data
  })
}

// (取消)收藏
export function collect(data) {
  return request({
    url: '/appApi/jzl/infoDetailPage/collect',
    method: 'post',
    data: data
  })
}

// 获取系统消息
export function userMsgList(data) {
  return request({
    url: '/appApi/jzl/userMsg/userMsgList',
    method: 'post',
    data: data
  })
}

// 预估服务费确认
export function confirmServiceFee(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/confirmServiceFee',
    method: 'post',
    data: data
  })
}

// 获取委托发布管理列表
export function getMyDemandList(data) {
  return request({
    url: '/appApi/jzl/demandInfoPage/demandList',
    method: 'post',
    data: data
  })
}
// 删除发布信息
export function demandRemove(data) {
  return request({
    url: '/appApi/jzl/demandInfoPage/demandRemove',
    method: 'post',
    data: data
  })
}