import request from '@/utils/request'

// 获取系统标识
export function apiGetSysConfig(data) {
  return request({
    url: '/appApi/jzl/common/getSysConfig',
    method: 'post',
    data,
  })
}

// 获取字典类目
export function apiGetDict(data) {
  return request({
    url: '/appApi/jzl/common/getDict',
    method: 'post',
    data,
  })
}

// 物资类型联动
export function apiListCategoryWithSonList(data) {
  return request({
    url: '/appApi/jzl/common/listCategoryWithSonList',
    method: 'post',
    data,
  })
}

// 获取推荐列表
export function apiRecommendList(data) {
  return request({
    url: '/appApi/jzl/homePage/recommendList',
    method: 'post',
    data,
  })
}

// 获取首页banner
export function apiGetBannerList(data) {
  return request({
    url: '/appApi/jzl/common/getBannerList',
    method: 'post',
    data,
  })
}

// 根据字母列表获取城市
export function apiGetCityWithAlpha(data) {
  return request({
    url: '/appApi/jzl/common/getCityWithAlpha',
    method: 'post',
    data,
  })
}

// 搜索物资
export function apiSearchList(data) {
  return request({
    url: '/appApi/jzl/homePage/searchList',
    method: 'post',
    data,
  })
}

// 获取省份
export function apiGetSubArea(data) {
  return request({
    url: '/appApi/jzl/common/getSubArea',
    method: 'post',
    data,
  })
}

// 发布
export function apiPublishInfo(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/publishInfo',
    method: 'post',
    data,
  })
}

// 获取我的发布列表
export function apiGetMyList(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/getMyList',
    method: 'post',
    data,
  })
}

// 获取我的发布详细信息
export function apiGetMyLeaseInfo(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/queryLeaseInfoForModify',
    method: 'post',
    data,
  })
}

// 编辑我的发布详细信息
export function apiEditMyLeaseInfo(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/modifyMyLease',
    method: 'post',
    data,
  })
}
// 快速发布
export function apiDemandRelease(data) {
  return request({
    url: '/appApi/jzl/demandInfoPage/demandRelease',
    method: 'post',
    data,
  })
}
// 快速发布详情
export function apiDemandDetail(data) {
  return request({
    url: '/appApi/jzl/demandInfoPage/demandDetail',
    method: 'post',
    data,
  })
}
// 快速发布编辑
export function apiDemandModify(data) {
  return request({
    url: '/appApi/jzl/demandInfoPage/demandModify',
    method: 'post',
    data,
  })
}
//二级地址联动
export function getAreaList(data) {
  return request({
    url: '/appApi/jzl/common/getAreaList',
    method: 'post',
    data,
  })
}
//身份证ocr

export function ocrIdCard(data) {
  return request({
    url: '/appApi/jzl/common/ocrIdCard',
    method: 'post',
    data,
  })
}

export function orcCompany(data) {
  return request({
    url: '/appApi/jzl/common/orcCompany',
    method: 'post',
    data,
  })
}
//获取实名认证信息
export function apiGetUserAuthInfo(data) {
  return request({
    url: '/appApi/jzl/user/getUserAuth',
    method: 'post',
    data,
  })
}
//提交实名认证
export function apiGetUserAuth(data) {
  return request({
    url: '/appApi/jzl/user/userAuth',
    method: 'post',
    data,
  })
}

//编辑获取实名认证信息
export function apiEditUserAuth(data) {
  return request({
    url: '/appApi/jzl/user/getUserAuth',
    method: 'post',
    data,
  })
}
//获取企业会员广告列表
export function apiGetAdvertList(data) {
  return request({
    url: '/appApi/jzl/advertAndPhone/getAdvertList',
    method: 'post',
    data,
  })
}