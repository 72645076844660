<template>
  <div class="component-container recommend" v-if='advListData.length > 0'>
    <div class="component-content recommend">
      <img src="@/assets/images/home/title-0.png" class="img-title" />
      <div class='advList'>
        <el-carousel class="carousel" :interval="5000" :autoplay="true" :loop="true">
          <el-carousel-item class="el-car-item" v-for="(list, index) in advListData" :key="index">
            <div v-for="(imgList,index1) in list" :key="index1" class="divSrc">
              <div class='advItem' v-if='imgList.advType == 1'>
                <img class='advImage' :src="imgList.advImage" @click="jumpUrlClick(imgList)"/>
              </div>
              <div class='advItem' v-else @click="jumpUrlClick(imgList)">
                <div :class="[index1==0 ? 'top bag1' : index1==1 ? 'top bag2' : 'top bag3']">
                  <img src="@/assets/images/home/tel.png" />
                  <div class='topInfo'>
                    <span class='tel'>{{imgList.showContactPhone ? imgList.showContactPhone : ''}}</span>
                    <span class='name'>/ {{imgList.showContactName ? imgList.showContactName : ''}}</span>
                  </div>
                </div>
                <div class='bottom'>
                  <img src="@/assets/images/home/advIcon.png" />
                  <div class='bottomInfo'>
                    <div class='companyName'>{{imgList.advertTitle ? imgList.advertTitle : ''}}</div>
                    <div class='desc'>{{imgList.advertContent ? imgList.advertContent : ''}}</div>
                  </div>
                </div>
                <img src='@/assets/images/home/advVip.png' alt='' class='advVip'>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetAdvertList, apiGetSysConfig } from '@/api'

export default {
  name: 'HomeAdv',
  data() {
    return {
      advListData:[],
      defaultAdvItem:{
        "advertTitle": "广告位招商",
        "advertContent": "共享资源，共谋发展，让物资循环使用更加便捷。",
        "showContactName": "客服经理",
        "showContactPhone": "400-658-1699",
      }
    }
  },
  mounted(){
    this.advListFun();
    // 获取顶部电话
    apiGetSysConfig({ code: 'jzl_customer_service_phone' }).then((res) => {
      this.defaultAdvItem.showContactPhone = res.data;
    })
  },
  methods:{
    advListFun(){
      let newDataList = [];
      let current = 0
      apiGetAdvertList({  }).then((res) => {
        const  advertList = res.data
        if (advertList && advertList.length > 0) {
          // 判断数组长度是否是3的倍数
          if (advertList.length % 3 !== 0) {
            // 计算需要补全的数量
            var remainder = advertList.length % 3;
            var toAdd = 3 - remainder;
            // 补全数组
            for (var i = 0; i < toAdd; i++) {
              advertList.push(this.defaultAdvItem); // 在这里补全数组，可以根据实际需求改变补全的内容
            }
          }
          for (let i = 0; i <= advertList.length - 1; i++) {
            if (i % 3 !== 0 || i === 0) {   //数据处理成几张展示
              if (!newDataList[current]) {
                newDataList.push([advertList[i]])
              } else {
                newDataList[current].push(advertList[i])
              }
            } else {
              current++
              newDataList.push([advertList[i]])
            }
          }
        }
        this.advListData = [...newDataList]
      })
    },
    // 跳转方法
    jumpUrlClick(item){
      console.log(item)
      if(item.jumpUrl){
        window.open(item.jumpUrl, '_blank');
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.component-container.recommend {
  margin-top: 20px;
  .component-content.recommend {
    width: $contentWidth;
  }
}
.img-title {
  width: 163px;
}
.advList{
  margin-top: 10px;
  display: flex;
  width: 100%;
  ::v-deep .el-carousel{
    width: 100%;
    .el-carousel__container{
      width: 100%;
      height: 154px;
      .el-carousel__item{
        display: flex;
      }
    }
  }
  .advItem{
    cursor: pointer;
    .advImage{
      width: 100%;
      height: 100%;
    }
    &:first-child, &:nth-child(2){
      margin-right: 10px;
    }
    width: 395px;
    height: 154px;
    background: #FFFFFF;
    position: relative;
    .top{
      background: url('@/assets/images/home/adv-title1.png') no-repeat center 100%;
      background-size: 100%;
      padding: 14px 20px;
      display: flex;
      align-items: center;
      height: 55px;
      img{
        width: 15px;
        height: 15px;
        margin-right: 8px;
      }
      .topInfo{
        color: #FFDBBF;
        display: flex;
        align-items: center;
        .tel{
          font-size: 20px;
          font-weight: bold;
          margin-right: 6px;
        }
        .name{
          font-size: 14px;
        }
      }
    }
    .bag1{
      background: url('@/assets/images/home/adv-title1.png') no-repeat center 100%;
    }
    .bag2{
      background: url('@/assets/images/home/adv-title2.png') no-repeat center 100%;
    }
    .bag3{
      background: url('@/assets/images/home/adv-title3.png') no-repeat center 100%;
    }
    .bottom{
      display: flex;
      padding: 10px 20px 17px;
      img{
        width: 15px;
        height: 15px;
        margin-right: 8px;
        margin-top: 2px;
      }
      .bottomInfo{
        .companyName{
          font-size: 14px;
          color: #333333;
          margin-bottom: 5px;
        }
        .desc{
          font-size: 12px;
          color: #999999;
          line-height: 16px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* 设置最大显示行数 */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
    }
    .advVip{
      width: 68px;
      height: 57px;
      position: absolute;
      right: 36px;
      top: 20px;
    }
  }
}
</style>
