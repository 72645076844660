import request from '@/utils/request'

// 获取系统标识
export function getSysConfig(data) {
    return request({
      url: '/appApi/jzl/common/getSysConfig',
      method: 'post',
      data: data
    })
}

// 获取上传又拍云的token
export function getUpyunToken(data) {
  return request({
    url: '/appApi/upload/getUpyunToken',
    method: 'post',
    data: data
  })
}

// 获取小程序码
export function getMiniCode(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/getMiniCode',
    method: 'post',
    data: data
  })
}

// 获取验证码
export function sendSMS(data) {
  return request({
    url: '/appApi/jzl/loginPage/sendSMS',
    method: 'post',
    data: data
  })
}
// 拨打电话
export function getUserDoCall(data) {
  return request({
    url: '/appApi/jzl/userCall/doCall',
    method: 'post',
    data: data
  })
}
