<template>
  <div class="component-container">
    <div class="component-content">
      <div class="list">
        <div class="item" v-for="(item, index) in navList" :class="{ active: navActive === index }" :key="item.value" @click="handleNavClick(index)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { JupaiUrl, NavList } from '@/constant/index'

export default {
  name: 'HomeNav',
  components: {},
  computed: {
    ...mapState({
      navActive: (state) => state.home.navActive,
    }),
  },
  data() {
    return {
      navList: NavList,
    }
  },
  watch: {
    '$route.path': {
      handler: function (newVal, oldVal) {
        let index = this.navList.findIndex((item) => {
          return item.value === newVal.split('/')[2]
        })
        if (index === -1) {
          index = 0
        }
        if(newVal == '/storage'){
          index = 6
        }
        this.setNavActive(index)
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    ...mapMutations('home', ['setNavActive']),
    handleNavClick(index) {
      if (index === 6){
        this.$router.push('/storage')
        return
      }
      if (index === 7) {
        window.open(JupaiUrl)
        return
      }
      this.$router.push('/home/' + this.navList[index].value)
      this.setNavActive(index)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.component-container {
  width: auto;
}
.list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .item {
    margin-right: 60px;
    line-height: 30px;
    cursor: pointer;
    font-size: $primaryTextSize;
    &:hover {
      color: $importantColor;
    }
    &.active {
      color: $importantColor;
    }
  }
}
</style>
