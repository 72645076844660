<template>
  <div class="component-container">
    <div class="component-content">
      <img src="@/assets/images/home/success.png" class="img" />
      <div class="title" v-if='!!userInfo.authenticationStatus'>{{ type }}信息提交成功，审核通过后系统会自动上架物资信息！</div>
      <div class="title" v-else>{{ type }}信息提交成功，您实名认证通过后系统会自动上架物资信息！</div>
      <div class="desc">物资信息会在1个工作日内完成审核，请耐心等待哦~</div>
      <div class="button-box">
        <div class="button primary-button disable" @click="$emit('home')">返回首页</div>
        <div class="button primary-button" v-if='!!userInfo.authenticationStatus' @click="$emit('goon')">继续发布</div>
        <div class="button primary-button" v-else @click="$router.push({name:'accountManage', params: { changeSelect: 2}})">去认证</div>
      </div>
      <FormTips/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FormTips  from './FormTips.vue'
export default {
  name: 'FormStep3',
  components: {FormTips},
  props: {
    type: String,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('user',['userInfo']),
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.component-content {
  width: 740px;
  border-top: 1px dashed #d6d6d6;
}
.img {
  width: 142px;
  margin-top: 84px;
}
.title {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}
.desc {
  margin-top: 10px;
  color: $secondaryTextColor;
}
.button-box {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .button {
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
