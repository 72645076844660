// 聚拍网链接
export const JupaiUrl = 'https://www.jupai.net/?source=juxunhuan'

// 菜单
export const NavList = [
  { name: '首页', value: 'home' },
  { name: '出租', value: 'hire' },
  { name: '出售', value: 'sell' },
  { name: '求租', value: 'seekingrent' },
  { name: '求购', value: 'buy' },
  { name: '置换', value: 'exchange' },
  { name: '仓储', value: 'storage' },
  { name: '竞拍', value: 'auction' },
]
