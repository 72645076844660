const state = () => {
  return {
    vipShowFlag:{},
    setVipShowFlag:false,
    errorInfo: {},
  }
}
const mutations = {
  setGoodsInfo(state,index){
    state.detailInfo = index
  },
  setGoodsInfoReset(state,index){
    state.detailInfo = {}
    state.vipShowFlag = false
    state.errorInfo = {}
  },
  setVipShowFlag(state,index){
    state.vipShowFlag = index
  },
  setErrorInfo(state,index){
    state.errorInfo = index
  },
}
export default{
  namespaced: true,
  state,
  mutations
}