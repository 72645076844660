<template>
  <div class="page-container">
    <personal-infro-top :iSelect="2" />
    <div class="page-content">
      <title-left :options="m_options" @sendChangeSelect="ChangeSelect" :is-select.sync='changeSelect'/>
      <div class="page-right">
        <div v-if="changeSelect == 0" class="item-module-goods"></div>

        <div style="padding-bottom: 10px;">
          <div class="item-content" @click="goUrl(item.infoType,item.infoNo)" v-for="(item, index) in userMesData" :key="index + 'w'">
            <div class="item-mess-status">
              <p class="item-mess-status-title">{{ handleMesType(item.msgType) }}</p>
              <p class="item-mess-status-icon">{{ item.msgTypeName }}</p>
              <p class="item-mess-status-time">{{ item.addTime }}</p>
            </div>
            <p class="item-mess-info">{{ item.content }}</p>
          </div>
        </div>
        <div v-show="userMesData.length == 0">
          <el-empty :image-size="200"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
import { mapState } from 'vuex'
import PersonalInfroTop from '@/views/personalcenter/components/PersonalInfoTop.vue'
import TitleLeft from '@/views/personalcenter/components/TitleLeft.vue'
import { userMsgList } from '@/api/personal'
export default {
  name: 'MessageIndex',
  components: { PersonalInfroTop, TitleLeft },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      navActive: (state) => state.home.navActive,
    }),
  },
  data() {
    return {
      m_options: [
        {
          title: '消息中心',
          content: [
            {
              index: 0,
              name: '系统消息',
            },
          ],
        },
      ],
      changeSelect: 0,
      userMesData: [],
    }
  },
  created() {
    this.getUserMsgList()
  },
  methods: {
    ChangeSelect(v) {
      this.changeSelect = v
      console.log('----titleLeft传值-----' + this.changeSelect)
      this.getUserMsgList()
    },
    handleMesType(mesType) {
      //（1-审核通知，2-下架通知，3-收藏上新，4-浏览上新）
      switch (mesType) {
        case 1:
          return '审核通知'
        case 2:
          return '下架通知'
        case 3:
          return '收藏上新'
        case 4:
          return '浏览上新'
        default:
          return '认证通知'
      }
    },
    goUrl(infoType,infoNo){
      if(infoType == 5){
        this.$router.push({name:'accountManage', params: { changeSelect: 2}});
      }else{
        var handleCode = 'edc66a6a8601378d'
        this.$router.push({
          path: '/product/detail',
          query: {
            infoNo: infoNo,
            subheads: this.subheads || '',
            handleCode: handleCode,
          },
        })
      }
    },
    handleNavClick(index) {
      this.selTitleIndex = index
    },
    getUserMsgList() {
      userMsgList({
        userUuid: this.userInfo.userUuid,
      }).then((res) => {
        var data = res
        if (data.success) {
          console.log(data)
          this.userMesData = data.data
        } else {
          this.$message(data.msg)
        }
      })
    },
    handleInforType(infoType) {
      return '测试-切换'
    },
  },
}
</script>
      
      <style lang="scss" scoped>
@import '@/styles/variables';

.page-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #f4f4f4;
  .page-content {
    width: $contentWidth;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    margin-top: 10px;
  }
}
.page-right {
  background: white;
  margin-left: 10px;
  margin-bottom: 10px;
  min-height: 875px;
  flex: 1;
}

.item-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: #f8f8f8;
  padding: 15px 20px;
  margin: 20px 20px 0 20px;
  border-left-color: #e1251b;
  border-left-width: 5px;
  border-left-style: solid;
  cursor: pointer;
  .item-mess-status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .item-mess-status-title {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
    }

    .item-mess-status-time {
      flex: 1;
      text-align: end;
      font-size: 14px;
      color: #bbbbbb;
    }
  }
  .item-mess-info {
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
  }
}
.item-mess-status-icon {
  margin-left: 8px;
  background: #e1251b;
  padding: 2px 5px;
  font-size: 12px;
  color: white;
  border-radius: 2px;
}
.item-content-list {
  height: 59px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-left: 57px;
  .item-content-list-text {
    color: #333333;
    margin-right: 73px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: $importantColor;
    }
    &.active {
      color: $importantColor;
    }
  }
}
</style>
      