<template>
  <div class="page-container">
    <Header @home="toHome" />
    <span style="width: 100%; background: #f5f5f5; height: 10px" />
    <div class="page-content">
      <div class="breadcrumb-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="goToMinHead">{{ minheads }}</el-breadcrumb-item>
          <el-breadcrumb-item v-show="subheads">{{ subheads }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ noBadgeTitle }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!--图片+标题内容-->
      <div class="page-headline-box">
        <div class="page-headline-img-box">
          <!--商品放大图效果-->
          <div class="page-headline-img-small" ref="small" @mousemove="mousemove" @mouseover="handleMouseOver" @mouseout="handleMouseOut">
            <img fit="fill" :src="changeImageURL" referrer="no-referrer|origin|unsafe-url" referrerPolicy="no-referrer" style="border-width: 1px; border-style: solid; border-color: #dddddd; object-fit: contain" />
            <div class="page-headline-img-box-mask" :style="{ left: left + 'px', top: top + 'px' }" ref="mask" v-show="isMouseEvent"></div>
          </div>
          <div class="page-headline-img-scroll-box-s">
            <img src="@/assets/images/detail/icon_detail_scroll_left.png" v-show="isScroll" @click="leftSlide" class="page-headline-img-box-lr" />
            <div class="page-headline-img-scroll-box" v-dragscroll ref="wrapperCon">
              <img fit="fill" :src="item" v-for="(item, index) in pictureUrlList" :key="index" referrer="no-referrer|origin|unsafe-url" referrerPolicy="no-referrer" @click="handleChangImg(index)" />
            </div>
            <img src="@/assets/images/detail/icon_detail_scroll_right.png" v-show="pictureUrlList.length > 4" @click="rightSlide" class="page-headline-img-box-lr" />
          </div>
        </div>
        <div style="position: relative">
          <div class="page-headline-title-box">
            <div class="page-headline-title-box-tips">
              <!-- <p class="page-headline-title-box-tips1">{{ badge.join('') }}</p> -->
              <p class="page-headline-title-box-tips2">{{ noBadgeTitle }}</p>
            </div>
            <p class="page-headline-title-box-infono">编号：{{ detailData.infoNo }}</p>
            <div :class="detailData.infoStatus != undefined && detailData.infoStatus === 2 ? '' : 'page-headlie-title-box-lucency'">
              <div class="page-headline-title-box-select-type">
                <div class="page-headline-title-box-st-child" v-for="item in priceTextSetSelect" :key="item.type">
                  <p class="page-headline-title-box-st-child1" v-if="item.type">{{ item.type }}：</p>
                  <p class="page-headline-title-box-st-child2">
                    ￥ <i style="font-size: 23px; font-weight: bold">{{ item.price }}</i> {{ item.unit }}
                  </p>
                </div>
              </div>
              <div class='page-headline-title-box-specifications'>
                 <span class='title'>规格：</span>
                  <div class='specificationsItem'>
                    <span v-for='(item,index) in detailData.jzlLeaseInfoSpecVoList' :key='item.id' :class="{'selected': selectedIndex === index}" class='ellipsis' @click='chooseSpecifica(index)'>{{item.specName}}</span>
                  </div>
              </div>
              <div class='page-headline-title-box-weight'>
                <span class='title'>总量：</span>
                <span class='weightNum'>共{{qtyNumUnit}}</span>
              </div>
<!--              <div class="page-headline-title-box-material-type">-->
<!--                <div class="page-headline-title-box-material-child-type"><img src="@/assets/images/detail/icon_materials_classification.png" style="width: 14px; height: 14px; margin-right: 10px" />物资分类：{{ detailData.materialTypeDesc }}</div>-->
<!--                <div class="page-headline-title-box-material-child-type"><img src="@/assets/images/detail/icon_material_name.png" style="width: 14px; height: 14px; margin-right: 10px" />物资名称：{{ detailData.materialName }}</div>-->
<!--              </div>-->
<!--              <div class="page-headline-title-box-material-type">-->
<!--                <div class="page-headline-title-box-material-child-type"><img src="@/assets/images/detail/icon_specifications_models.png" style="width: 14px; height: 14px; margin-right: 10px" />规格型号：{{ detailData.specName }}</div>-->
<!--                <div class="page-headline-title-box-material-child-type"><img src="@/assets/images/detail/icon_materials_number.png" style="width: 14px; height: 14px; margin-right: 10px" />物资数量：{{ detailData.qty + detailData.qtyUnit }}</div>-->
<!--              </div>-->

              <div class="page-headline-title-box-material-type">
                <div class="page-headline-title-box-material-child-location"><img src="@/assets/images/public/icon-position-gray.png" style="width: 11px; height: 14px; margin-right: 7px" />{{ detailData.provName + detailData.cityName }} 距离{{ detailData.geoDistance }}</div>
                <div class="page-headline-title-box-material-child-collect" :style="{ color: detailData.isCollect ? '#E1251B' : '#BBBBBB' }" v-on:click="handleCollect(detailData.isCollect)" v-show="isMyRelease">
                  <img :src="detailData.isCollect ? imgSrcAlreadyColl : imgSrcColl" style="width: 14px; height: 14px; margin-right: 2px" />收藏
                </div>
              </div>

              <div class="page-headline-title-box-material-type">
                <p class="page-headline-title-box-material-child-button1" @click="handlePublish(releaseTypes)">{{ wLeftButton }}</p>
                <p class="page-headline-title-box-material-child-button2" @click="doCall()">{{ wRightButton }}</p>
              </div>
            </div>
          </div>
          <div class="page-headline-img-big" ref="big" v-show="isMouseEvent">
            <img ref="bigimg" fit="fill" :src="changeImageURL" referrer="no-referrer|origin|unsafe-url" referrerPolicy="no-referrer" :style="{ left: imgX + 'px', top: imgY + 'px' }" style="object-fit: scale-down" />
          </div>
          <div class="page-headline-title-box-status" v-show="detailData.infoStatus != undefined && detailData.infoStatus !== 2">
            <img :src="handleInfoStatus(detailData.infoStatus)" class="page-headline-title-box-soldout-status" />
          </div>
        </div>
      </div>

      <div class="page-particulars-box">
        <div class="page-particulars-left-box">
          <img src="@/assets/images/detail/icon_detail_related_recommend.png" />
          <div class="list-content" v-if="allCategoryList.length > 0" style="margin-bottom: 10px">
            <goods-item class="goods-item" v-for="item in allCategoryList" :data="item" :key="item.infoNo" style="background: #f5f5f5; margin-top: 10px" @set-vip-show="setVipShow" @refresh="handleRefresh" />
          </div>
        </div>
        <div class="page-particulars-right-box">
          <!-- <el-tabs v-model="activeName" @tab-click="handleClick" style="background: #f5f5f5; padding-left: 32px">
            <el-tab-pane label="物资图片" name="first"></el-tab-pane>
            <el-tab-pane label="物资说明" name="second"></el-tab-pane>
          </el-tabs> -->
          <div class="page-particulars-right-titles">
            <div @click="handleChangeTitle('first')" class="page-particulars-right-titles-child">
              <p class="page-particulars-right-titles-p1" :class="{ active: activeName === 'first' }">物资图片</p>
              <p v-show="activeName === 'first'" class="page-particulars-right-titles-line"></p>
            </div>
            <div @click="handleChangeTitle('second')" style="margin-left: 80px" class="page-particulars-right-titles-child">
              <p class="page-particulars-right-titles-p1" :class="{ active: activeName === 'second' }">物资说明</p>
              <p v-show="activeName === 'second'" class="page-particulars-right-titles-line"></p>
            </div>
          </div>
          <ul id="scroll_parent">
            <li id="scroll_0" style="margin-top: 10px">
              <div v-for="(item, index) in defaultPictureUrlList" :key="'defaultUrl'+index">
                <img :src="item" style="height: 400px; max-width: 80%" />
              </div>
              <div v-for="(item, index) in specPictureUrlList" :key="'specUrl'+index">
                <span class='specBag'>规格：{{item.name}}</span>
                <div v-for="(items, indexs) in item.urlList" :key="indexs">
                  <img :src="items" style="height: 400px; max-width: 80%" />
                </div>
              </div>
            </li>
            <li id="scroll_1">
              <p class="page-particulars-right-description-box">物资说明</p>
              <p class="page-particulars-right-description-cont-box">{{ detailData.leaseDesc }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div style="height: 10px; background: #f5f5f5; width: 100%"></div>
    <VIP :isShowDialog="showDialog" :showImg="0" @set-show-dialog="setShowDialog" :isShowFlipDialog="showDialog" />

<!--    <el-dialog :visible.sync="rDialogVisible1" center class="whz-el-dialog1">-->
<!--      <div class="dialog-content">-->
<!--        <img :src="miniCodeUrl" class="dialog-content-mini" />-->
<!--        <p class="dialog-content-title">通过微信扫一扫</p>-->
<!--        <p class="dialog-content-info">扫描二维码可通过聚循环小程序免费拔打电话咨询</p>-->
<!--      </div>-->

<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button type="primary" @click="rDialogVisible1 = false">确 定</el-button>-->
<!--      </div>-->
<!--    </el-dialog>-->
    <el-dialog :visible.sync="rDialogVisible1" center class="whz-el-dialog1">
      <div class="dialog-content">
        <p class="dialog-content-title">客服电话</p>
        <img src="@/assets/images/home/telIcon.png" class='telIcon'/>
        <p class="dialog-content-info">{{customerService}}</p>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="rDialogVisible2" center class="whz-el-dialog2">
      <div class="dialog-content">
        <p class="dialog-content-mini_p">{{ wLookCallTitle }}</p>

        <div v-infinite-scroll="loadDialog" infinite-scroll-disabled="disabled" style="overflow: auto" class="dialog-content-scroll" v-if="wLookCallList && wLookCallList.length > 0">
          <div v-for="(item, index) in wLookCallList" :key="index" class="dialog-content-scroll-item">
            <div class="dialog-content-scroll-item-left">
              <img src="@/assets/images/detail/icon_browsing_history_head.png" class="dialog-content-scroll-item-img" />
              <p class="dialog-content-scroll-item-p1">{{ item.phoneHide }}</p>
            </div>
            <p class="dialog-content-scroll-item-p2">{{ item.updateTime }}</p>
            <!-- <img src="@/assets/images/detail/icon_browsing_history_call.png" class="dialog-content-scroll-item-img2" />
            <p class="dialog-content-scroll-item-p3">打电话</p> -->
          </div>
        </div>
        <el-empty v-else :image-size="200"></el-empty>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Header from '@/components/public/Header.vue'
import GoodsItem from '@/views/home/components/GoodsItem.vue'
import VIP from '@/views/home/components/VIP.vue'
import { recommendList, listToDetail, collectGoods, getLookListByInfoNo, getCallListByInfoNo } from '@/api/detail.js'
import { getMiniCode, getUserDoCall } from '@/api/common'
export default {
  name: 'ProductDetailIndex',
  components: { Header, GoodsItem, VIP },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      userPosition: (state) => state.user.userPosition,
      detailInfo: (state) => state.detail.detailInfo,
    }),
    badge() {
      if (!this.detailData || !this.detailData.title) {
        return []
      }
      const typeString = this.detailData.title.split('|')[0]
      const typeList = []
      for (let i = 0; i < typeString.length; i += 2) {
        if (typeString.slice(i, i + 2)) {
          typeList.push(typeString.slice(i, i + 2))
        }
      }
      return typeList
    },
    noBadgeTitle() {
      if (!this.detailData || !this.detailData.title) {
        return ''
      }
      return this.detailData.title

      // return this.detailData.title.split('|')[1]
    },
  },
  data() {
    return {
      rDialogVisible1: false,
      miniCodeUrl: 'https://file.jupai.net/2023/11/13/QZ20230927152153ce43ccbc7f644b.png',
      activeName: 'first',
      isScroll: false,
      infoNo: '',
      specId: '',//跳转需要选中的规格id
      minheads: '',
      goToMinHead: { path: '/' },
      subheads: '',
      handleCode: '', // 浏览记录、拨打记录 edc66a6a8601378d
      allCategoryList: [],
      detailData: {
        title: '',
        priceDescSet: [],
        firstPictureUrl: '',
        isCollect: false,
        priceTextSet: [],
      },
      pictureUrlList: [],
      imgSrcAlreadyColl: require('../../assets/images/detail/icon_detail_already_collect.png'),
      imgSrcColl: require('../../assets/images/detail/icon_detail_collect.png'),
      changeImageURL: '',
      rDialogVisible2: false,
      wLookCallList: [],
      wLookCallTitle: '浏览记录',
      wLookCallPage: 1,
      wLeftButton: '发布出租',
      wRightButton: '电话咨询',
      releaseTypes: 1,
      top: '',
      left: '',
      imgX: '',
      imgY: '',
      isMouseEvent: false,
      isMyRelease: true,
      showDialog: false,
      qtyNumUnit:'',//总量
      selectedIndex: 0,// 默认选中第一项
      priceTextSet:[],//价格数组
      priceTextSetSelect:[],//显示的价格
      customerService:null,//客服电话
      specPictureUrlList: [],// 规格图片
      defaultPictureUrlList: [],//默认图片
    }
  },
  watch:{
    userInfo: {
      handler(newValue, oldValue) {
        if(newValue){
          this.setGoodsInfoReset();
        }
        // 在这里处理状态值变化的逻辑
      },
    },
    userPosition:{
      handler(newValue, oldValue) {
        this.handleRefresh()
      },
      deep:true,
      immediate:true
    }
  },
  mounted() {
    // console.log('mounted的数据')
    // console.log("王怀智" + this.detailData.infoStatus)
    // this.handleRefresh()
  },
  methods: {
    ...mapMutations('detail',['setGoodsInfoReset']),
    // 获取推荐列表
    getRecommendList() {
      recommendList({
        infoNo: this.infoNo,
        lon: this.userPosition.location.lng,
        lat: this.userPosition.location.lat,
        size: 5,
      }).then((res) => {
        var data = res
        if (data.success) {
          this.allCategoryList = data.data
        } else {
          this.$message(data.msg)
        }
      })
    },
    // 获取详情
    getListToDetail() {
      listToDetail({
        infoNo: this.infoNo,
        // userUuid: this.userInfo.userUuid,
        lon: this.userPosition.location.lng,
        lat: this.userPosition.location.lat,
      }).then((res) => {
        var data = res
        if (data.success) {
          this.detailData = data.data
          let pictureUrl = this.detailData.pictureUrl
          this.pictureUrlList = pictureUrl.split(',')
          this.defaultPictureUrlList = pictureUrl.split(',')
          this.changeImageURL = this.pictureUrlList[0]
          // 数量初始值（显示第一种规格的值）
          if(this.detailData.jzlLeaseInfoSpecVoList.length > 0){
            let defaultSpecVo = {};
            if(this.specId){
              defaultSpecVo =  this.detailData.jzlLeaseInfoSpecVoList.find((ele)=>{
                return ele.id == this.specId
              });
              this.selectedIndex = this.detailData.jzlLeaseInfoSpecVoList.findIndex((item)=>item.id == this.specId);
            }else{
              defaultSpecVo = this.detailData.jzlLeaseInfoSpecVoList[0]
              this.selectedIndex = 0;
            }
            if(defaultSpecVo){
              this.qtyNumUnit = defaultSpecVo.qty + defaultSpecVo.qtyUnit;
              let pictureUrl = '';
              if(defaultSpecVo.specPictureUrl){
                pictureUrl = defaultSpecVo.specPictureUrl
              } else{
                pictureUrl = this.detailData.pictureUrl
              }
              this.pictureUrlList = pictureUrl.split(',')
              this.changeImageURL = this.pictureUrlList[0];
              this.detailData.jzlLeaseInfoSpecVoList.forEach((item, index) => {
                // 检查对象中是否包含出租和出售的信息
                this.priceTextSet.push(item.priceTextSet);
                let pictureUrl = item.specPictureUrl
                if(pictureUrl){
                  this.specPictureUrlList.push({
                    name: item.specName,
                    urlList: pictureUrl.split(',')
                  })
                }
              });
            }

            this.priceTextSetSelect = this.priceTextSet[this.selectedIndex];
          }
          if (!this.handleCode) {
            // 1 出租 ；2 出售；3 求租；4 求购；5 置换
            this.releaseTypes = data.data.releaseType
            switch (data.data.releaseType) {
              // 1 出租 ；2 出售；3 求租；4 求购；5 置换
              case 1:
                this.wLeftButton = '发布求租'
                this.releaseTypes = 3
                break
              case 2:
                this.wLeftButton = '发布求购'
                this.releaseTypes = 4
                break
              case 3:
                this.wLeftButton = '发布出租'
                this.releaseTypes = 1
                break
              case 4:
                this.wLeftButton = '发布出售'
                this.releaseTypes = 2
                break
            }
          }
        } else if (res.code == 'A1006') {
          // this.$confirm('领取会员后才能查看详情, 是否去领取会员?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // }).then(() => {
          //   this.showDialog = true
          // }).catch(() => {});
          this.showDialog = true
        } else {
          this.$message(data.msg)
        }
      })
    },
    // 选择规格
    chooseSpecifica(index){
      this.selectedIndex = index;
      this.qtyNumUnit = this.detailData.jzlLeaseInfoSpecVoList[index].qty + this.detailData.jzlLeaseInfoSpecVoList[index].qtyUnit;
      this.priceTextSetSelect = this.priceTextSet[index];
      let pictureUrl = '';
      if(this.detailData.jzlLeaseInfoSpecVoList[index].specPictureUrl){
        pictureUrl = this.detailData.jzlLeaseInfoSpecVoList[index].specPictureUrl
      }else{
        pictureUrl = this.detailData.pictureUrl
      }
      this.pictureUrlList = pictureUrl.split(',')
      this.changeImageURL = this.pictureUrlList[0];
    },
    handleClick() {
      // 滚动至固定位置
      let scroll0 = document.getElementById('scroll_0')
      let scroll1 = document.getElementById('scroll_1')
      let parent = document.getElementById('scroll_parent')
      console.log(scroll0.offsetTop + '----' + parent.offsetTop + '---' + scroll0.offsetTop)
      switch (this.activeName) {
        case 'first':
          document.documentElement.scrollTop = scroll0.offsetTop
          break
        case 'second':
          // document.documentElement.scrollTop = scroll1.offsetTop - parent.offsetTop
          document.documentElement.scrollTop = scroll1.offsetTop
          break
      }
    },
    handleRefresh() {
      document.documentElement.scrollTop = 0
      // 刷新操作
      this.infoNo = this.$route.query.infoNo
      this.specId = this.$route.query.specId
      this.minheads = this.$route.query.minheads || ''
      this.subheads = this.$route.query.subheads || ''
      this.handleCode = this.$route.query.handleCode || ''
      this.getListToDetail()
      this.getRecommendList()

      if (this.handleCode) {
        // 从个人中心的发布管理进入的
        this.isMyRelease = false
        // console.log(this.infoNo)
        this.wLeftButton = '浏览记录'
        this.wRightButton = '拨打记录'
      } else {
        this.isMyRelease = true
        this.rDialogVisible2 = false
        this.wLeftButton = '发布出租'
        this.wRightButton = '电话咨询'
      }
      if (this.minheads === 'home') {
        this.minheads = '首页'
        this.goToMinHead = { path: '/' }
      } else {
        this.minheads = '个人中心'
        this.goToMinHead = { path: '/personal/home' }
      }
    },
    handleCollect(isCollect) {
      collectGoods({
        infoNo: this.infoNo,
        operationType: isCollect ? -1 : 1,
      }).then((res) => {
        var data = res
        console.log(data, '--')
        if (data.success) {
          this.detailData.isCollect = !isCollect
          this.$message.warning(data.data)
        } else {
          this.$message(data.msg)
        }
      })
    },
    doCall() {
      if (this.handleCode) {
        this.wLookCallPage = 1
        this.wLookCallList = []
        this.wLookCallTitle = '拨打记录'
        this.rDialogVisible2 = true
        // 拨打记录
        this.getCallListByInfoNo()
      } else {
        var params = {
          infoNo: this.infoNo,
        }
        // getMiniCode(params).then((res) => {
        //   if (res.success) {
        //     this.miniCodeUrl = res.data.miniCodeUrl
        //     this.rDialogVisible1 = true
        //   } else {
        //     this.$message(res.msg)
        //   }
        // })
        getUserDoCall(params).then((res) => {
          if (res.success) {
              this.customerService = res.data.phoneNoX;
              this.rDialogVisible1 = true;
          } else {
            this.$message(res.msg)
          }
        })
      }
    },
    // 发布
    handlePublish(index) {
      if (this.handleCode) {
        this.wLookCallPage = 1
        this.wLookCallList = []
        this.wLookCallTitle = '浏览记录'
        this.rDialogVisible2 = true
        // 浏览记录-拨打记录
        this.getLookListByInfoNo()
      } else {
        // 1：出租，2：出售，3：求租，4：求购
        if (this.userInfo.userUuid) {
          this.$router.push({ path: `/publish/${index}` })
        } else {
          this.$router.push('/login')
        }
      }
    },
    handleChangImg(index) {
      // 放大图片
      console.log(index)
      this.changeImageURL = this.pictureUrlList[index]
    },

    // 左箭头
    leftSlide() {
      // 保存滚动盒子左侧已滚动的距离
      let left = this.$refs.wrapperCon.scrollLeft
      let num = 0

      //   !left:已经滚动到最左侧
      //   一次性滚动距离（可调节）
      if (!left || num >= 300) {
        // 停止滚动
        clearInterval(this.timer)
        this.timer = null
        return
      }
      // 给滚动盒子元素赋值向左滚动距离
      this.$refs.wrapperCon.scrollLeft = left -= 30
      // 保存向左滚动距离（方便判断一次性滚动多少距离）
      num += 30

      // clearInterval(this.timer)
      // this.timer = null
      // this.timer = setInterval(() => {
      //   //   !left:已经滚动到最左侧
      //   //   一次性滚动距离（可调节）
      //   if (!left || num >= 300) {
      //     // 停止滚动
      //     clearInterval(this.timer)
      //     this.timer = null
      //     return
      //   }
      //   // 给滚动盒子元素赋值向左滚动距离
      //   this.$refs.wrapperCon.scrollLeft = left -= 30
      //   // 保存向左滚动距离（方便判断一次性滚动多少距离）
      //   num += 30
      // }, 20)
      // // 20：速度（可调节）
    },
    // 右箭头
    rightSlide() {
      this.isScroll = true
      // 保存滚动盒子左侧已滚动的距离
      let left = this.$refs.wrapperCon.scrollLeft
      // 保存元素的整体宽度
      let scrollWidth = this.$refs.wrapperCon.scrollWidth
      // 保存元素的可见宽度
      let clientWidth = this.$refs.wrapperCon.clientWidth
      let num = 0

      // 已经滚动距离+可见宽度
      // left+clientWidth>=scrollWidth：滚动到最右侧
      // num>=300一次性滚动距离
      if (left + clientWidth >= scrollWidth || num >= 300) {
        clearInterval(this.timer)
        return
      }
      // 给滚动盒子元素赋值向左滚动距离
      this.$refs.wrapperCon.scrollLeft = left += 30
      // 保存向左滚动距离（方便判断一次性滚动多少距离）
      num += 30

      // clearInterval(this.timer)
      // this.timer = setInterval(() => {
      //   // 已经滚动距离+可见宽度
      //   // left+clientWidth>=scrollWidth：滚动到最右侧
      //   // num>=300一次性滚动距离
      //   if (left + clientWidth >= scrollWidth || num >= 300) {
      //     clearInterval(this.timer)
      //     return
      //   }
      //   // 给滚动盒子元素赋值向左滚动距离
      //   this.$refs.wrapperCon.scrollLeft = left += 30
      //   // 保存向左滚动距离（方便判断一次性滚动多少距离）
      //   num += 30
      // }, 20)
      // // 20：速度（可调节）
    },
    getLookListByInfoNo() {
      // 获取浏览记录
      let params = {
        userUuid: this.userInfo.userUuid,
        infoNo: this.infoNo,
        pageNo: this.wLookCallPage,
        pageSize: 10,
      }
      console.log(params)
      getLookListByInfoNo(params).then((res) => {
        var data = res
        if (data.success) {
          if (this.wLookCallPage == 1) {
            this.wLookCallList = data.data
          } else {
            if (data.data) {
              this.wLookCallList.push(data.data)
            }
          }
        } else {
          this.$message(data.msg)
        }
      })
    },
    getCallListByInfoNo() {
      // 获取拨打记录
      let params = {
        userUuid: this.userInfo.userUuid,
        infoNo: this.infoNo,
        pageNo: this.wLookCallPage,
        pageSize: 10,
      }
      console.log(params)
      getCallListByInfoNo(params).then((res) => {
        var data = res
        if (data.success) {
          if (this.wLookCallPage == 1) {
            this.wLookCallList = data.data
          } else {
            if (data.data) {
              this.wLookCallList.push(data.data)
            }
          }
        } else {
          this.$message(data.msg)
        }
      })
    },
    loadDialog() {
      // dialog上拉加载更多
      this.wLookCallPage = this.wLookCallPage + 1
      if (this.wLookCallTitle === '浏览记录') {
        this.getLookListByInfoNo()
      } else {
        this.getCallListByInfoNo()
      }
    },
    mousemove(e) {
      let small = this.$refs.small
      let mask = this.$refs.mask
      let big = this.$refs.big
      let bigimg = this.$refs.bigimg

      // 第一步获取鼠标中点位置
      let maskX = e.pageX - small.offsetLeft
      let maskY = e.pageY - small.offsetTop

      // 让鼠标出现在mask的中心点
      maskX = maskX - mask.offsetWidth / 2
      maskY = maskY - mask.offsetHeight / 2

      // 把mask限制到box中
      maskX = maskX < 0 ? 0 : maskX
      maskY = maskY < 0 ? 0 : maskY

      maskX = maskX > small.offsetWidth - mask.offsetWidth ? small.offsetWidth - mask.offsetWidth : maskX
      maskY = maskY > small.offsetHeight - mask.offsetHeight ? small.offsetHeight - mask.offsetHeight : maskY

      //遮挡层的移动距离/大图的移动距离=遮挡层的最大移动距离/大图的最大移动距离
      //大图的移动距离=遮挡层的移动距离*大图的最大移动距离/遮挡层的最大移动距离

      // 注意：
      // 遮罩层的面积/盒子的面积 == 大图展示盒子的面积/大图图片的面积
      // 一定要是等比例的才行严格控制样式

      let bigImgX = (maskX * (big.offsetWidth - bigimg.offsetWidth)) / (small.offsetWidth - mask.offsetWidth)
      let bigImgY = (maskY * (big.offsetHeight - bigimg.offsetHeight)) / (small.offsetHeight - mask.offsetHeight)
      this.left = maskX
      this.top = maskY
      this.imgX = bigImgX
      this.imgY = bigImgY
    },
    handleMouseOver() {
      this.isMouseEvent = true
    },
    handleMouseOut() {
      this.isMouseEvent = false
    },
    // 返回首页
    toHome() {
      this.$router.push('/')
    },
    setShowDialog(data) {
      console.log(data)
      this.showDialog = data
    },
    setVipShow(data) {
      console.log('点击了2')
      this.showDialog = true
    },
    handleChangeTitle(name) {
      this.activeName = name
      this.handleClick()
    },
    handleInfoStatus(status) {
      if (status !== 2) {
        return require('@/assets/images/detail/icon_detail_sold_out.png')
      }
    },

  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.page-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: white;
  .page-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: $contentWidth;
  }
}
.breadcrumb-box {
  margin-top: 13px;
}
.page-headline-box {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  .page-headline-img-box {
    display: flex;
    flex-direction: column;
    .page-headline-img-box-png {
      width: 400px;
      height: 400px;
    }

    .page-headline-img-scroll-box-s {
      width: 400px; /*设定宽度*/
      // overflow-x: hidden; /* 设置滚动条 */
      // overflow-y: hidden;
      // margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }
    .page-headline-img-scroll-box-s .page-headline-img-box-lr {
      width: 40px;
      height: 40px;
      margin: 20px;
    }

    .page-headline-img-scroll-box {
      margin-top: 10px;
      white-space: nowrap;
      overflow: hidden;
    }

    .page-headline-img-scroll-box img {
      width: 72px;
      height: 72px;
      margin-right: 10px;
      display: inline-block;
      // animation: move 10s linear infinite; /* 设置动画 */
      // animation: move2 10s linear infinite; /* 设置动画 */
    }

    /*图片元素向右滚动*/
    @keyframes move {
      0% {
      }
      100% {
        transform: translateX(-50%);
        /* 可以把这里改为translateY 实现上下滚动*/
      }
    }
    /*图片向左滚动:*/
    @keyframes move2 {
      0% {
        transform: translateX(calc(-100% + 100vw));
      }
      100% {
        transform: translateX(calc(-66.6% + 100vw));
      }
    }
  }
  .page-headline-title-box {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: 20px;
    .page-headline-title-box-infono {
      font-size: 14px;
      color: #bbbbbb;
      margin-top: 10px;
    }
    .page-headline-title-box-tips {
      display: flex;
      flex-direction: row;
      .page-headline-title-box-tips1 {
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
        background: linear-gradient(to bottom, #f24c3a, #e1251b);
        padding: 3px 10px 5px 5px;
        clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 70% 100%, 0% 100%);
      }
      .page-headline-title-box-tips2 {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        margin-left: 0px;
      }
    }
    .page-headline-title-box-select-type {
      display: flex;
      flex-direction: column;
      background: url('@/assets/images/detail/icon_detail_background_type.png');
      background-size: 780px 112px;
      background-repeat: no-repeat;
      height: 112px;
      width: 780px;
      margin-top: 15px;
      padding-top: 11px;
      padding-bottom: 11px;
      justify-content: center;
      .page-headline-title-box-st-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        min-width: 182px;
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 5px;
        padding-right: 5px;
        // background: rgba(255, 255, 255, 0.1);
        .page-headline-title-box-st-child1 {
          font-size: 14px;
          color: #ffffff;
        }
        .page-headline-title-box-st-child2 {
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
    .page-headline-title-box-material-type {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      color: #333333;
      cursor: pointer;
      .page-headline-title-box-material-child-type {
        display: flex;
        flex-direction: row;
        width: 260px;
        height: 48px;
        background: #f5f5f5;
        padding: 10px;
        align-items: center;
        margin-top: 11px;
        margin-right: 10px;
      }
    }
    .page-headlie-title-box-lucency {
      opacity: 0.2;
      pointer-events: none;
    }
    .page-headline-title-box-specifications{
      display: flex;
      margin-top: 20px;
      .title{
        font-size: 14px;
        color: #BBBBBB;
        margin-right: 9px;
        display: inline-block;
        width: 43px;
        margin-top: 12px;
        white-space: nowrap;
      }

      .specificationsItem{
        span{
          display: inline-block;
          height: 42px;
          max-width: 650px;
          line-height: 42px;
          background: #F5F5F5;
          border-radius: 4px;
          padding: 0 36px;
          margin: 0 10px 20px 0;
          font-size: 14px;
          color: #333333;
          cursor: pointer;
        }
        .selected{
          color: #E60012;
          background: #FCE9E8;
        }
      }
    }
    .page-headline-title-box-weight{
      display: flex;
      .title{
        font-size: 14px;
        color: #BBBBBB;
        margin-right: 9px;
        display: inline-block;
        width: 43px;
      }
      .weightNum{
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .page-headline-title-box-material-child-location {
    display: flex;
    flex-direction: row;
    height: 57px;
    align-items: center;
  }

  .page-headline-title-box-material-child-collect {
    display: flex;
    flex-direction: row;
    height: 57px;
    align-items: center;
    margin-left: 20px;
  }

  .page-headline-title-box-material-child-button1 {
    width: 110px;
    height: 40px;
    background: linear-gradient(270deg, #f24c3a 0%, #e1251b 100%);
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .page-headline-title-box-material-child-button2 {
    width: 110px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid;
    font-size: 14px;
    color: #e1251b;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer;
  }
  .page-headline-title-box-status {
    position: absolute;
    top: 114px;
    right: 71px;
    .page-headline-title-box-soldout-status {
      width: 170px;
      height: 170px;
    }
  }
}
.page-particulars-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 17px;
  .page-particulars-left-box {
    display: flex;
    flex-direction: column;
    width: 232px;
  }
  .page-particulars-right-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 10px;
    .page-particulars-right-description-box {
      width: 100%;
      height: 42px;
      font-size: 16px;
      color: #333333;
      padding-left: 19px;
      background: #f5f5f5;
      border-left-color: #e1251b;
      border-left-style: solid;
      border-left-width: 4px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-weight: bold;
    }
    .page-particulars-right-description-cont-box {
      font-size: 12px;
      color: #bbbbbb;
      line-height: 16px;
      padding: 10px;
      box-sizing: border-box;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      background-color: transparent;
    }
    ::v-deep .el-tabs__header {
      margin: 0 0;
    }
    .page-particulars-right-titles {
      display: flex;
      flex-direction: row;
      background: rgb(245, 245, 245);
      padding-left: 32px;
      width: 100%;
      height: 50px;
      .page-particulars-right-titles-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 16px;
        cursor: pointer;
        .page-particulars-right-titles-p1 {
          font-size: 14px;
          color: #333333;
          &.active {
            color: $importantColor;
            font-weight: bold;
          }
        }
        .page-particulars-right-titles-line {
          width: 21px;
          margin-top: 9px;
          border: 3px solid #e1251b;
          border-radius: 5px;
        }
      }
    }
  }
}

// 对话框(客服电话)
.whz-el-dialog1 {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 24px;
      color: #333333;
      font-weight: bold;
    }
    .dialog-content-info {
      font-size: 21px;
      color: #BBBBBB;
    }
    .telIcon{
      width: 42px;
      height: 42px;
      margin: 45px 0 13px;
    }
  }
  .dialog-content-mini {
    width: 205px;
    height: 205px;
    margin-top: 51px;
  }
  ::v-deep .el-dialog {
    width: 520px;
    height: 268px;
    border-radius: 20px;
    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 160px;
    height: 50px;
    font-size: 14px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  //   ::v-deep .el-dialog__headerbtn {
  //     visibility: hidden;
  //   }

  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: white;
    border-radius: 50%;
    background: #929292;
    padding: 3px;
  }
}
//.whz-el-dialog1 {
//  .dialog-content {
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    .dialog-content-title {
//      font-size: 16px;
//      color: #333333;
//      font-weight: bold;
//      margin-top: 20px;
//    }
//    .dialog-content-info {
//      font-size: 14px;
//      color: #bbbbbb;
//      margin-top: 6px;
//    }
//  }
//  .dialog-content-mini {
//    width: 205px;
//    height: 205px;
//    margin-top: 51px;
//  }
//  ::v-deep .el-dialog {
//    width: 520px;
//    height: 520px;
//    border-radius: 20px;
//    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
//  }
//  ::v-deep .el-button--small {
//    border-radius: 34px;
//    width: 160px;
//    height: 50px;
//    font-size: 14px;
//  }
//  ::v-deep .el-button--default {
//    background: #e8e8e8;
//  }
//  //   ::v-deep .el-dialog__headerbtn {
//  //     visibility: hidden;
//  //   }
//
//  ::v-deep .el-dialog__headerbtn .el-dialog__close {
//    color: white;
//    border-radius: 50%;
//    background: #929292;
//    padding: 3px;
//  }
//}

// 对话框
.whz-el-dialog2 {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      margin-top: 20px;
    }
    .dialog-content-info {
      font-size: 14px;
      color: #bbbbbb;
      margin-top: 6px;
    }

    .dialog-content-scroll {
      height: 400px;
      width: 100%;
      .dialog-content-scroll-item {
        height: 70px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom-color: #eaeaea;
        border-bottom-style: dashed;
        border-bottom-width: 1px;
        justify-content: space-between;
        .dialog-content-scroll-item-left {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .dialog-content-scroll-item-img {
          width: 50px;
          height: 50px;
        }
        .dialog-content-scroll-item-p1 {
          color: #333333;
          margin-left: 10px;
          font-size: 16px;
        }
        .dialog-content-scroll-item-p2 {
          color: #bbbbbb;
          margin-left: 20px;
          font-size: 12px;
        }
        .dialog-content-scroll-item-p3 {
          color: #333333;
          margin-left: 5px;
          font-size: 14px;
        }
        .dialog-content-scroll-item-img2 {
          width: 24px;
          height: 24px;
          margin-left: 70px;
        }
      }
    }
  }
  .dialog-content-mini_p {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  ::v-deep .el-dialog {
    width: 520px;
    height: 520px;
    border-radius: 20px;
    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 160px;
    height: 50px;
    font-size: 14px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  //   ::v-deep .el-dialog__headerbtn {
  //     visibility: hidden;
  //   }

  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: white;
    border-radius: 50%;
    background: #929292;
    padding: 3px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    background-color: #ececec;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
  }
}

.page-headline-img-small {
  width: 400px;
  height: 400px;
  position: relative;
}
.page-headline-img-small img {
  width: 100%;
  height: 100%;
}
.page-headline-img-big {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 0px;
  left: 10px;
  overflow: hidden;
  z-index: 9;
}
.page-headline-img-big img {
  position: absolute;
  width: 800px;
  height: 800px;
  left: 0;
  top: 0;
}

.page-headline-img-box-mask {
  width: 200px;
  height: 200px;
  background: rgba(255, 255, 0, 0.4);
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: move;
}

#scroll_0 {
  text-align: center;
  .specBag{
    margin: 44px 0;
    display: block;
    width: 214px;
    height: 42px;
    line-height: 42px;
    background: linear-gradient(270deg, rgba(251,251,251,0) 0%, rgba(245,245,245,0.43) 24%, #F5F5F5 100%);
    border-radius: 4px;
    padding: 0 13px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }
}
#scroll_0 img {
  display: inline-block;
}
</style>
