<template>
  <!-- 编辑发布信息的列表页 -->
  <div class="component-container">
    <div class="component-content">
      <div class="list" v-for="item in list" :key="item.infoNo">
        <div class="item" @click="handleClick(item)">{{ item.materialName }} 编辑</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { apiGetMyList } from '@/api/index'
export default {
  name: 'TopBar',
  components: {},
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  created() {
    // 获取用户信息
    this.getUserInfo()
  },
  mounted() {
    apiGetMyList({
      userUuid: this.userInfo.userUuid,
      releaseType: 1,
      pageNo: 1,
      pageSize: 100,
    }).then((res) => {
      this.list = res.data
    })
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),

    handleClick(item) {
      const type = 2
      // 需要传发布的类型和infoN0 1: '出租', 2: '出售', 3: '求租', 4: '求购',
      this.$router.push({ path: `publish/${type}/${item.infoNo}` })
    },
  },
}
</script>
<style lang="scss" scoped>
.item {
  padding: 10px;
  cursor: pointer;
}
</style>
