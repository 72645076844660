<template>
  <div class="app-container">
    <el-upload ref="elUpload" 
    action="https://v0.api.upyun.com/jupaiwang" 
    class="el-upload-list__item-thumbnail" 
   :show-file-list="false"
    :on-success="onSuccess"
   :before-upload="beforeUpload" 
    list-type="picture-card" 
    :data="fileExtendData" 
    >
    
      <img v-if="upimg" width="95%" :src="upimg" alt="" style="object-fit: contain;  height: 95%;"/>
      <img v-else :src="images[srclen]" alt="" :srclen="srclen" width='136'>
    </el-upload>

  </div>
</template>
<script>
import { getUpyunToken } from '@/api/common'
export default {
  name: 'UploadImage',
  computed: {},
  props: {
    srclen: Number,
    upimg:String
  },
  data() {
    return {
      limit: 0,
      dialogImageUrl:'',
      fileExtendData: {},
      images:[
        `${require('@/assets/images/realname/realnameL_1.png')}`, //营业执照
        `${require('@/assets/images/realname/realnameL_2.png')}`, //身份证正面
        `${require('@/assets/images/realname/realnameL_3.png')}`, //身份证反面
        `${require('@/assets/images/realname/realnameL_4.png')}`, //手持
        `${require('@/assets/images/realname/realnameL_5.png')}`, //委托书
      ]
    }
  },
   watch: {
        upimg(newValue) {
          console
            this.dialogImageUrl = newValue;
        },
   },

  mounted() {
    //初始化
    if (this.imageList && this.imageList.length > 0) {
      this.fileList = this.imageList
      this.addImageAttr()
    }
  },
  methods: {
    // 图片添加属性
    addImageAttr() {
      this.$nextTick(() => {
        const eleList = document.getElementsByClassName('el-upload-list__item-thumbnail')
        if (eleList && eleList.length > 0) {
          Array.prototype.forEach.call(eleList, (item) => {
            item.setAttribute('referrer', 'no-referrer|origin|unsafe-url')
            item.setAttribute('referrerPolicy', 'no-referrer')
          })
        }
      })
    },

    // 获取 token
    beforeUpload() {
      return new Promise((resolve, reject) => {
        var params = { propKey: 'jupai' }
        getUpyunToken(params).then((res) => {
          if (res.code == '000000') {
            this.fileExtendData = {
              policy: res.data.policy,
              authorization: 'UPYUN jupai:' + res.data.sign,
            }
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 上传成功
    onSuccess(response, file, fileList) {
      this.addImageAttr()
      // this.dialogImageUrl=process.env.VUE_APP_UPYUN_API + response.url
      this.$emit('success', process.env.VUE_APP_UPYUN_API + response.url,this.srclen)
    },
 
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables';

::v-deep .el-upload.el-upload--picture-card {
  width: 166px;
  height: 120px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px #ECECEC dashed;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-upload--picture-card i {
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    width: 54px;
    height: 51px;
    background: url('@/assets/images/home/upload.png') no-repeat center / 100%;
  }

}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
.app-container {

  // border: 1px #ECECEC dashed
}
</style>
