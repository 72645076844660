<template>
  <div class="item-module-goods">
    <div class="item-content-list">
      <div v-for="(item, index) in selTitleData" :key="index + 'w'" class="item-content-list-text" :class="{ active: selTitleIndex === index }" @click="handleNavClick(index)">
        {{ item }}
      </div>
    </div>
    <el-divider></el-divider>
    <div v-show="leaseInfoList.length > 0">
      <div v-for="(item, index) in leaseInfoList" :key="index + 'l'">
        <div class="item-module-goods-list">
          <div class="item-module-goods-list-top">
            <p v-if="item.infoStatus != 2" class="item-mess-status-icon" style="margin-left: 0px">已下架</p>
            <p class="item-module-goods-list-top-text">发布时间：{{ item.addTime }}</p>
            <p class="item-module-goods-list-top-text">物资编号：{{ item.infoNo }}</p>
          </div>
          <div class="item-module-goods-list-bottom">
            <div class="item-module-goods-list-bottom-left" @click="handleDetail(item.infoNo,item.specId)">
              <img referrer="no-referrer|origin|unsafe-url" referrerPolicy="no-referrer" :src="item.firstPictureUrl" class="item-module-goods-list-bl-img" />
              <div class="item-module-goods-list-bl-content">
                <p class="item-module-goods-list-bl-content-p" style="font-weight: bold">{{ item.packageTitle }}</p>
                <p class="item-module-goods-list-bl-content-p ellipsis" style="margin-top: 10px;width: 300px;">规格型号：{{ item.specName }}</p>
                <p class="item-module-goods-list-bl-content-p" style="margin-top: 3px">物资数量：{{ item.qty }}{{ item.qtyUnit }}</p>
                <div style="margin-top: 12px">
                  <div class="item-module-goods-list-bl-content-p-rent" style="margin-bottom: 5px" v-for="(item1, index1) in item.priceTextSet" :key="index1 + 'l'">
                    <div class="item-module-goods-list-bl-content-p-rent2">
                      <p style="color: #bbbbbb" v-show="item1.type">{{ item1.type }}：</p>
                      {{ item1.price }}
                      <p style="color: #e1251b; font-weight: normal;">{{ item1.unit }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-module-goods-list-bottom-right">
              <div class="item-module-goods-list-right-item1">
                <img v-show="item.infoStatus == 2" @click.stop="doCall(item.infoNo)" src="@/assets/images/public/icon-phone2.png" class="item-module-goods-list-br-img1" />
                <p v-show="item.infoStatus == 2" @click.stop="doCall(item.infoNo)" class="item-module-goods-list-br-p1">电话咨询</p>
              </div>
              <div class="item-module-goods-list-right-item2">
                <template v-if="item.geoDistance != ''">
                  <img src="@/assets/images/public/icon-position-gray.png" class="item-module-goods-list-br-img2" />
                  <p class="item-module-goods-list-br-p2">{{ item.provName }}{{ item.cityName }} {{ item.geoDistance }}</p>
                </template>
                <p v-if="showType == 5" @click.stop="cancelCollect(item.infoNo)" class="item-module-goods-list-br-p3">取消收藏</p>
              </div>
            </div>
          </div>
        </div>
      </div>

<!--      <el-dialog :visible.sync="rDialogVisible" center class="whz-el-dialog1">-->
<!--        <div class="dialog-content">-->
<!--          <img :src="miniCodeUrl" class="dialog-content-mini" />-->
<!--          <p class="dialog-content-title">通过微信扫一扫</p>-->
<!--          <p class="dialog-content-info">扫描二维码可通过聚循环小程序免费拔打电话咨询</p>-->
<!--        </div>-->

<!--        <div slot="footer" class="dialog-footer">-->
<!--          <el-button-->
<!--            type="primary"-->
<!--            @click="-->
<!--              rDialogVisible = false-->
<!--              miniCodeUrl = ''-->
<!--            "-->
<!--            >确 定</el-button-->
<!--          >-->
<!--        </div>-->
<!--      </el-dialog>-->
      <el-dialog :visible.sync="rDialogVisible" center class="whz-el-dialog1">
        <div class="dialog-content">
          <p class="dialog-content-title">客服电话</p>
          <img src="@/assets/images/home/telIcon.png" class='telIcon'/>
          <p class="dialog-content-info">{{customerService}}</p>
        </div>
      </el-dialog>
      <div class="pagination-bottom">
        <!-- <el-pagination background layout="prev, pager, next" :current-page="pageNo" :page-size="pageSize" :total="total" hide-on-single-page @current-change="currentPage"> </el-pagination> -->

        <el-pagination @size-change="currentPage" @current-change="currentPage" :current-page.sync="pageNo" :page-size="pageSize" layout="total, prev, pager, next" :total="total"> </el-pagination>
      </div>
    </div>
    <div v-show="leaseInfoList.length == 0">
      <el-empty :image-size="200"></el-empty>
    </div>
  </div>
</template>
      
      <script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { getCallList, getCollectList, getLookList, collect } from '@/api/personal'
import { getMiniCode, getUserDoCall } from '@/api/common'
export default {
  name: 'AttentionCom',
  components: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      userPosition: (state) => state.user.userPosition,
    }),
  },
  props: {
    showType: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      selTitleData: [' 全部 ', '出租信息', '出售信息', '求租信息', '求购信息', '置换信息'],
      selTitleIndex: 0,
      leaseInfoData: ['1', '2', '', '', '', '', '', '', ''],
      pageNo: 1,
      pageSize: 10,
      leaseInfoList: [],
      releaseType: '',
      total: 0,
      rDialogVisible: false,
      miniCodeUrl: '',
      customerService:null,//客服电话
    }
  },
  methods: {
    currentPage(page) {
      this.pageNo = page
      this.getLeaseInfoList()
    },
    handleNavClick(index) {
      this.selTitleIndex = index
      switch (index) {
        case 0:
          this.releaseType = -1
          break
        case 1:
          this.releaseType = 1
          break
        case 2:
          this.releaseType = 2
          break
        case 3:
          this.releaseType = 3
          break
        case 4:
          this.releaseType = 4
          break
        case 5:
          this.releaseType = 5
          break
      }
      this.pageNo = 1
      this.getLeaseInfoList()
    },
    getLeaseInfoList() {
      var params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        lon: this.userPosition.location.lng,
        lat: this.userPosition.location.lat,
      }
      if (this.releaseType != '' && this.releaseType != -1) {
        params['releaseType'] = this.releaseType
      }
      if (this.$store.state) console.log(this.showType)
      if (this.showType == 4) {
        //拨打记录
        getCallList(params).then((res) => {
          if (res.success) {
            this.total = res.count
            this.showDataList(res.data)
          } else {
            this.$message({ message: res.msg, type: 'warning' })
          }
        })
      } else if (this.showType == 5) {
        //收藏记录
        getCollectList(params).then((res) => {
          if (res.success) {
            this.total = res.count
            this.showDataList(res.data)
          } else {
            this.$message({ message: res.msg, type: 'warning' })
          }
        })
      } else if (this.showType == 6) {
        //浏览记录
        getLookList(params).then((res) => {
          if (res.success) {
            this.total = res.count
            this.showDataList(res.data)
          } else {
            this.$message({ message: res.msg, type: 'warning' })
          }
        })
      }
    },
    showDataList(dataList) {
      console.log(dataList)
      var oldDataList = []
      dataList.forEach((item) => {
        console.log(item.dataList)
        oldDataList = oldDataList.concat(item.dataList)
      })
      console.log(oldDataList)
      oldDataList.forEach((element) => {
        element['showStatus'] = this.getShowStatus(element.infoStatus)
      })
      this.leaseInfoList = oldDataList
      // console.log(this.leaseInfoList)
    },
    getShowStatus(infoStatus) {
      var showStatus = ''
      //0：待审核，1：待上架，2：已上架，3：已下架，4：平台下架，5：已驳回
      switch (infoStatus) {
        case 0:
          showStatus = '待审核'
          break
        case 1:
          showStatus = '待上架'
          break
        case 2:
          showStatus = '已上架'
          break
        case 3:
          showStatus = '已下架'
          break
        case 4:
          showStatus = '平台下架'
          break
        case 5:
          showStatus = '已驳回'
          break
        default:
          break
      }
      return showStatus
    },
    doCall(infoNo) {
      var params = {
        infoNo: infoNo,
      }
      // getMiniCode(params).then((res) => {
      //   if (res.success) {
      //     console.log(res.data.miniCodeUrl)
      //     this.miniCodeUrl = res.data.miniCodeUrl
      //     this.rDialogVisible = true
      //   } else {
      //     this.$message({ message: res.msg, type: 'warning' })
      //   }
      // })
      getUserDoCall(params).then((res) => {
        if (res.success) {
          this.customerService = res.data.phoneNoX;
          this.rDialogVisible = true;
        } else {
          this.$message(res.msg)
        }
      })
    },
    cancelCollect(infoNo) {
      var params = {
        infoNo: infoNo,
        operationType: -1,
      }
      collect(params).then((res) => {
        if (res.success) {
          //取消收藏成功
          this.getLeaseInfoList()
        } else {
          this.$message({ message: res.msg, type: 'warning' })
        }
      })
    },
    handleDetail(infoNo,specId) {
      // 跳转详情
      var handleCode = ''
      // 登录成功后未开通会员，弹窗领取会员后可查看详情
      this.$router.push({
        path: '/product/detail',
        query: {
          infoNo: infoNo,
          specId:specId,
          subheads: '关注记录',
          handleCode: handleCode,
        },
      })
    },
  },
  mounted() {
    console.log('111111')
    this.getLeaseInfoList()
  },
  watch: {
    showType: function (newValue, oldValue) {
      // 响应数据变化
      console.log(newValue)
      this.pageNo = 1
      this.getLeaseInfoList()
    },
  },
}
</script>
      
      <style lang="scss" scoped>
@import '@/styles/variables';

.item-content-list {
  height: 59px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-left: 57px;
  .item-content-list-text {
    color: #333333;
    margin-right: 73px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: $importantColor;
    }
    &.active {
      color: $importantColor;
    }
  }
}
.item-module-goods {
  display: flex;
  flex-direction: column;
  justify-content: start;
  .pagination-bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }
}
.item-module-goods ::v-deep .el-divider--horizontal {
  margin: 0px 20px 0px 20px;
  background-color: #eaeaea;
  width: auto;
}
.item-module-goods-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 15px 0px;
  margin: 0px 15px;
  border-bottom-color: #eaeaea;
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  .item-module-goods-list-top {
    display: flex;
    flex-direction: row;
    justify-content: start;
    font-size: 14px;
    color: #bbbbbb;
    .item-mess-status-icon {
      margin-left: 8px;
      background: #e1251b;
      padding: 2px 5px;
      font-size: 12px;
      color: white;
      border-radius: 2px;
    }
    .item-module-goods-list-top-text {
      margin-left: 10px;
    }
  }
  .item-module-goods-list-bottom {
    display: flex;
    flex-direction: row;
    justify-content: start;
    .item-module-goods-list-bottom-left {
      display: flex;
      flex-direction: row;
      flex: 1;
      padding-top: 15px;
      cursor: pointer;
      .item-module-goods-list-bl-img {
        width: 138px;
        height: 138px;
        background: #eaeaea;
      }
      .item-module-goods-list-bl-content {
        // display: flex;
        // flex-direction: column;
        // justify-content: start;
        .item-module-goods-list-bl-content-p {
          font-size: 14px;
          color: #333333;
          margin-left: 10px;
        }
        .item-module-goods-list-bl-content-p-rent {
          display: flex;
          font-size: 12px;
          color: #bbbbbb;
          margin-left: 10px;
        }
        .item-module-goods-list-bl-content-p-rent2 {
          display: flex;
          font-size: 12px;
          color: #e1251b;
          font-weight: bold;
        }
      }
    }
    .item-module-goods-list-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      flex: 1;
      padding-top: 15px;
      .item-module-goods-list-right-item1 {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      .item-module-goods-list-right-item2 {
        flex: 2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
      }
      .item-module-goods-list-br-img1 {
        width: 14px;
        height: 14px;
      }
      .item-module-goods-list-br-p1 {
        font-size: 14px;
        color: #e1251b;
        margin-left: 7px;
        cursor: pointer;
      }
      .item-module-goods-list-br-img2 {
        width: 11px;
        height: 14px;
      }
      .item-module-goods-list-br-p2 {
        width: 178px;
        font-size: 12px;
        color: #bbbbbb;
        margin-right: 10px;
      }
      .item-module-goods-list-br-p3 {
        font-size: 12px;
        color: #bbbbbb;
        cursor: pointer;
      }
    }
  }
}
// 对话框(客服电话)
.whz-el-dialog1 {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 24px;
      color: #333333;
      font-weight: bold;
    }
    .dialog-content-info {
      font-size: 21px;
      color: #BBBBBB;
    }
    .telIcon{
      width: 42px;
      height: 42px;
      margin: 45px 0 13px;
    }
  }
  .dialog-content-mini {
    width: 205px;
    height: 205px;
    margin-top: 51px;
  }
  ::v-deep .el-dialog {
    width: 520px;
    height: 268px;
    border-radius: 20px;
    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 160px;
    height: 50px;
    font-size: 14px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  //   ::v-deep .el-dialog__headerbtn {
  //     visibility: hidden;
  //   }

  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: white;
    border-radius: 50%;
    background: #929292;
    padding: 3px;
  }
}
// 对话框
//.whz-el-dialog1 {
//  .dialog-content {
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    .dialog-content-title {
//      font-size: 16px;
//      color: #333333;
//      font-weight: bold;
//      margin-top: 20px;
//    }
//    .dialog-content-info {
//      font-size: 14px;
//      color: #bbbbbb;
//      margin-top: 6px;
//    }
//  }
//  .dialog-content-mini {
//    width: 205px;
//    height: 205px;
//    margin-top: 51px;
//  }
//  ::v-deep .el-dialog {
//    width: 520px;
//    height: 520px;
//    border-radius: 20px;
//    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
//  }
//  ::v-deep .el-button--small {
//    border-radius: 34px;
//    width: 160px;
//    height: 50px;
//    font-size: 14px;
//  }
//  ::v-deep .el-button--default {
//    background: #e8e8e8;
//  }
//  //   ::v-deep .el-dialog__headerbtn {
//  //     visibility: hidden;
//  //   }
//
//  ::v-deep .el-dialog__headerbtn .el-dialog__close {
//    color: white;
//    border-radius: 50%;
//    background: #929292;
//    padding: 3px;
//  }
//}
</style>
      