
<template>
  <div className='page-container'>
    <Header @home="toHome"/>
    <div style='position:relative;'>
      <StorageMap ref="mapChild"/>
      <div class='searchForm' :style="{ left: leftOffset + 'px' }">
        <div class='search'>
          <el-input
            class='searchInput'
            placeholder="请输入城市"
            v-model="searchCity"
            @input="onInput">
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div class='warehouseTotal'>共找到 {{storageTotal}} 个仓库</div>
        <div class='warehouseList' ref="warehouseListRef" >
          <StorageListinfo @warehouse-click="handleWarehouse" :mapListData="mapListData" :selectedIndex="selectedIndex"/>
          <div class="no-more" v-if="!hasMore">没有更多数据了</div>
          <div class='nodata' v-if='nodataFlag'>
            <img src='@/assets/images/home/mapNoData.png' alt=''>
            <div class='text'>暂无数据</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/public/Header.vue'
import StorageMap from './components/storageMap.vue'
import StorageListinfo from './components/storageListinfo.vue'
import { getMapList } from '@/api/storage'
import { mapState } from 'vuex'

export default {
  name: 'storageIndex',
  components: { Header, StorageListinfo, StorageMap },
  computed: {
    ...mapState({
      userPosition: (state) => state.user.userPosition,
    }),
  },
  data() {
    return {
      searchCity:'',
      mapListData:[],//仓库列表数据
      nodataFlag:false,//仓库列表是否有数据
      selectedIndex:null,//当前选中的仓库
      screenWidth: 0, // 屏幕宽度
      leftOffset: 0, // 绝对定位元素距离左侧的距离
      page: 1, // 当前页码
      limit: 20,//调取的条数
      hasMore: true, // 是否还有更多数据
      storageTotal:0,//仓库总数
    }
  },
  watch: {
    userPosition: {
      handler: function (newVal, oldVale) {
        this.page = 1;
        this.hasMore = true;
        this.getMapListData({},this.keyWord)
      },
      immediate: true,
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth // 获取屏幕宽度
    this.getLeftOffset() // 计算绝对定位元素距离左侧的初始距离
    window.addEventListener('resize', this.handleResize) // 监听屏幕宽度的变化
    const warehouseListRef = this.$refs.warehouseListRef;
    warehouseListRef.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize) // 移除屏幕宽度监听事件
  },
  methods: {
    // 返回首页
    toHome() {
      this.$router.push('/')
      this.setNavActive(0)
    },
    getLeftOffset() {
      // 根据屏幕宽度计算绝对定位元素距离左侧的距离
      this.leftOffset = ((this.screenWidth - 1200) / 2) -8;
    },
    handleResize() {
      this.screenWidth = window.innerWidth // 更新屏幕宽度
      this.getLeftOffset() // 重新计算绝对定位元素距离左侧的距离
    },
    //获取仓库列表
    /*item 当前仓库的item数据
    keyWord 输入的搜索条件
    uniqueId 点击匹配的id值
    pageNum 当前页数
    ishasMore 是否加载更多
   */
    getMapListData(item,keyWord,uniqueId,pageNum,ishasMore){
      let that = this;
      if (!that.userPosition.location) {
        return
      }
      if(ishasMore){
        that.hasMore  = ishasMore
      }
      if (!that.hasMore) return; // 如果正在加载数据或没有更多数据可供加载，则直接返回
      if(pageNum){
        that.page = pageNum;
      }
      var params = {
        page: that.page,
        limit: that.limit,
        lon: that.userPosition.location.lng,
        lat: that.userPosition.location.lat,
        provId: item.parentId != 0 ? item.parentId : item.dataId,
        cityId: item.parentId != 0 ? item.dataId : null,
        typeCode:null,
        keyWord:keyWord,
      }
      getMapList(params).then((res) => {
        if(res.success == true){
          that.storageTotal = res.count;
          if (res.data && res.data.length > 0) {
            if(that.page == 1){
              that.mapListData = res.data;
              that.selectedIndex = null
            }else{
              that.mapListData.push(...res.data); // 将新数据追加到原有数据之后
            }
            that.page++; // 更新页码数
            that.nodataFlag = false;
            if(that.mapListData.length >= res.count){
              that.hasMore = false; // 没有更多数据可供加载
            }
            if(uniqueId){
              let selectindex = that.mapListData.findIndex(ite => ite.id == uniqueId);
              that.handleWarehouse(null,selectindex)
            }
          } else {
            that.mapListData = [];
            that.nodataFlag = true;
          }
        }else{
          that.mapListData = [];
          that.nodataFlag = true;
        }
      })
    },
    //搜索城市
    onInput(val) {
      this.page = 1;
      this.hasMore = true;
      this.getMapListData({},val)
    },
    handleScroll(event) {
      const warehouseListRef = event.target;
      // 判断是否滚动到底部，如果是，则调用 getMapListData 方法进行数据加载
      if (warehouseListRef.scrollHeight - warehouseListRef.scrollTop <= warehouseListRef.clientHeight) {
        this.getMapListData({},this.searchCity);
      }
    },
    //仓库点击事件
    handleWarehouse(itemInfo,index) {
      this.$refs.mapChild.removeAllMarkers();
      this.$refs.mapChild.clickFlag = true;
      this.selectedIndex = index;
      // 通过索引获取对应的数据项
      const selectedItem = this.mapListData[index];
      // 输出获取到的数据项
      if(selectedItem){
        this.$refs.mapChild.frameFun(selectedItem);
      }
    },
  }
}
</script>

<style lang='scss' scoped>
#container {
  padding: 0px;
  width: 100%;
  height: 750px;
  margin: 10px 0;
}
.searchForm{
  width: 324px;
  height: 750px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  .search {
    padding: 20px 12px;
    background: #FFFFFF;

    .searchInput {
      ::v-deep .el-input__inner {
        height: 40px;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
      }
    }
  }
  .warehouseTotal{
    color: #999999;
    font-size: 12px;
    padding: 0 0 10px;
    margin: 0 30px;
  }
  .warehouseList{
    height: calc(100% - 110px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 5px;
    .nodata{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 200px;
      img{
        width: 166px;
        height: 135px;
      }
      .text{
        margin-top: 35px;
        font-size: 14px;
        color: #BBBBBB;
        line-height: 19px;
      }
    }
  }
  .no-more{
    text-align: center;
    padding: 20px 0;
    color: #999999;
  }
}
::v-deep .amap-info{
  min-width: 260px;
  max-width: 350px;
}
::v-deep .amap-info-content{
  background: #333333 !important;
}
::v-deep .bottom-center .amap-info-sharp{
  border-top: 8px solid #333333 !important;
}
::v-deep .amap-info-close{
  color: #c3c3c3 !important;
}
*::-webkit-scrollbar {
  border: none; /* 去掉边框 */
  width: 4px; /* 滚动条宽度 */
}
/* 仅在火狐浏览器中设置滚动条宽度为thin */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: #BBBBBB transparent; /* 滑块颜色和透明背景 */
  }
  *::-moz-scrollbar-thumb {
    background-color: #BBBBBB; /* 火狐浏览器滑块颜色 */
  }
  *::-moz-scrollbar-track {
    background-color: transparent; /* 火狐浏览器轨道背景 */
  }
}
/* 滚动条滑块 */
*::-webkit-scrollbar-thumb {
  background-color: #BBBBBB; /* 谷歌浏览器滑块颜色 */
}
/* 滚动条轨道 */
*::-webkit-scrollbar-track {
  background-color: transparent; /* 谷歌浏览器轨道背景 */
}


</style>
