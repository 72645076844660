import request from '@/utils/request'

// 获取详情推荐数据
export function recommendList(data) {
  return request({
    url: '/appApi/jzl/infoDetailPage/recommendList',
    method: 'post',
    data,
  })
}

// 获取详情数据
export function listToDetail(data) {
  return request({
    url: '/appApi/jzl/infoDetailPage/listToDetail',
    method: 'post',
    data,
  })
}

// 收藏、取消收藏 
export function collectGoods(data) {
  return request({
    url: '/appApi/jzl/infoDetailPage/collect',
    method: 'post',
    data
  })
}
// 获取我的浏览记录
export function getLookListByInfoNo(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/getLookListByInfoNo',
    method: 'post',
    data
  })
}
// 获取我的拨打记录
export function getCallListByInfoNo(data) {
  return request({
    url: '/appApi/jzl/leaseInfo/getCallListByInfoNo',
    method: 'post',
    data
  })
}

// 校验是否可以跳转详情页
export function checkVipLimit(data) {
  return request({
    url: '/appApi/jzl/infoDetailPage/checkVipLimit',
    method: 'post',
    data,
  })
}