<template>
  <div class="page-container">
    <personal-infro-top :iSelect="0" />
    <div class="page-content">
      <title-left :options="m_options" @sendChangeSelect="ChangeSelect" :is-select.sync='changeSelect'/>
      <div class="page-right">
        <div v-if="changeSelect == 0 || changeSelect == 1 || changeSelect == 2 || changeSelect == 3 || changeSelect == 8">
          <release-com :releaseType="releaseType" />
        </div>

        <div v-if="changeSelect == 4 || changeSelect == 5 || changeSelect == 6">
          <attention-com :showType="showType" />
        </div>

        <!--委托发布管理-->
        <div v-if="changeSelect == 7">
          <entrust-com/>
        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
import { mapState } from 'vuex'
import PersonalInfroTop from '@/views/personalcenter/components/PersonalInfoTop.vue'
import TitleLeft from '@/views/personalcenter/components/TitleLeft.vue'
import AttentionCom from '../components/AttentionCom.vue'
import ReleaseCom from '../components/ReleaseCom.vue'
import EntrustCom from '../components/EntrustCom.vue'
export default {
  name: 'MessageIndex',
  components: { PersonalInfroTop, TitleLeft, AttentionCom, ReleaseCom, EntrustCom },
  computed: {
    ...mapState({
      navActive: (state) => state.home.navActive,
    }),
  },
  data() {
    return {
      m_options: [
        {
          title: '发布管理',
          content: [
            {
              index: 0,
              name: '出租信息',
            },
            {
              index: 1,
              name: '出售信息',
            },
            {
              index: 2,
              name: '求租信息',
            },
            {
              index: 3,
              name: '求购信息',
            },
            {
              index: 8,
              name: '置换信息',
            },
            {
              index: 7,
              name: '委托发布',
            },
          ],
        },
        {
          title: '关注记录',
          content: [
            {
              index: 4,
              name: '拨打记录',
            },
            {
              index: 5,
              name: '我的收藏',
            },
            {
              index: 6,
              name: '浏览记录',
            },
          ],
        },
      ],
      changeSelect: 0,
      releaseType: '1',
      showType: ''
    }
  },
  created() {
    if(this.$route.params.changeSelect){
      this.changeSelect = this.$route.params.changeSelect;
    }
  },
  methods: {
    ChangeSelect(v) {
      this.changeSelect = v
      // 1 出租 ；2 出售；3 求租；4 求购；5 置换
      switch (v) {
        case 0:
          this.releaseType = 1
          break
        case 1:
          this.releaseType = 2
          break
        case 2:
          this.releaseType = 3
          break
        case 3:
          this.releaseType = 4
          break
        case 4:
          this.showType = 4
          break
         case 5:
          this.showType = 5
          break
        case 6:
          this.showType = 6
          break
        case 8:
          this.releaseType = 5
          break
      }
    },
    handleMesType(mesType) {
      //（1-审核通知，2-下架通知，3-收藏上新，4-浏览上新）
      switch (mesType) {
        case 1:
          return '审核通知'
        case 2:
          return '下架通知'
        case 3:
          return '收藏上新'
        case 4:
          return '浏览上新'
        default:
          return '认证通知'
      }
    },
  },
}
</script>
      
      <style lang="scss" scoped>
@import '@/styles/variables';

.page-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #f4f4f4;
  .page-content {
    width: $contentWidth;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    margin-top: 10px;
  }
}
.page-right {
  background: white;
  margin-left: 10px;
  margin-bottom: 10px;
  min-height: 875px;
  flex: 1;
}
</style>
      