<template>
  <div class="component-container">
    <div class="component-content">
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="formStep1" label-width="100px" class="demo-form">
          <el-form-item label="企业名称" prop="entName">
            <el-input v-if="publishEdit && !canModifyEntName" v-model="form.entName" readonly placeholder="请输入企业名称" maxlength="200"></el-input>
            <el-input v-else v-model="form.entName" placeholder="请输入企业名称" maxlength="200"></el-input>
          </el-form-item>
          <el-form-item label="物资联系人" prop="contactName" class="small">
            <el-input v-model="form.contactName" placeholder="请输入物资联系人" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="联系人电话" prop="contactPhone" class="small">
            <el-input v-model="form.contactPhone" placeholder="请输入联系人电话"></el-input>
          </el-form-item>
          <el-form-item label="物资所在地" prop="address1" class="address" :show-message="false">
            <el-select v-model="form.provName" placeholder="请选择省" @click.native.prevent="handleShowMap"> </el-select>
            <el-select v-model="form.cityName" placeholder="请选择市" @click.native.prevent="handleShowMap"> </el-select>
            <el-select v-model="form.areaName" placeholder="请选择区" @click.native.prevent="handleShowMap"> </el-select>
            <!--            <div class="mask" @click="handleShowMap"></div>-->
          </el-form-item>
          <el-form-item label="" prop="address" class="address-detail">
            <el-input v-model="form.address" placeholder="请输入物资详情地址"></el-input>
          </el-form-item>
          <el-form-item label="物资说明" prop="leaseDesc">
            <el-input type="textarea" v-model="form.leaseDesc" placeholder="请输入详细说明" maxlength="1000"></el-input>
          </el-form-item>
          <el-form-item class="item-button">
            <div class="button-box">
              <div class="button primary-button disable" @click="$emit('home')">返回</div>
              <div class="button primary-button" v-if="publishEdit" @click="submitForm('formStep1')">编辑物资</div>
              <div class="button primary-button" v-else @click="submitForm('formStep1')">添加物资</div>
            </div>
          </el-form-item>
        </el-form>
        <FormTips/>
      </div>
      <!-- 高德地图 -->
      <el-dialog title="选择物资所在地" :visible.sync="dialogVisible" width="800px" :close-on-click-modal="false">
        <MapContainer v-if="dialogVisible" @confirm="handleConfirmAddress" @cancel="dialogVisible = false" :lng="form.lon || userPosition.location.lng" :lat="form.lat || userPosition.location.lat" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import MapContainer from './MapContainer.vue'
import { isMobile } from '@/utils/validate'
import { mapState } from 'vuex'
import { apiGetMyLeaseInfo, apiGetUserAuthInfo } from '@/api'
import FormTips  from './FormTips.vue'
export default {
  name: 'FormStep1',
  components: { MapContainer, FormTips },
  props: {
    publishEdit: Boolean,
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      console.log(value)
      if (!value) {
        callback(new Error('请输入联系人电话'))
      } else if (!isMobile(value)) {
        callback(new Error('联系人电话格式不正确'))
      } else {
        callback()
      }
      
    }
    return {
      canModifyEntName: true, // 企业名称是否可以编辑
      result: {},
      dialogVisible: false,
      address: '未选择地址',
      form: {
        entName: '', // 发布企业名称
        contactName: '', // 联系人姓名
        contactPhone: '', // 联系人电话
        adcode: '', // 高德行政编码
        lon: '', // 物资所在经度
        lat: '', // 物资所在纬度
        address: '', // 地址
        leaseDesc: '', // 租赁说明
        provName: '',
        cityName: '',
        areaName: '',
      },
      rules: {
        entName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        contactName: [{ required: true, message: '请输入物资联系人', trigger: 'blur' }],
        contactPhone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        address1: [{ required: true, validator:this.validateAddress}],
        address: [{ required: true, message: '请输入物资详细地址', trigger: 'blur' }],
        leaseDesc: [{ required: true, message: '请输入物资说明', trigger: 'blur' }],

      },
    }
  },
  computed: {
    ...mapState('user', ['userPosition', 'userInfo']),
  },

  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (parseInt(newVal.canModifyEntName) === 0) {
            this.canModifyEntName = false
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    // 获取用户信息
    let { infoNo } = this.$route.params
    if (infoNo) {
      // 编辑信息
      this.infoNo = this.$route.params.infoNo
      this.getMyLeaseInfo()
    } else {
      // 新增从缓存和接口中拿数据
      this.getMyLeaseInfoAdd()
    }
  },
  methods: {
    // 编辑信息
    getMyLeaseInfo() {
      // 获取信息
      apiGetMyLeaseInfo({
        infoNo: this.infoNo,
        userUuid: this.userInfo.userUuid,
      }).then((res) => {
        this.form = res.data
      })
    },
 
    validateAddress (rule,value,callBack){
      callBack()
    },
    getMyLeaseInfoAdd(){
      const baseInfo = localStorage.getItem('publish-base-info')
      if (baseInfo) {
        this.form = JSON.parse(baseInfo)
      } else {
        apiGetUserAuthInfo({ userUuid: this.userInfo.userUuid }).then((res) => {
          const { serLegalName, phone, serStatus } = res.data
          if(serStatus === 3) {
            if(res.data.serAuthType == 1){
              this.form.contactName = res.data.serEntrustName
            }else{
              this.form.contactName = serLegalName
            }
          }
          this.form.contactPhone= phone
        })
      }
    },
    // 下一步
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(!this.form.provName) {
            this.$message({message:"请选择省",type:"warning"})
          } else if(!this.form.cityName) {
            this.$message({message:"请选择市",type:"warning"})

          }else if(!this.form.areaName) {
            this.$message({message:"请选择县",type:"warning"})

          } else {
            this.$emit('next', this.form)
          }
        }
      })
    },
    handleShowMap() {
      this.dialogVisible = true
    },
    // 确认地址
    handleConfirmAddress(result) {
      const { province, city, district, township, street, neighborhood, building, adcode } = result.regeocode.addressComponent
      this.dialogVisible = false
      this.form.address = result.regeocode.formattedAddress
      this.form.lat = result.lat
      this.form.lon = result.lng
      this.form.adcode = adcode
      this.form.provName = province
      this.form.cityName = city || province
      this.form.areaName = district
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.form-box {
  width: 866px;
  border-top: 1px dashed #d6d6d6;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ::v-deep .el-input__inner {
    height: 36px;
    line-height: 36px;
  }
}
::v-deep .el-form-item__label {
  font-size: $primaryTextSize;
  color: $primaryTextColor;
}

.el-select {
  width: 100px;
  margin-right: 5px;
}
.el-input {
  width: 313px;
  height: 36px;
}

.el-textarea {
  width: 313px;
}
::v-deep .el-input__inner,
::v-deep .el-textarea__inner {
  font-family: Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Arial, sans-serif;
  border-radius: 4px;
  border: 1px solid #ececec;
}
.address-detail {
  margin-top: -13px;
}

.item-button {
  .button-box {
    margin-top: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .button {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
::v-deep textarea {
  height: 75px;
}

.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
</style>
