<template>
  <div class="item-module-goods">
    <div class="item-content-list">
      <div v-for="(item, index) in selTitleData" :key="index + 'w'" class="item-content-list-text" :class="{ active: selTitleIndex === index }" @click="handleNavClick(item.infoStatusInd, index)">
        {{ item.title }}
      </div>
    </div>
    <el-divider></el-divider>
    <div v-show="leaseInfoList.length > 0">
      <div v-for="(item, index) in leaseInfoList" :key="index + 'l'">
        <div class="item-module-goods-list">
          <div class="item-module-goods-list-top">
            <p class="item-mess-status-icon" style="margin-left: 0px">
              <span v-if="item.infoStatus == 0">未上架</span>
              <span v-if="item.infoStatus == 1">待上架</span>
              <span v-if="item.infoStatus == 2">已上架</span>
              <span v-if="item.infoStatus == 3">已下架</span>
              <span v-if="item.infoStatus == 4">已下架</span>
              <span v-if="item.infoStatus == 5">未上架</span>
            </p>
            <p class="item-module-goods-list-top-text">发布时间：{{ item.addTime }}</p>
            <p class="item-module-goods-list-top-text">物资编号：{{ item.infoNo }}</p>
            <p class="item-module-goods-list-top-text">电话咨询：{{ item.callCount }}</p>
            <p class="item-module-goods-list-top-text">信息浏览：{{ item.lookCount }}</p>
          </div>
          <div class="item-module-goods-list-bottom">
            <div class="item-module-goods-list-bottom-left" @click="handleDetail(item.infoNo,item.specId)">
              <img referrer="no-referrer|origin|unsafe-url" referrerPolicy="no-referrer" :src="item.firstPictureUrl" class="item-module-goods-list-bl-img" />
              <div class="item-module-goods-list-bl-content">
                <p class="item-module-goods-list-bl-content-p" style="font-weight: bold">{{ item.materialName }}</p>

                <div class='item-module-goods-list-bottom-right'>
                  <div class='goods-list-item-info'>
                    <p class="item-module-goods-list-bl-content-p ellipsis" style="width: 250px;text-align: left;">规格型号：{{ item.specName }}</p>
                    <p class="item-module-goods-list-bl-content-p" style="display: flex;margin: 3px 0 10px 10px;">物资数量：{{ item.qty }}{{ item.qtyUnit }}</p>
                    <div class="item-module-goods-list-bl-content-p-rent" style="margin-bottom: 5px" v-for="(item1, index1) in item.priceTextSet" :key="index1 + 'l'">
                      <div class="item-module-goods-list-bl-content-p-rent2">
                        <p style="color: #bbbbbb" v-show="item1.type">{{ item1.type }}：</p>
                        <div class="text important-text ellipsis"><span>{{ item1.price }}</span></div>
                        <p style="color: #e1251b; font-weight: normal;">{{ item1.unit }}</p>
                      </div>
                    </div>
                  </div>
                  <div class='goods-list-item-info'>
                    <!--服务费状态 1：待评估 2：待确认 3：已确认-->
                    <!--服务费类型 1：按件数 2：按百分比 3：其他-->
                    <div v-if="item.serviceChargeState == 1" class='serviceChargeItem'>服务费待评估</div>
                    <div v-if="item.serviceChargeState == 3" class='serviceChargeItem'>
                      <span class='confirmSpan ellipsis2' v-if='item.serviceChargeType == 1'>预估服务费 {{item.serviceCharge}}元/单</span>
                      <span class='confirmSpan ellipsis2' v-if='item.serviceChargeType == 2'>预估服务费 成交额 * {{item.serviceCharge}}%</span>
                      <span class='confirmSpan ellipsis2' v-if='item.serviceChargeType == 3'>预估服务费 {{item.serviceCharge}}</span>
                      <img src='@/assets/images/public/icon-confirm.png' alt='' class='iconConfirm'>
                    </div>
                    <div v-if="item.serviceChargeState == 2" class='serviceChargeItem'>
                      <span class='tobeconfirmSpan ellipsis2' v-if='item.serviceChargeType == 1'>预估服务费 {{item.serviceCharge}}元/单</span>
                      <span class='tobeconfirmSpan ellipsis2' v-if='item.serviceChargeType == 2'>预估服务费 成交额 * {{item.serviceCharge}}%</span>
                      <span class='tobeconfirmSpan ellipsis2' v-if='item.serviceChargeType == 3'>预估服务费 {{item.serviceCharge}}</span>
                      <span class='tobeConfirmedBtn' @click.stop="confirmedBtn(item)">请确认</span>
                    </div>
                  </div>
                  <div class='goods-list-item-info'>
                    <div v-if="item.infoStatus == 0" class="item-module-goods-list-left-item">
                      <p class="item-module-goods-list-br-p1">审核中</p>
                      <p v-if="item.infoStatus == 1" class="item-module-goods-list-br-p2">审核通过后就可直接上架展示啦~</p>
                    </div>
                    <div v-if="item.infoStatus == 1" class="item-module-goods-list-left-item">
                      <p class="item-module-goods-list-br-p1">已审核</p>
                      <p v-if="item.infoStatus == 1" class="item-module-goods-list-br-p2">实名认证通过后就可直接上架展示啦~</p>
                    </div>
                    <div v-if="item.infoStatus == 2" class="item-module-goods-list-left-item">
                      <p class="item-module-goods-list-br-p1">已上架</p>
                    </div>
                    <div v-if="item.infoStatus == 3" class="item-module-goods-list-left-item">
                      <p class="item-module-goods-list-br-p1">已下架</p>
                    </div>
                    <div v-if="item.infoStatus == 4" class="item-module-goods-list-left-item">
                      <p class="item-module-goods-list-br-p1">平台下架</p>
                      <p class="item-module-goods-list-br-p2">{{ item.checkDescr }}</p>
                    </div>
                    <div v-if="item.infoStatus == 5" class="item-module-goods-list-left-item">
                      <p class="item-module-goods-list-br-p1">已驳回</p>
                      <p class="item-module-goods-list-br-p2">{{ item.checkDescr }}</p>
                    </div>
                  </div>
                  <div class='goods-list-item-info'>
                    <p v-if="item.infoStatus == 3 && (userInfo.serStatus == 3 || userInfo.entStatus == 3)" class="item-module-goods-list-br-p1" style="margin-right: 20px; cursor: pointer" @click.stop="handleSoldIn(item.infoNo)">上架</p>
                    <p v-if="item.infoStatus == 2" class="item-module-goods-list-br-p1" style="margin-right: 20px; cursor: pointer" @click.stop="handleSoldOut(item.infoNo)">下架</p>
                    <p v-if="item.infoStatus == 5 || item.infoStatus == 3 || item.infoStatus == 4" class="item-module-goods-list-br-p1" style="margin-right: 20px; cursor: pointer" @click.stop="handleDelete(item.infoNo)">删除</p>
                    <p class="item-module-goods-list-br-p1" style="margin-right: 20px; cursor: pointer" @click.stop="handleRevise(item.infoNo, item.releaseType)">修改</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-bottom">
        <!-- <el-pagination background layout="prev, pager, next" :current-page="pageNo" :page-size="pageSize" :total="total" hide-on-single-page @current-change="currentPage"> </el-pagination> -->

        <el-pagination @size-change="currentPage" @current-change="currentPage" :current-page.sync="pageNo" :page-size="pageSize" layout="total, prev, pager, next" :total="total"> </el-pagination>
      </div>
    </div>
    <div v-show="leaseInfoList.length == 0">
      <el-empty :image-size="200"></el-empty>
    </div>
    <el-dialog :visible.sync="rDialogVisible" center width="363px" :show-close="false" class="whz-el-dialog">
      <div class="dialog-content">
        <p class="dialog-content-title">删除提示</p>
        <p class="dialog-content-info">确定删除此物资信息吗？</p>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            rDialogVisible = false
            delInfoNo = ''
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmDel">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="rDialogVisible1" center class="whz-el-dialog1">
      <div class="dialog-content">
        <img :src="miniCodeUrl" class="dialog-content-mini" />
        <p class="dialog-content-title">通过微信扫一扫</p>
        <p class="dialog-content-info">扫描二维码可通过聚循环小程序免费拔打电话咨询</p>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="rDialogVisible1 = false">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="rDialogVisible2" center class="whz-el-dialog2" @close="handleDialogClose">
      <div class="dialog-content">
        <p class="dialog-content-title">请选择下架原因</p>
        <div class="dialog-content-reason">
          <el-radio v-model="radio_reason" label="情况有变，暂停出租出售" class="dialog-content-reason-item" @change="handleRadioReason">情况有变，暂停出租出售</el-radio>
          <el-radio v-model="radio_reason" label="已经成交无需挂网展示" class="dialog-content-reason-item" @change="handleRadioReason">已经成交无需挂网展示</el-radio>
          <el-radio v-model="radio_reason" label="其他" class="dialog-content-reason-item" @change="handleRadioReason">其他</el-radio>
          <el-input type="textarea" v-model="input_reason" placeholder="请输入下架原因" class="dialog-content-reason-input" maxlength="50" show-word-limit></el-input>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="rDialogVisible2 = false">取消</el-button>
        <el-button type="primary" @click="handleSubmitReason">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="rDialogVisible3" center class="whz-el-dialog3">
      <div class="dialog-content">
        <p class="dialog-content-title">服务费</p>
        <div class="dialog-content-serviceCharge">
          <div class='serviceCharge' v-if='serviceChargeType == 1'>该物资交易服务费预计为：{{serviceCharge}}元/单</div>
          <div class='serviceCharge' v-if='serviceChargeType == 2'>该物资交易服务费预计为：成交额 * {{serviceCharge}}%</div>
          <div class='serviceCharge' v-if='serviceChargeType == 3'>该物资交易服务费预计为：{{serviceCharge}}</div>
          <div class='tips'>最终费用金额需以实际交易为准</div>
          <div class='agreeBtn' @click='confirmServiceFeeBtn'>同意</div>
          <div class='phone'>客服电话：{{phone}}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
        
<script>
import { mapState } from 'vuex'
import { getMyLeaseList, delMyLease, setMyLeaseUpOrDown, confirmServiceFee } from '@/api/personal'
import { apiGetSysConfig } from '@/api'
export default {
  name: 'AttentionCom',
  components: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  props: {
    releaseType: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      selTitleData: [
        { title: ' 全部 ', infoStatusInd: -1 },
        { title: ' 未上架 ', infoStatusInd: 0 },
        { title: ' 待上架 ', infoStatusInd: 1 },
        { title: ' 已下架 ', infoStatusInd: 3 },
        { title: ' 已上架 ', infoStatusInd: 2 },
      ],
      selTitleIndex: 0,
      leaseInfoData: ['1', '2', '', '', '', '', '', '', ''],
      rDialogVisible: false,
      rDialogVisible1: false,
      rDialogVisible2: false,
      rDialogVisible3: false,
      pageNo: 1,
      pageSize: 10,
      leaseInfoList: [],
      infoStatus: '',
      total: 0,
      miniCodeUrl: '',
      delInfoNo: '',
      radio_reason: '',
      input_reason: '',
      infoNo_reason: '',
      checkDescr_reason: '',
      serviceCharge:null,
      serviceChargeType:null,
      serviceChargeInfoNo:null,
      phone: '',
    }
  },
  methods: {
    currentPage(page) {
      this.pageNo = page
      this.getLeaseInfoList()
    },
    handleNavClick(infoStatusInd, index) {
      this.selTitleIndex = index
      this.infoStatus = infoStatusInd
      this.leaseInfoList = []
      this.pageNo = 1
      this.getLeaseInfoList()
    },
    // 下架
    handleSoldOut(infoNo) {
      this.rDialogVisible2 = true
      this.infoNo_reason = infoNo
    },
    //上架
    handleSoldIn(infoNo) {
      var params = {
        infoNo: infoNo,
        upOrDownType: 2,
        checkDescr: '',
      }
      setMyLeaseUpOrDown(params).then((res) => {
        if (res.success) {
          //上架成功
          this.$message({ message: '上架成功', type: 'success' })
          this.getLeaseInfoList()
        } else {
          this.$message({ message: res.msg, type: 'warning' })
        }
      })
    },
    // 删除
    handleDelete(infoNo) {
      this.delInfoNo = infoNo
      this.rDialogVisible = true

      // this.rDialogVisible1 = true
    },
    confirmDel() {
      var params = {
        infoNo: this.delInfoNo,
      }
      delMyLease(params).then((res) => {
        if (res.success) {
          //删除成功
          this.delInfoNo = ''
          this.getLeaseInfoList()
          this.rDialogVisible = false
        } else {
          this.$message({ message: res.msg, type: 'warning' })
        }
      })
    },
    // 修改
    handleRevise(infoNo, releaseType) {
      this.$router.push('/publish/' + releaseType + '/' + infoNo)
    },
    getStatusArr(infoStatus) {
      var statusArr = []
      switch (infoStatus) {
        case 0:
          statusArr.push(0)
          statusArr.push(5)
          break
        case 1:
          statusArr.push(1)
          break
        case 2:
          statusArr.push(2)
          break
        case 3:
          statusArr.push(3)
          statusArr.push(4)
          break
      }
      return statusArr
    },
    getLeaseInfoList() {
      var params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }
      if (this.releaseType !== '') {
        params['releaseType'] = this.releaseType
      }
      console.log(this.infoStatus)
      if (this.infoStatus !== '' && this.infoStatus != '-1') {
        params['infoStatusArr'] = this.getStatusArr(this.infoStatus)
      }
      getMyLeaseList(params).then((res) => {
        if (res.success) {
          var newList = res.data
          // if (this.pageNo == 1) {
          this.leaseInfoList = newList
          this.total = res.count
          // } else {
          //   this.leaseInfoList = this.leaseInfoList.concat(newList)
          // }
          // this.pageNo += 1
          // console.log(this.leaseInfoList)
        } else {
          this.$message({ message: res.msg, type: 'warning' })
        }
      })
    },
    handleDetail(infoNo,specId) {
      // 跳转详情
      var handleCode = 'edc66a6a8601378d'
      // 登录成功后未开通会员，弹窗领取会员后可查看详情
      this.$router.push({
        path: '/product/detail',
        query: {
          infoNo: infoNo,
          specId:specId,
          subheads: '发布管理',
          handleCode: handleCode,
        },
      })
    },
    handleRadioReason() {
      this.input_reason = ''
      if (this.radio_reason !== '其他') {
        this.checkDescr_reason = this.radio_reason
      } else {
        this.checkDescr_reason = this.input_reason
      }
    },
    handleSubmitReason() {
      if (this.radio_reason === '其他') {
        if (this.input_reason) {
          this.checkDescr_reason = this.input_reason
        } else {
          return this.$message('请填写下架原因')
        }
      } else if(!this.radio_reason) {
        return this.$message('请选择下架原因')
      }
      var params = {
        infoNo: this.infoNo_reason,
        upOrDownType: 3,
        checkDescr: this.checkDescr_reason,
      }
      setMyLeaseUpOrDown(params).then((res) => {
        if (res.success) {
          //下架成功
          this.rDialogVisible2 = false
          this.getLeaseInfoList()
        } else {
          this.$message({ message: res.msg, type: 'warning' })
        }
      })
    },
    handleDialogClose() {// 下架原因弹窗关闭回调destroy-on-close
      this.radio_reason = ''
      this.input_reason = ''
    },
    // 请确认
    confirmedBtn(info){
      this.rDialogVisible3 = true;
      this.serviceCharge = info.serviceCharge;
      this.serviceChargeType = info.serviceChargeType;
      this.serviceChargeInfoNo = info.infoNo;
    },
    //确认服务费
    confirmServiceFeeBtn(){
      var params = {
        infoNo: this.serviceChargeInfoNo,
      }
      confirmServiceFee(params).then((res) => {
        if (res.success) {
          // 确认成功
          this.$message({ message: res.data, type: 'success' })
          this.rDialogVisible3 = false;
          this.getLeaseInfoList()
        } else {
          this.$message({ message: res.msg, type: 'warning' })
        }
      })
    }
  },
  mounted() {
    this.getLeaseInfoList();
    // 获取顶部电话
    apiGetSysConfig({ code: 'jzl_customer_service_phone' }).then((res) => {
      this.phone = res.data
    })
  },
  watch: {
    releaseType: function (newValue, oldValue) {
      // 响应数据变化
      console.log(newValue)
      this.pageNo = 1
      this.getLeaseInfoList()
    },
  },
}
</script>
        
<style lang="scss" scoped>
@import '@/styles/variables';

.item-content-list {
  height: 59px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-left: 57px;
  .item-content-list-text {
    color: #333333;
    margin-right: 73px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: $importantColor;
    }
    &.active {
      color: $importantColor;
    }
  }
}
.item-module-goods {
  display: flex;
  flex-direction: column;
  justify-content: start;
  .pagination-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
}
.item-module-goods ::v-deep .el-divider--horizontal {
  margin: 0px 20px 0px 20px;
  background-color: #eaeaea;
  width: auto;
}
.item-module-goods-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 15px 0px;
  margin: 0px 15px;
  border-bottom-color: #eaeaea;
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  .item-module-goods-list-top {
    display: flex;
    flex-direction: row;
    justify-content: start;
    font-size: 14px;
    color: #bbbbbb;
    .item-mess-status-icon {
      margin-left: 8px;
      background: #e1251b;
      padding: 2px 5px;
      font-size: 12px;
      color: white;
      border-radius: 2px;
    }
    .item-module-goods-list-top-text {
      margin-left: 10px;
    }
  }
  .item-module-goods-list-bottom {
    display: flex;
    flex-direction: row;
    justify-content: start;
    .item-module-goods-list-bottom-left {
      display: flex;
      flex-direction: row;
      flex: 1;
      padding-top: 15px;
      cursor: pointer;
      .item-module-goods-list-bl-img {
        width: 138px;
        height: 138px;
        background: #eaeaea;
      }
      .item-module-goods-list-bl-content {
         display: flex;
         flex-direction: column;
         flex: 1;
        // justify-content: start;
        .item-module-goods-list-bl-content-p {
          font-size: 14px;
          color: #333333;
          margin-left: 10px;
        }
        .item-module-goods-list-bl-content-p-rent {
          display: flex;
          font-size: 12px;
          color: #bbbbbb;
          margin-left: 10px;
          &:last-child{
            margin-bottom: 0 !important;
          }
        }
        .item-module-goods-list-bl-content-p-rent2 {
          display: flex;
          font-size: 12px;
          color: #e1251b;
          font-weight: bold;
          .text{
            flex-shrink: 0;
            max-width: 160px;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .item-module-goods-list-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      //flex: 1;
      padding-top: 10px;
      .goods-list-item-info{
        flex: 1;
        text-align: center;
        border-right: 1px solid #EAEAEA;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:first-child{
          flex: 1.1;
        }
        &:last-child{
          border-right: none;
          flex: 0.7;
          flex-direction: row;
          align-items: center;
        }
        .serviceChargeItem{
          font-size: 14px;
          color: #BBBBBB;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding: 0 10px;
          .confirmSpan{
            color: #00B968;
          }
          .tobeconfirmSpan{
            color: #333333;
          }
          .iconConfirm{
            width: 18px;
            height: 18px;
            margin-top: 10px;
          }
          .tobeConfirmedSpan{
            color: #333333;
            display: inline-block;
            margin-right: 10px;
          }
          .tobeConfirmedBtn{
            display: inline-block;
            width: 66px;
            height: 22px;
            background: #E1251B;
            border-radius: 4px;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 22px;
            margin-top: 10px;
          }
        }
      }
      .item-module-goods-list-br-p1 {
        font-size: 14px;
        color: #e1251b;
      }
      .item-module-goods-list-br-img2 {
        width: 11px;
        height: 14px;
      }
      .item-module-goods-list-br-p2 {
        font-size: 12px;
        color: #999999;
      }
      .item-module-goods-list-br-left {
        flex: 1;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
        .item-module-goods-list-left-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .item-module-goods-list-br-right {
        display: flex;
        flex-direction: row;
        padding-right: 22px;
        flex: 1;
        justify-content: right;
      }
    }
  }
}

// 对话框
.whz-el-dialog {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 24px;
      color: #333333;
      font-weight: bold;
    }
    .dialog-content-info {
      font-size: 14px;
      color: #333333;
      margin-top: 10px;
    }
  }
  ::v-deep .el-dialog {
    border-radius: 20px;
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 122px;
    height: 38px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  //   ::v-deep .el-dialog__headerbtn {
  //     visibility: hidden;
  //   }
}
// 对话框
.whz-el-dialog1 {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      margin-top: 20px;
    }
    .dialog-content-info {
      font-size: 14px;
      color: #bbbbbb;
      margin-top: 6px;
    }
  }
  .dialog-content-mini {
    width: 205px;
    height: 205px;
    margin-top: 51px;
  }
  ::v-deep .el-dialog {
    width: 520px;
    height: 520px;
    border-radius: 20px;
    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 160px;
    height: 50px;
    font-size: 14px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  //   ::v-deep .el-dialog__headerbtn {
  //     visibility: hidden;
  //   }

  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: white;
    border-radius: 50%;
    background: #929292;
    padding: 3px;
  }
}

// 对话框
.whz-el-dialog2 {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
    }
    .dialog-content-info {
      font-size: 14px;
      color: #bbbbbb;
      margin-top: 12px;
    }
    .dialog-service-merchant {
      width: 380px;
      height: 101px;
      background-image: url('@/assets/images/public/icon_service_merchant.png');
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 29px;
      justify-content: space-between;
    }

    .dialog-construction-enterprise {
      width: 380px;
      height: 101px;
      background-image: url('@/assets/images/public/icon_construction_enterprise.png');
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 17px;
      justify-content: space-between;
    }

    .dialog-content-select {
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-left: 96px;
    }
    .dialog-content-select-p1 {
      font-size: 20px;
      color: #ffffff;
    }
    .dialog-content-select-p2 {
      margin-top: 5px;
      width: 183px;
      font-size: 12px;
      color: #ffffff;
    }
    .dialog-content-checkbox {
      width: 24px;
      height: 24px;
      margin-right: 20px;
      background-image: url('@/assets/images/public/icon_select_normal_status.png');
      &.active {
        background-image: url('@/assets/images/public/icon_select_status.png');
      }
    }
    .dialog-content-checkbox ::v-deep .el-checkbox__inner {
      width: 24px;
      height: 24px;
    }
    .dialog-content-reason {
      display: flex;
      flex-direction: column;
      justify-content: left;
      width: 100%;
      margin-top: 20px;
      .dialog-content-reason-item {
        margin-top: 27px;
      }
      .dialog-content-reason-input {
        height: 114px;
        margin-top: 14px;
        ::v-deep .el-textarea__inner {
          font-size: 14px;
          height: 100%;
          padding: 15px;
        }
      }
    }
  }
  ::v-deep .el-input--small .el-input__inner {
    height: 100%;
    text-align: start;
  }
  .dialog-content-mini {
    width: 205px;
    height: 205px;
    margin-top: 51px;
  }
  ::v-deep .el-dialog {
    width: 520px;
    height: 460px;
    border-radius: 20px;
    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 122px;
    height: 38px;
    font-size: 14px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  //   ::v-deep .el-dialog__headerbtn {
  //     visibility: hidden;
  //   }

  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: white;
    border-radius: 50%;
    background: #929292;
    padding: 3px;
  }

  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 5px 70px 30px;
  }
  ::v-deep .el-button + .el-button {
    margin-left: 22px;
  }
}
.whz-el-dialog3 {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 24px;
      color: #333333;
      font-weight: bold;
    }
    .dialog-content-serviceCharge{
      margin-top: 54px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .serviceCharge{
        font-size: 20px;
        font-weight: bold;
        color: #E1251B;
        line-height: 26px;
        max-height: 80px;
        overflow: auto;
      }
      .tips{
        font-size: 16px;
        color: #BBBBBB;
        line-height: 21px;
      }
      .agreeBtn{
        width: 160px;
        height: 50px;
        line-height: 50px;
        background: #E1251B;
        border-radius: 24px;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        margin: 46px 0 16px;
        cursor: pointer;
      }
      .phone{
        font-size: 14px;
        color: #BBBBBB;
      }
    }
  }
  ::v-deep .el-input--small .el-input__inner {
    height: 100%;
    text-align: start;
  }
  .dialog-content-mini {
    width: 205px;
    height: 205px;
    margin-top: 51px;
  }
  ::v-deep .el-dialog {
    width: 520px;
    height: 378px;
    border-radius: 20px;
    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 122px;
    height: 38px;
    font-size: 14px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: white;
    border-radius: 50%;
    background: #929292;
    padding: 3px;
  }

  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 5px 70px 30px;
  }
  ::v-deep .el-button + .el-button {
    margin-left: 22px;
  }
}
</style>
        